import React, { useState, useEffect, useCallback } from 'react'

/**
 * Scroll to top
 * @param {object} props 
 * @param {React.ReactNode} props.children Children
 */
export default function ScrollToTop(props) {
    /** @type {[boolean, function(boolean):any]} Is panel open ?  */
    const [isScrollTopVisible, updateIsScrollTopVisible] = useState(!!false)

    const listenScroll = useCallback(
        /** Listen scroll */
        (ev) => {
            updateIsScrollTopVisible(ev.srcElement.scrollingElement.scrollTop > 150)
        },
        []
    )

    //Did mount
    useEffect(() => {
        window.addEventListener('scroll', listenScroll)

        //Will unmount
        return () => {
            window.removeEventListener('scroll', listenScroll)
        }
    }, [listenScroll])

    return (
        <div
            className="app-scroll-to-top"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            style={{ opacity: isScrollTopVisible ? 0.3 : 0, visibility: isScrollTopVisible ? 'visible' : 'hidden' }}
        >
            {props.children}
        </div>
    )
} 