import React from 'react'
import { IComboBoxOption, IDropdownOption, ICommandBarItemProps, Icon } from 'office-ui-fabric-react' // eslint-disable-line
import _flattenObj from './flattenObj'
import { Types } from "static/types"
import { IColumn } from "office-ui-fabric-react" // eslint-disable-line
import { Column } from 'static/column' // eslint-disable-line
import { Time } from './time'

/**
 * Method to handle column click in <DetailList>
 * @param {object} params Params to pass
 * @param {string} params.colName Columns of the <DetailList> need to be in state to be sortable, ex: this.state.columns => "columns"
 * @param {string[]} params.dataName Dataname of data populating the <DetailList> can be nested object or not, ex: this.state.item => ["item"]; this.state.element.item => ["element", "item"]
 * @param {string} params.source Can be "state" or "props"
 * @param {function=} params.action Function to update props
 * @param {boolean=} params.isFlatten Is items need to be flatten
 * @param {object} ev
 * @param {object} column
 */
function _onColumnClick(params = { colName: "columns", dataName: ['items'], source: "state", action: () => null, isFlatten: false }, ev, column) {
    if (!Array.isArray(params.dataName) || params.dataName.length > 2 || params.dataName.length < 1) {
        console.error("'params.dataName' should be array with one or two arguments only")
        return
    }

    const columns = this.state[params.colName]
    let items = params.dataName.length > 1 ? this[params.source][params.dataName[0]][params.dataName[1]] : this[params.source][params.dataName[0]]
    //var a = function(x, s) { if (s.length > 1) { return a(x[s[0]], s.slice(1, s.length)) } else { return x[s[0]] } }

    if (params.isFlatten) items = items.map(x => _flattenObj(x))

    let newItems = items.slice();
    const newColumns = columns.slice();
    const currColumn = newColumns.filter((currCol, idx) => {
        return column.key === currCol.key;
    })[0];
    newColumns.forEach((newCol) => {
        if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
        } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
        }
    });
    newItems = _sortItems(newItems, currColumn.fieldName || '', currColumn.isSortedDescending);

    if (params.source === "state") {
        if (params.dataName.length > 1) {
            this.setState({
                [params.dataName[0]]: {
                    ...this.state[params.dataName[0]],
                    [params.dataName[1]]: newItems
                }
            })
        } else {
            this.setState({
                [params.colName]: newColumns,
                [params.dataName[0]]: newItems
            });
        }
    } else {
        if (typeof params.action === "function") {
            params.action(newItems)
        } else {
            this[params.source][params.action](newItems)
        }
    }
};

/**
 * Method called by "_onColumnClick"
 * Handle: String, Boolean, Date
 * @param {object} items Items to sort
 * @param {string} sortBy Column name
 * @param {boolean} descending Descending or ascending
 * @returns {object[]} Items sorted
 */
function _sortItems(items, sortBy, descending = false) {
    return items.slice(0).sort((a, b) => {
        //Force null item to go at the bottom
        // if (
        //     a[sortBy] === undefined ||
        //     a[sortBy] === null ||
        //     (typeof a[sortBy] === 'string' && a[sortBy]?.trim() === '')
        // )
        //     return 1

        const _clean = (val) => val === undefined || val === null || (typeof val === 'string' && val?.trim() === '') ? '' : val
        a[sortBy] = _clean(a[sortBy])
        b[sortBy] = _clean(b[sortBy])

        if (descending) {
            if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string')
                return a[sortBy]?.trim()?.toLowerCase() < b[sortBy]?.trim()?.toLowerCase() ? 1 : -1
            else
                return a[sortBy] < b[sortBy] ? 1 : -1
        } else {
            if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string')
                return a[sortBy]?.trim()?.toLowerCase() > b[sortBy]?.trim()?.toLowerCase() ? 1 : -1
            else
                return a[sortBy] > b[sortBy] ? 1 : -1
        }
    })
};

/**
 * Handle multiple choice dropdown
 * @param {string[] | number[] | object[]} selectedItems All selected items
 * @param {IComboBoxOption | IDropdownOption} item Item clicked 
 * @returns {object[]} Selected items
 */
function _getUpdatedList(selectedItems, item) {
    const newSelectedItems = [...selectedItems]
    if (item.selected) {
        newSelectedItems.push(item.key)
    } else {
        const currIndex = newSelectedItems.indexOf(item.key)
        if (currIndex > -1) newSelectedItems.splice(currIndex, 1)
    }
    return newSelectedItems
}

/**
 * Handle render of column
 * @param {Column[]} columnsList
 * @param {IColumn | Column} column 
 * @param {boolean=} isOnlyText Force to render text, not react
 * @returns {(item?: any, index?: number, column?: IColumn) => any} onRender function
 */
function _handleRenderColumn(columnsList, column, isOnlyText = false) {
    /** Handle special case in list */
    switch (columnsList.find(x => x.name === column.name)?.type) {
        case Types.DATE:
            return item => item[column.fieldName] ? Time(item[column.fieldName]).getCleanDate({
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }) : ''

        case Types.DATETIME:
            return item => item[column.fieldName] ? Time(item[column.fieldName]).getCleanDate({
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            }) : ''

        case Types.BOOLEAN:
            if (isOnlyText)
                return item => item[column.fieldName] ? "Oui" : "Non"

            return item => item[column.fieldName] ?
                <Icon iconName="CheckMark" styles={{ root: { color: "#0b6a0b", fontWeight: 'bold', marginLeft: '.5em' } }} /> :
                <Icon iconName="Clear" styles={{ root: { color: "#a4262c", fontWeight: 'bold', marginLeft: '.5em' } }} />

        default:
            return
    }
}


/**
 * Disable a command on a given index with a workaround
 * @param {ICommandBarItemProps[]} command 
 * @param {number} index 
 * @param {boolean=} isDisabled 
 */
function _disableCommandBtn(command, index, isDisabled = true) {
    command = command.slice()
    command[index] = (() => {
        const temp = { ...command[index] }
        temp.disabled = isDisabled
        return temp
    })()
    return command
}

/**
 * Disable a sub command on a given index with a workaround
 * @param {ICommandBarItemProps[]} command 
 * @param {number} index 
 * @param {number} indexSub 
 * @param {boolean=} isDisabled 
 */
function _disableCommandSubBtn(command, index, indexSub, isDisabled = true) {
    command = command.slice()
    command[index] = (() => {
        const temp = { ...command[index] }
        temp.subMenuProps = { ...temp.subMenuProps }
        temp.subMenuProps.items = temp.subMenuProps.items.slice()
        temp.subMenuProps.items[indexSub] = (() => {
            const tempSub = { ...temp.subMenuProps.items[indexSub] }
            tempSub.disabled = isDisabled
            return tempSub
        })()
        return temp
    })()
    return command
}

/**
 * Is Valid date
 * @param {any} dt
 */
function _isValidDate(dt) {
    return dt instanceof Date && !isNaN(new Date(dt).getTime())
}

export { _onColumnClick, _getUpdatedList, _handleRenderColumn, _disableCommandBtn, _disableCommandSubBtn, _isValidDate }