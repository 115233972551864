import { TypeSanction, ErrorTypeSanction } from 'request/objects/typeSanction'
import ApiManager from 'request/apiManager'
import { UpsertResult } from 'request/objects/_upsertResult'

/**
 * TypeSanctionManager
 * @extends {ApiManager<TypeSanction, ErrorTypeSanction, UpsertResult>}
 */
export default class TypeSanctionManager extends ApiManager {
    constructor() {
        super({ type: TypeSanction, errorType: ErrorTypeSanction, upsertResult: UpsertResult, key: 'typeSanction' })
    }
}