/**
 * UpsertResult Object
 */
export class UpsertResult {
    /**
     * @param {object} data
     * @param {number=} data.id
     */
    constructor({
        id = 0,
    } = {}) {
        this.id = id
    }
}