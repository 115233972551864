import { Salarie, ErrorSalarie } from 'request/objects/salarie'
import ApiManager from 'request/apiManager'
import { NotImplementedError } from 'request/errors/notImplementedError'
import { UpsertResult } from 'request/objects/_upsertResult'// eslint-disable-line 

/**
 * SalarieManager
 * @extends {ApiManager<Salarie, ErrorSalarie, UpsertResult>}
 */
export default class SalarieManager extends ApiManager {
    constructor() {
        super({ type: Salarie, errorType: ErrorSalarie, upsertResult: UpsertResult, key: 'salarie' })
    }

    /**
     * Authentificate User
     * @param {object} data
     * @returns {Promise<Salarie>}
     */
    login(data) {
        const request = this._getRequest({ url: ['login'], method: 'POST', data })

        return request.req
            .then(res => {
                return new (this.type)(res.data[this.objectName])
            })
            .catch(err => {
                throw this._handleError(err)
            })
            .finally(() => {
                delete this.cancelTokens[request.cancelTokenId]
            })
    }

    /**
     * Get informations about current user
     * @returns {Promise<Salarie>}
     */
    getMe() {
        const request = this._getRequest({ url: ['me'] })

        return request.req
            .then(res => {
                return new (this.type)(res.data[this.objectName])
            })
            .catch(err => {
                throw this._handleError(err)
            })
            .finally(() => {
                delete this.cancelTokens[request.cancelTokenId]
            })
    }

    /**
     * Update
     * @override
     * @param {Salarie} obj 
     * @returns {Promise<UpsertResult>}
     */
    updateById(obj = new (this.type)(), id = undefined) {
        return super.updateById(
            {
                ...obj,
                // @ts-ignore
                restrictionId: [...new Set(obj.restrictionId)]?.toString() ?? null,
                // @ts-ignore
                restrictionCentreId: [...new Set(obj.restrictionCentreId)]?.toString() ?? null,
            },
            id
        )
    }

    /**
     * @override
     * @returns {any}
     */
    create() {
        throw new NotImplementedError()
    }

    /**
     * @override
     * @returns {any}
     */
    removeById() {
        throw new NotImplementedError()
    }
}