import { Procedure, ErrorProcedure } from 'request/objects/procedure'
import { UpsertResult } from 'request/objects/_upsertResult'
import ApiManager from 'request/apiManager'
import { File } from 'request/objects/file' // eslint-disable-line
import store from 'redux/store'
import { setMessageBar } from 'redux/slices/common'
import { MessageBarType } from 'office-ui-fabric-react'
import { HandleBlop } from '../../helpers/methods/blob';

/**
 * ProcedureManager
 * @extends {ApiManager<Procedure, ErrorProcedure, UpsertResult>}
 */
export default class ProcedureManager extends ApiManager {
    constructor() {
        super({
            type: Procedure,
            errorType: ErrorProcedure,
            upsertResult: UpsertResult,
            key: 'procedure'
        });
    }

    /**
     * Upload file
     * @param {number} id Procedure ID
     * @param {globalThis.File} file File to upload
     * @returns {Promise<File>}
     */
    async uploadFile(id = undefined, file = undefined) {
        let fileBase64 = (await HandleBlop.blobToBase64(file)).split(",");

        let fileEncoded = fileBase64.length > 1 ? fileBase64[1] : null;

        let data = {
            filename: file.name,
            fileContentBase64: fileEncoded
        }

        const request = this._getRequest({ url: [id, 'file'], method: "POST", data: data })

        return request.req
            .then(res => {
                store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: "L'élément a bien été ajouté" }))
                return new File({
                    id: res.data.procedure.fichierId,
                    procId: id,
                    nom: file.name
                });
            })
            .catch(err => {
                throw this._handleError(err)
            })
            .finally(() => {
                delete this.cancelTokens[request.cancelTokenId]
            })
    }

    /**
     * Get file
     * @param {number} id Procedure ID
     * @param {number | string} fileId File to to get
     * @returns {Promise<Blob>}
     */
    getFile(id = undefined, fileId = undefined) {
        const request = this._getRequest({ url: [id, 'file', fileId], method: "GET", responseType: "blob" })

        return request.req
            .then(res => {
                return new Blob([res.data])
            })
            .catch(err => {
                throw this._handleError(err)
            })
            .finally(() => {
                delete this.cancelTokens[request.cancelTokenId]
            })
    }

    /**
     * Remove file
     * @param {number} id Procedure ID
     * @param {number} fileId File to remove
     * @returns {Promise<File>}
     */
    removeFile(id = undefined, fileId = undefined) {
        const request = this._getRequest({ url: [id, 'file', fileId], method: "DELETE" })

        return request.req
            .then(res => {
                store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: "L'élément a bien été supprimé" }))
                return new File(res.data?.file)
            })
            .catch(err => {
                throw this._handleError(err)
            })
            .finally(() => {
                delete this.cancelTokens[request.cancelTokenId]
            })
    }

    /**
     * Validate step
     * @param {number} id Procedure ID
     * @returns {Promise<UpsertResult>}
     */
    validateWf(id = undefined) {
        const request = this._getRequest({ url: [id, 'workflow', 'validate'], method: "PATCH" })

        return request.req
            .then(res => {
                return new (this.upsertResult)(res.data[this.objectName])
            })
            .catch(err => {
                throw this._handleError(err)
            })
            .finally(() => {
                delete this.cancelTokens[request.cancelTokenId]
            })
    }

    /**
     * Validate step
     * @param {number} id Procedure ID
     * @returns {Promise<UpsertResult>}
     */
    validateWfCancel(id = undefined) {
        const request = this._getRequest({ url: [id, 'workflow', 'cancel'], method: "PATCH" })

        return request.req
            .then(res => {
                return new (this.upsertResult)(res.data[this.objectName])
            })
            .catch(err => {
                throw this._handleError(err)
            })
            .finally(() => {
                delete this.cancelTokens[request.cancelTokenId]
            })
    }

    /**
     * Close procedure
     * @param {number} id Procedure ID
     * @returns {Promise<UpsertResult>}
     */
    closeWf(id = undefined) {
        const request = this._getRequest({ url: [id, 'workflow', 'close'], method: "PATCH" })

        return request.req
            .then(res => {
                return new (this.upsertResult)(res.data[this.objectName])
            })
            .catch(err => {
                throw this._handleError(err)
            })
            .finally(() => {
                delete this.cancelTokens[request.cancelTokenId]
            })
    }
}