import React from 'react'

/**
 * Card item
 * @param {object} props 
 * @param {object=} props.style Style
 */
export const Divider = ({ style = {} }) => (
    <div className="app-divider" style={style}>
        <span></span>
    </div>
)