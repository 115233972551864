import React, { PureComponent } from 'react'
import { ErrorInfo } from 'react'// eslint-disable-line
import Login from 'pages/public/login'
import { initializeIcons } from '@uifabric/icons'
import { connect } from "react-redux"
import Layout from 'pages/private/_layout'
import { loadTheme, MessageBarType, Text } from 'office-ui-fabric-react'
import { Switch, Router, Redirect } from 'react-router-dom'
import { PrivateRoute, PublicRoute } from 'components/routes'
import { history } from 'helpers/history'
import { init, signIn, signOut, addParam, editParam, removeParam } from 'redux/slices/user'
import { setBreadcrumb, setCommand, setMessageBar, setModal, setUrl } from 'redux/slices/common'
import Modal from 'components/containers/modal'
// import Home from 'pages/private/home'
import withManagers from 'helpers/hoc/withManagers'
import PublicLayout from 'pages/public/_publicLayout'
import { Card } from 'components/containers/card'
import { ManagersProps } from 'helpers/hoc/withManagers' // eslint-disable-line
import { RouteChildrenProps } from 'react-router-dom'// eslint-disable-line
import { PayloadInit, PayloadSingIn, PayloadParam, UserState } from 'redux/slices/user'// eslint-disable-line
import { PayloadMessageBar, PayloadUrl, PayloadBreadcrumb, PayloadCommand, PayloadModal, CommonState } from 'redux/slices/common'// eslint-disable-line
import AllProcedure from 'pages/private/procedure/all'
import OneProcedure from 'pages/private/procedure/one'
import AllAdministration from 'pages/private/administration/all'
import OneAdministration from 'pages/private/administration/one'
import { administrationsList } from 'static/administration/administrationsList'
import 'request/prentender'

initializeIcons()
// https://fabricweb.z5.web.core.windows.net/pr-deploy-site/refs/heads/master/theming-designer/index.html
loadTheme({
    palette: {
        themePrimary: '#2b6ca3',
        themeLighterAlt: '#f4f8fb',
        themeLighter: '#d4e3f0',
        themeLight: '#b1cce3',
        themeTertiary: '#6f9ec8',
        themeSecondary: '#3d79ae',
        themeDarkAlt: '#266093',
        themeDark: '#20517c',
        themeDarker: '#183c5b',
        neutralLighterAlt: '#f8f8f8',
        neutralLighter: '#f4f4f4',
        neutralLight: '#eaeaea',
        neutralQuaternaryAlt: '#dadada',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c8c8',
        neutralTertiary: '#bab8b7',
        neutralSecondary: '#a3a2a0',
        neutralPrimaryAlt: '#8d8b8a',
        neutralPrimary: '#323130',
        neutralDark: '#605e5d',
        black: '#494847',
        white: '#ffffff',
    }
})

/**
 * Global components props
 * @typedef {ReduxProps & ManagersProps & RouteChildrenProps} AppProps
 */

/**
 * @typedef {object} ReduxProps
 * @property {function(PayloadInit):void} init Init
 * @property {function(PayloadSingIn):void} signIn Sign in
 * @property {function():void} signOut Sign out
 * @property {function(PayloadParam):void} addParam Add item to constantes
 * @property {function(PayloadParam):void} editParam Edit param from constant
 * @property {function(PayloadParam):void} removeParam Remove param from constant
 * @property {function(PayloadMessageBar):void} setMessageBar Set MessageBar
 * @property {function(PayloadCommand):void} setCommand Set CommandBar
 * @property {function(PayloadBreadcrumb):void} setBreadcrumb Set Breadcrumb
 * @property {function(PayloadModal):void} setModal Set Modal
 * @property {function(PayloadUrl):void} setUrl Set Current Url
 * 
 * @property {UserState["isAuthenticated"]} isAuthenticated Is user authenticated
 * @property {UserState["me"]} me User informations
 * @property {UserState["param"]} param Constants
 * @property {CommonState["selectedKeyURL"]} selectedKeyURL Current URL in application
 * @property {CommonState["messageBar"]} messageBar Messagebar
 * @property {CommonState["breadcrumb"]} breadcrumb Breadcrumb items
 * @property {CommonState["command"]} command Command items
 * @property {CommonState["modal"]} modal Modal
 */

const mapDispatchToProps = dispatch => ({
    init: ({ me, param }) => dispatch(init({ me, param })),
    signIn: token => dispatch(signIn(token)),
    signOut: () => dispatch(signOut(undefined)),
    addParam: ({ key, value }) => dispatch(addParam({ key, value })),
    editParam: ({ key, value }) => dispatch(editParam({ key, value })),
    removeParam: ({ key, value }) => dispatch(removeParam({ key, value })),

    setMessageBar: ({ isDisplayed, type, message }) => dispatch(setMessageBar({ isDisplayed, type, message })),
    setCommand: commandBarItems => dispatch(setCommand(commandBarItems)),
    setBreadcrumb: data => dispatch(setBreadcrumb(data)),
    setModal: ({ show, title, subTitle, callback, content }) => dispatch(setModal({ show, title, subTitle, callback, content })),

    setUrl: selectedKeyURL => dispatch(setUrl(selectedKeyURL)),
})

const mapStateToProps = state => ({
    isAuthenticated: state.user.isAuthenticated,
    me: state.user.me,
    param: state.user.param,
    selectedKeyURL: state.common.selectedKeyURL,
    messageBar: state.common.messageBar,
    breadcrumb: state.common.breadcrumb,
    command: state.common.command,
    modal: state.common.modal
})

const AppLayout = connect(mapStateToProps, mapDispatchToProps,)(withManagers(Layout))
const AppPublicLayout = connect(mapStateToProps, mapDispatchToProps)(withManagers(PublicLayout))

const AppModal = connect(mapStateToProps, mapDispatchToProps)(Modal)

const _Login = connect(mapStateToProps, mapDispatchToProps)(withManagers(Login))
// const _Home = connect(mapStateToProps, mapDispatchToProps)(withManagers(Home))
const _AllProcedure = connect(mapStateToProps, mapDispatchToProps)(withManagers(AllProcedure))
const _OneProcedure = connect(mapStateToProps, mapDispatchToProps)(withManagers(OneProcedure))
const _AllAdministration = connect(mapStateToProps, mapDispatchToProps)(withManagers(AllAdministration))
const _OneAdministration = connect(mapStateToProps, mapDispatchToProps)(withManagers(OneAdministration))

const AppError = connect(mapStateToProps, mapDispatchToProps)(class extends PureComponent {
    componentDidMount() {
        this.props.setBreadcrumb([{ text: 'Erreur', key: 'error' },])
        this.props.setCommand([])
    }
    render() {
        return (
            <Card title="Erreur" iconName='Error'><Text as="p" block>La page n'a pas été trouvée</Text></Card>
        )
    }
})


/**
 * @extends {React.PureComponent<AppProps>}}
 */
class _App extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isInit: false
        }
    }

    /**
     * @inheritdoc
     * @param {Error} error 
     * @param {ErrorInfo} info 
     */
    componentDidCatch(error, info) {
        console.error(error)
        //Tips: you can use this to save log in back, in case the front crash in production
        // console.error(error, info)
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        history.replace({
            pathname: history.location.pathname,
            hash: history.location.hash,
            search: history.location.search
        }) //Force to clean history state on each refresh
        if (this.props.isAuthenticated) {
            this.init()
        }
    }

    /** 
     * @inheritdoc
     * @param {object} prevProps 
     */
    componentDidUpdate(prevProps) {
        if (this.props.isAuthenticated && this.props.isAuthenticated !== prevProps.isAuthenticated) {
            this.init()
        }
    }

    /**
     * Init app by getting constants and user infos
     */
    init() {
        this.props.setMessageBar({ isDisplayed: false })
        this.props.setCommand([])
        this.props.setBreadcrumb([])
        this.setState({ isInit: false }, () => {
            Promise.all([this.props.salarieManager.getMe(), this.props.paramManager.getParam()])
                .then(([me, param]) => {
                    this.props.init({ me, param })
                })
                .catch(err => {
                    console.error(err)
                    this.props.setMessageBar({
                        isDisplayed: true,
                        type: MessageBarType.error,
                        message: err
                    })
                })
                .finally(() => {
                    this.setState({ isInit: true })
                })
        })
    }

    render() {
        const { isAuthenticated, me } = this.props
        const { isInit } = this.state

        return (
            <>
                <Router history={history}>
                    <AppLayout
                        isDisplay={isAuthenticated}
                        refresh={() => this.init()}
                    >
                        <Switch>
                            <PrivateRoute
                                path="/procedure/nouveau"
                                component={_OneProcedure}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Nouvelle Procédure"
                            />
                            <PrivateRoute
                                path="/procedure/actions-rh"
                                component={_AllProcedure}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Procédures avec actions RH"
                            />
                            <PrivateRoute
                                path="/procedure/en-cours"
                                component={_AllProcedure}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Procédures en cours"
                            />
                            <PrivateRoute
                                path="/procedure/:id"
                                component={_OneProcedure}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                            // title="Procédure"
                            />
                            <PrivateRoute
                                path="/procedure"
                                component={_AllProcedure}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Toutes les procédures"
                            />

                            <PrivateRoute
                                path={`/administration/:type(${administrationsList.map(x => x.key).join('|')})/nouveau`}
                                component={_OneAdministration}
                                isAuthenticated={isAuthenticated}
                                isAuthorized={me.restrictionId.includes(3)}
                                isInit={isInit}
                                title={(props) => `Nouveau ${administrationsList.find(x => x.key === props.type)?.name ?? 'Administration'}`}
                            />
                            <PrivateRoute
                                path={`/administration/:type(${administrationsList.map(x => x.key).join('|')})/:id`}
                                component={_OneAdministration}
                                isAuthenticated={isAuthenticated}
                                isAuthorized={me.restrictionId.includes(3)}
                                isInit={isInit}
                                title={(props) => `${administrationsList.find(x => x.key === props.type)?.name ?? 'Administration'} n°${props.id}`}
                            />
                            <PrivateRoute
                                path={`/administration/:type(${administrationsList.map(x => x.key).join('|')})`}
                                component={_AllAdministration}
                                isAuthenticated={isAuthenticated}
                                isAuthorized={me.restrictionId.includes(3)}
                                isInit={isInit}
                                title={(props) => `${administrationsList.find(x => x.key === props.type)?.name ?? 'Administration'}`}
                            />

                            <PrivateRoute
                                exact
                                path="/"
                                component={() => <Redirect to="/procedure/en-cours" />}
                                // component={_Home}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Accueil"
                            />
                            {isAuthenticated && <PublicRoute component={AppError} title="Error" />}
                        </Switch>
                    </AppLayout>

                    <AppPublicLayout
                        isDisplay={!isAuthenticated}
                    >
                        <Switch>
                            <PublicRoute
                                path="/login"
                                component={_Login}
                                title="Connexion"
                            />
                            {!isAuthenticated && <PublicRoute component={AppError} title="Error" />}
                        </Switch>
                    </AppPublicLayout>
                </Router>
                <AppModal />
            </>
        )
    }
}

const App = connect(mapStateToProps, mapDispatchToProps)(withManagers(_App))

export default App
