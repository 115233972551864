import { Icon, Text } from "office-ui-fabric-react"
import React from "react"
import { ReactNode, ReactComponentElement } from "react" //eslint-disable-line
import { Divider } from "components/visuals/divider"

/**
 * Card item
 * @param {object} props 
 * @param {(string | ReactComponentElement)=} props.title Title
 * @param {string=} props.iconName Icon Name
 * @param {string=} props.className Classes name
 * @param {ReactNode=} props.children Children
 */
export const Card = ({ title = '', iconName = '', className = '', children = null }) => (
	<div className={`app-card ${className}`}>
		{title && <>
			<Text variant="large" block><Icon iconName={iconName} /> {title}</Text>
			<Divider />
		</>}
		{children}
	</div>
)