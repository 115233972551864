import { PagesType } from 'static/pages' // eslint-disable-line
import { Types } from 'static/types'

const procedureSteps = [
    "Nouvelle procédure",
    "Convocation envoyée",
    "Sanction à rédiger",
    "Sanction rédigée",
    "Sanction envoyée",
]

/**
 * @type {PagesType[]}
 */
export const proceduresList = [
    {
        key: 'en-cours',
        name: 'En cours',
        filters: {
            etatId: 3,
        },
        columns: [
            {
                fieldName: 'sal.nom',
                name: "Salarié",
                type: Types.STRING,
                onRender: (procedure) => !procedure['estSaisieManuelle'] ? `${procedure['sal.nom'] ?? ''} ${procedure['sal.prenom'] ?? ''}` : `${procedure['salManuel'] ?? ''}`,
            },
            {
                fieldName: 'cen.soc.libelle',
                name: "Société",
                type: Types.STRING,
            },
            {
                fieldName: 'salManager.nom',
                name: "Manager",
                type: Types.STRING,
                onRender: (procedure) => !procedure['estSaisieManuelle'] ? `${procedure['salManager.nom'] ?? ''} ${procedure['salManager.prenom'] ?? ''}` : `${procedure['salManagerManuel'] ?? ''}`,
            },
            {
                fieldName: 'affectee.nom',
                name: "Affectée à",
                type: Types.STRING,
                onRender: (procedure) => `${procedure['affectee.nom'] ?? ''} ${procedure['affectee.prenom'] ?? ''}`,
            },
            {
                fieldName: 'etatAvancement.nom',
                name: "État d'avancement",
                type: Types.STRING,
                onRender: (procedure) => procedure['estCloture'] === true ? 'Cloturé' : procedureSteps[procedure['etape'] - 1],
            },
            {
                fieldName: 'dateEntretien',
                name: "Date entretien",
                type: Types.DATE,
            },
            {
                fieldName: 'typeSanReel.libelle',
                name: "Sanction",
                type: Types.STRING,
            },
        ]
    },
    {
        key: '',
        name: 'Toutes',
        columns: [
            {
                fieldName: 'sal.nom',
                name: "Salarié",
                type: Types.STRING,
                onRender: (procedure) => !procedure['estSaisieManuelle'] ? `${procedure['sal.nom'] ?? ''} ${procedure['sal.prenom'] ?? ''}` : `${procedure['salManuel'] ?? ''}`,
            },
            {
                fieldName: 'cen.soc.libelle',
                name: "Société",
                type: Types.STRING,
            },
            {
                fieldName: 'salManager.nom',
                name: "Manager",
                type: Types.STRING,
                onRender: (procedure) => !procedure['estSaisieManuelle'] ? `${procedure['salManager.nom'] ?? ''} ${procedure['salManager.prenom'] ?? ''}` : `${procedure['salManagerManuel'] ?? ''}`,
            },
            {
                fieldName: 'affectee.nom',
                name: "Affectée à",
                type: Types.STRING,
                onRender: (procedure) => `${procedure['affectee.nom'] ?? ''} ${procedure['affectee.prenom'] ?? ''}`,
            },
            {
                fieldName: 'etatAvancement.nom',
                name: "État d'avancement",
                onRender: (procedure) => procedure['estCloture'] === true ? 'Cloturé' : procedureSteps[procedure['etape'] - 1],
                type: Types.STRING,
            },
            {
                fieldName: 'dateEntretien',
                name: "Date entretien",
                type: Types.DATE,
            },
            {
                fieldName: 'typeSanReel.libelle',
                name: "Sanction",
                type: Types.STRING,
            },
        ]
    },
]