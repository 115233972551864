/**
 * Origine Object
 */
export class Origine {
    /**
     * @param {object} data
     * @param {number=} data.id
     * @param {string=} data.code
     * @param {string=} data.nom
     */
    constructor({
        id = 0,
        code = null,
        nom = null,
    } = {}) {
        this.id = id
        this.code = code
        this.nom = nom
    }
}

/**
 * Origine Object used to bind error message
 */
export class ErrorOrigine {
    id
    code
    nom
}