import { Societe, ErrorSociete } from 'request/objects/societe'
import ApiManager from 'request/apiManager'
import { NotImplementedError } from 'request/errors/notImplementedError'
import { UpsertResult } from 'request/objects/_upsertResult'

/**
 * SocieteManager
 * @extends {ApiManager<Societe, ErrorSociete, UpsertResult>}
 */
export default class SocieteManager extends ApiManager {
    constructor() {
        super({ type: Societe, errorType: ErrorSociete, upsertResult: UpsertResult, key: 'societe' })
    }

    /**
     * @override
     * @returns {any}
     */
    create() {
        throw new NotImplementedError()
    }

    /**
     * @override
     * @returns {any}
     */
    removeById() {
        throw new NotImplementedError()
    }
}