import { Types } from "static/types"
import { Column } from 'static/column'// eslint-disable-line

/** 
 * List of ColumnHistorique 
 * @type {Column[]}
 * @readonly
 */
export const columnsHistoric = [
    {
        fieldName: 'date',
        name: "Date",
        type: Types.DATETIME
    },
    {
        fieldName: 'nomUtilisateur',
        name: "Utilisateur",
        type: Types.STRING
    },
    {
        fieldName: 'champ',
        name: "Champ",
        type: Types.STRING
    },
    {
        fieldName: 'ancienneValeur',
        name: "Ancienne val.",
        type: Types.STRING
    },
    {
        fieldName: 'nouvelleValeur',
        name: "Nouvelle val.",
        type: Types.STRING
    }
]