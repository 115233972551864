
import React, { PureComponent } from 'react'
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, DialogType } from 'office-ui-fabric-react'
import { ReduxProps } from 'app'// eslint-disable-line

/**
 * @extends {PureComponent<ReduxProps>}}
 */
export default class Modal extends PureComponent {
    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        /** Scrool to top of modal on creation */
        if (prevProps.modal?.show !== this.props.modal?.show && this.props.modal?.show === true) {
            setTimeout(() => document.querySelector('.ms-Modal-scrollableContent').scrollTop = 0, 50)
        }
    }

    /**
     * Render component
     */
    render() {
        const { modal, setModal } = this.props

        if (!modal.show)
            return null

        return (
            <Dialog
                hidden={!modal.show}
                onDismiss={() => setModal({ show: false, title: modal.title, subTitle: modal.subTitle, callback: modal.callback, content: modal.content })}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: modal.title,
                    subText: modal.subTitle
                }}
                modalProps={{
                    isBlocking: true
                }}
                maxWidth={"555px"}
            >
                {modal.content}
                <DialogFooter>
                    <DefaultButton
                        onClick={() => setModal({ show: false, title: modal.title, subTitle: modal.subTitle, callback: modal.callback, content: modal.content })}
                        text="Annuler"
                    />
                    {modal.callback && typeof modal.callback === "function" &&
                        <PrimaryButton
                            onClick={() => {
                                modal.callback()
                                setModal({ show: false, title: modal.title, subTitle: modal.subTitle, callback: modal.callback, content: modal.content })
                            }}
                            text="Oui"
                        />
                    }
                </DialogFooter>
            </Dialog>
        )
    }
}
