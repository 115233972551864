import React from 'react'
import { TooltipHost, DirectionalHint, TooltipDelay } from 'office-ui-fabric-react'
import classNames from 'classnames'

/**
 * @typedef {object} Step
 * @property {string} label Label
 * @property {string=} description Description
 * @property {string=} message Message in tooltip
 * @property {boolean=} isCompleted Is step completed
 * @property {boolean=} isActive Is step active
 * @property {boolean=} isError Is step error
 * 
 * @param {object} props
 * @param {Step[]} props.steps All steps
 * @param {string=} props.className Class to add
 */
export const Workflow = ({ steps, className }) => {
    if (!steps || !steps?.length) return <p>Impossible d'afficher le workflow...</p>

    return (
        <div className={`steps ${className ?? ''}`}>
            {
                steps?.map((row, i) => (
                    <div key={i} className={classNames('step-item', { 'is-active': row.isActive }, { 'is-completed': row.isCompleted || row.isError }, { 'is-warning': row.isError })}>
                        <div className="step-marker">
                            <TooltipHost
                                content={row.message}
                                directionalHint={DirectionalHint.topCenter}
                                delay={TooltipDelay.zero}
                            >
                                {i + 1}
                                {/* {(() => {
                                        if (row.isError) {
                                            return <Icon iconName="Exclamation" className={row.message ? 'info' : ''} />
                                        } else if (row.isCompleted) {
                                            return <Icon iconName="CheckMark" className={row.message ? 'info' : ''} />
                                        } else if (row.isActive) {
                                            return <Icon iconName="ProgressRingDots" className={row.message ? 'info' : ''} />
                                        } else {
                                            return <Icon iconName="More" className={row.message ? 'info' : ''} />
                                        }
                                    })()} */}
                            </TooltipHost>
                        </div>
                        <div className="step-details">
                            <p className="step-title" style={{ marginBottom: row.description?.length ? '1em' : '0' }}>{row.label}</p>
                            <p className="is-hidden-touch" >{row.description}</p>
                        </div>
                    </div>
                )
                )
            }
        </div>
    )
}
