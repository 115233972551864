import { Centre, ErrorCentre } from 'request/objects/centre'
import ApiManager from 'request/apiManager'
import { NotImplementedError } from 'request/errors/notImplementedError'
import { UpsertResult } from 'request/objects/_upsertResult'

/**
 * CentreManager
 * @extends {ApiManager<Centre, ErrorCentre, UpsertResult>}
 */
export default class CentreManager extends ApiManager {
    constructor() {
        super({ type: Centre, errorType: ErrorCentre, upsertResult: UpsertResult, key: 'centre' })
    }

    /**
     * @override
     * @returns {any}
     */
    create() {
        throw new NotImplementedError()
    }

    /**
     * @override
     * @returns {any}
     */
    removeById() {
        throw new NotImplementedError()
    }
}