import React, { PureComponent } from 'react'
import { DirectionalHint, IconButton, Label, MessageBarType, Stack, Text, TextField, Toggle, TooltipDelay, TooltipHost } from 'office-ui-fabric-react'
import { ICommandBarItemProps } from 'office-ui-fabric-react' // eslint-disable-line
import { history } from 'helpers/history'
import { AppProps } from 'app' // eslint-disable-line
import { Status } from 'static/status'
import { InvalidEntityError } from 'request/errors/invalidEntityError'
import { Card } from 'components/containers/card'
import { Loader } from 'components/visuals/loader'
import { CancelRequestError } from 'request/errors/cancelRequestError'
import { NotImplementedError } from 'request/errors/notImplementedError'
import { _getUpdatedList } from 'helpers/methods/common'
import { UnauthorizedError } from 'request/errors/unauthorizedError'
import { administrationsList } from 'static/administration/administrationsList'
import { ErrorTypeSanction, TypeSanction } from 'request/objects/typeSanction'// eslint-disable-line
import { ErrorTypeEnvoi, TypeEnvoi } from 'request/objects/typeEnvoi'// eslint-disable-line
import { PagesType } from 'static/pages'// eslint-disable-line
import { Centre, ErrorCentre } from 'request/objects/centre'// eslint-disable-line
import { ErrorParametre, Parametre } from 'request/objects/parametre'// eslint-disable-line
import { ErrorSalarie, Salarie } from 'request/objects/salarie'// eslint-disable-line
import { ErrorSociete, Societe } from 'request/objects/societe'// eslint-disable-line
// @ts-ignore
import { Columns } from 'react-bulma-components'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'

/**
 * @extends {PureComponent<AppProps>}}
 */
export default class OneAdministration extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!this.props.match?.params?.id,
            /** @type {PagesType=} Current administration find by arg in URL */
            administration: administrationsList?.find(x => x.key === this.props?.match?.params?.type) ?? /** @type {PagesType} */({}),
            /** @type {TypeSanction | TypeEnvoi | Centre | Societe | Parametre | Salarie | object} Element find from API */
            item: {},
            /** @type {TypeSanction | TypeEnvoi | Centre | Societe | Parametre | Salarie | object} Initial element fond from API. Used to refresh */
            iniItem: {},
            /** @type {ErrorTypeSanction | ErrorTypeEnvoi | object} Errors */
            errorField: {}
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.props.manager(this.state.administration.object).cancel()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.props?.match?.params?.id !== prevProps?.match?.params?.id) {
            this._init()
        }
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.props.setMessageBar({ isDisplayed: false })
        this._init()
    }

    /**
     * Get constant name and value associated to current object, when updated, created or deleted
     * @param {object=} administration 
     */
    _getConstantData(administration) {
        switch (this.state.administration?.object) {
            case TypeSanction: {
                const item = /** @type {TypeSanction} */(administration ?? this.state.item)
                return {
                    key: 'typeSanction',
                    value: {
                        key: item.id,
                        text: item.libelle
                    }
                }
            }
            case TypeEnvoi: {
                const item = /** @type {TypeEnvoi} */(administration ?? this.state.item)
                return {
                    key: 'typeEnvoi',
                    value: {
                        key: item.id,
                        text: item.libelle
                    }
                }
            }
            case Centre: {
                const item = /** @type {Centre} */(administration ?? this.state.item)
                return {
                    key: 'centre',
                    value: {
                        key: item.id,
                        text: item.nom
                    }
                }
            }
            case Societe: {
                const item = /** @type {Societe} */(administration ?? this.state.item)
                return {
                    key: 'societe',
                    value: {
                        key: item.id,
                        text: item.libelle
                    }
                }
            }
            case Parametre:
            case Salarie:
            default:
                return {
                    key: null,
                    value: {
                        key: null
                    }
                }
        }
    }

    /**
     * Init Page
     */
    _init() {
        this.props.setBreadcrumb([
            { text: 'Saisie administration', key: 'administration' },
            { text: `Tous les ${this.state.administration.name}`, key: 'all-administration', href: `/administration/${this.state.administration.key}` },
            { text: `${this.state.administration.name} ${this.props?.match?.params?.id ? `n°${this.props?.match?.params?.id}` : 'Nouveau'}`, key: 'one-administration', isCurrentItem: true }
        ])
        this.props.setCommand([])

        /** 
         * @type {ICommandBarItemProps[]} Commanbar items when readonly 
         */
        this.commandRead = [
            {
                key: 'edit',
                text: 'Modifier',
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false }, () => this.props.setCommand(this.commandEdit)),
                disabled: !this.state.administration?.access?.isUpdate
            }
        ]

        /** 
         * @type {ICommandBarItemProps[]} Commanbar items when editing 
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: 'Annuler',
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    this.setState({ isReadOnly: true, item: { ...this.state.iniItem }, status: Status.PENDING, errorField: {} },
                        () => this.setState({ status: Status.IDLE, }, //Workaround to reset input with "defaultValue"
                            () => this.props.setCommand(this.commandRead)
                        )
                    )
                },
                disabled: !this.props.match?.params?.id
            },
            {
                key: 'save',
                text: 'Enregistrer',
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                }
            },
            {
                key: 'delete',
                text: 'Supprimer',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    this.props.setModal({
                        show: true,
                        title: `Supprimer la ${this.state.administration.name}`,
                        subTitle: `Êtes-vous certains de vouloir supprimer la ${this.state.administration.name} ? Cette action est définitive.`,
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                try {
                                    await this.props.manager(this.state.administration.object).removeById(this.props?.match?.params?.id)
                                    this.props.removeParam(this._getConstantData())
                                    history.push(`/administration/${this.props?.match?.params?.type}`)
                                    this.props.setMessageBar({ //We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: "L'élément a bien été supprimée"
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            console.error(error)
                                            break
                                        default:
                                            this.props.setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        }
                    })
                },
                disabled: !this.props.match?.params?.id || !this.state.administration?.access?.isDelete
            }
        ]

        //If there is an id in URL, get element by id
        if (this.props?.match?.params?.id) {
            this.setState({ status: Status.PENDING }, async () => {
                if (this.props.location.state?.administration) { //If object came from history push with a create
                    switch (this.state.administration.key) {
                        case 'utilisateurs':
                            this.setState({
                                item: {
                                    ...this.props.location.state?.administration,
                                    restrictionSocieteId: this._getRestrictionSocieteId(this.props.location.state?.administration)
                                },
                                iniItem: {
                                    ...this.props.location.state?.administration,
                                    restrictionSocieteId: this._getRestrictionSocieteId(this.props.location.state?.administration)
                                },
                                status: Status.RESOLVED
                            })
                            break
                        default:
                            this.setState({
                                item: this.props.location.state?.administration,
                                iniItem: this.props.location.state?.administration,
                                status: Status.RESOLVED
                            })
                            break
                    }
                    this.props.setCommand(this.commandRead)
                } else {
                    try {
                        const administration = await this.props.manager(this.state.administration.object).getById(this.props?.match?.params?.id)
                        switch (this.state.administration.key) {
                            case 'utilisateurs':
                                this.setState({
                                    item: {
                                        ...administration,
                                        restrictionSocieteId: this._getRestrictionSocieteId(administration)
                                    },
                                    iniItem: {
                                        ...administration,
                                        restrictionSocieteId: this._getRestrictionSocieteId(administration)
                                    },
                                    status: Status.RESOLVED
                                })
                                break
                            default:
                                this.setState({
                                    item: administration,
                                    iniItem: administration,
                                    status: Status.RESOLVED
                                })
                                break
                        }
                        this.props.setCommand(this.commandRead)
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                console.error(error)
                                break
                            default:
                                this.setState({ status: Status.REJECTED })
                                this.props.setCommand(this.commandRead)
                                console.error(error)
                                break
                        }
                    }
                }
            })
        } else {
            this.props.setCommand(this.commandEdit)
        }
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    _upsert() {
        const { item } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            this.props.setCommand([])
            try {
                const result = await this.props.manager(this.state.administration.object).upsert(item, this.props.match?.params?.id)
                const administration = await this.props.manager(this.state.administration.object).getById(result.id)

                if (!!this.props.match?.params?.id) { //Update has an ID
                    this.props.editParam(this._getConstantData(administration))

                    switch (this.state.administration.key) {
                        case 'utilisateurs':
                            this.setState({
                                item: {
                                    ...administration,
                                    restrictionSocieteId: this._getRestrictionSocieteId(administration)
                                },
                                iniItem: {
                                    ...administration,
                                    restrictionSocieteId: this._getRestrictionSocieteId(administration)
                                },
                                status: Status.RESOLVED
                            })
                            break
                        default:
                            this.setState({
                                item: administration,
                                iniItem: administration,
                                status: Status.RESOLVED
                            })
                            break
                    }
                    this.props.setCommand(this.commandRead)
                } else { //Else without ID
                    this.props.addParam(this._getConstantData(administration))
                    history.push(`/administration/${this.props?.match?.params?.type}/${administration?.id}`, { administration })
                }
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            console.error(error)
                            break
                        case InvalidEntityError:
                            const err = /** @type {InvalidEntityError<any>} */(error)
                            this.setState({ errorField: err.errorField })
                            this.props.setCommand(this.commandEdit)
                            break
                        default:
                            this.props.setCommand(this.commandEdit)
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Setup restrictionSocieteId
     * @param {object=} item
     */
    _getRestrictionSocieteId(item) {
        const { param } = this.props
        item = item ?? /** @type {Salarie} */(this.state.item)

        const restrictionSocieteId = []

        const selectedCentre = param.centre?.filter(x => (item.restrictionCentreId ?? [])?.includes(/** @type {number} */(x.key)))

        const socNbr = param.centre.reduce((x, y) => ({ ...x, [y.socId]: ((x[y.socId] || 0) + 1) }), {})

        for (const socId in socNbr)
            if (socNbr[socId] === selectedCentre.filter(x => x.socId === parseInt(socId)).length)
                restrictionSocieteId.push(parseInt(socId))

        return restrictionSocieteId
    }

    /**
     * Render component
     */
    render() {
        const { status } = this.state

        if (status === Status.PENDING) return <Loader />

        return (
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    this._upsert()
                }}
            >
                <Card
                    title="Champs"
                    iconName="PageData"
                >
                    {(() => {
                        switch (this.state.administration.object) {
                            case TypeSanction:
                                return this._renderTypeSanction()
                            case TypeEnvoi:
                                return this._renderTypeEnvoi()
                            case Centre:
                                return this._renderCentre()
                            case Societe:
                                return this._renderPiedPage()
                            case Parametre:
                                return this._renderParametre()
                            case Salarie:
                                return this._renderUtilisateur()
                            default:
                                return <Text>Résultat impossible</Text>
                        }
                    })()}
                </Card>
                <button
                    type="submit"
                    style={{ display: 'none' }}
                    ref={this.submitInput}
                />
            </form>
        )
    }

    /**
     * Render field for: Type Sanction
     */
    _renderTypeSanction() {
        const { isReadOnly } = this.state
        const item = /** @type {TypeSanction} */(this.state.item)
        const errorField = /** @type {ErrorTypeSanction} */(this.state.errorField)

        return (<>
            <Columns>
                <Columns.Column className="is-one-quarter">
                    <TextField
                        label="Libéllé de la sanction"
                        placeholder="Libéllé de la sanction"
                        value={item.libelle || ''}
                        errorMessage={errorField.libelle}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, libelle: newVal } })}
                        readOnly={isReadOnly}
                        borderless={isReadOnly}
                        required
                    />
                </Columns.Column>
                <Columns.Column className="is-one-quarter">
                    <TextField
                        label="Code"
                        placeholder="Code"
                        value={item.code || ''}
                        errorMessage={errorField.code}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, code: newVal } })}
                        readOnly={isReadOnly}
                        borderless={isReadOnly}
                        required
                    />
                </Columns.Column>
                <Columns.Column className="is-hidden-touch" />
                <Columns.Column className="is-hidden-touch" />
            </Columns>
        </>)
    }

    /**
     * Render field for: Type envoi
     */
    _renderTypeEnvoi() {
        const { isReadOnly } = this.state
        const item = /** @type {TypeEnvoi} */(this.state.item)
        const errorField = /** @type {ErrorTypeEnvoi} */(this.state.errorField)

        return (<>
            <Columns>
                <Columns.Column className="is-one-quarter">
                    <TextField
                        label="Libéllé de l'envoi"
                        placeholder="Libéllé de l'envoi"
                        value={item.libelle || ''}
                        errorMessage={errorField.libelle}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, libelle: newVal } })}
                        readOnly={isReadOnly}
                        borderless={isReadOnly}
                        required
                    />
                </Columns.Column>
                <Columns.Column className="is-hidden-touch" />
                <Columns.Column className="is-hidden-touch" />
                <Columns.Column className="is-hidden-touch" />
            </Columns>

        </>)
    }

    /**
     * Render field for: Centre
     */
    _renderCentre() {
        const { isReadOnly } = this.state
        const item = /** @type {Centre} */(this.state.item)
        const errorField = /** @type {ErrorCentre} */(this.state.errorField)

        return (<>
            <Columns>
                <Columns.Column className="is-one-quarter">
                    <TextField
                        label="Code"
                        placeholder="Code"
                        value={item.code || ''}
                        errorMessage={errorField.code}
                        readOnly={true}
                        borderless={true}
                        styles={{ field: { cursor: 'default' } }}
                    />
                </Columns.Column>
                <Columns.Column className="is-one-quarter">
                    <TextField
                        label="Nom"
                        placeholder="Nom"
                        value={item.nom || ''}
                        errorMessage={errorField.nom}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, nom: newVal } })}
                        readOnly={isReadOnly}
                        borderless={isReadOnly}
                        required
                    />
                </Columns.Column>
                <Columns.Column className="is-one-quarter">
                    <TextField
                        label="Adresse 1"
                        placeholder="Adresse 1"
                        value={item.adr1 || ''}
                        errorMessage={errorField.adr1}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, adr1: newVal } })}
                        readOnly={isReadOnly}
                        borderless={isReadOnly}
                    />
                </Columns.Column>
                <Columns.Column className="is-one-quarter">
                    <TextField
                        label="Adresse 2"
                        placeholder="Adresse 2"
                        value={item.adr2 || ''}
                        errorMessage={errorField.adr2}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, adr2: newVal } })}
                        readOnly={isReadOnly}
                        borderless={isReadOnly}
                    />
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column className="is-one-quarter">
                    <TextField
                        label="Code postal"
                        placeholder="Code postal"
                        value={item.cp || ''}
                        errorMessage={errorField.cp}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, cp: newVal } })}
                        readOnly={isReadOnly}
                        borderless={isReadOnly}
                    />
                </Columns.Column>
                <Columns.Column className="is-one-quarter">
                    <TextField
                        label="Ville"
                        placeholder="Ville"
                        value={item.ville || ''}
                        errorMessage={errorField.ville}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, ville: newVal } })}
                        readOnly={isReadOnly}
                        borderless={isReadOnly}
                    />
                </Columns.Column>
                <Columns.Column className="is-hidden-touch" />
                <Columns.Column className="is-hidden-touch" />
            </Columns>
        </>)
    }

    /**
     * Render field for: Pieds de page
     */
    _renderPiedPage() {
        const { isReadOnly } = this.state
        const item = /** @type {Societe} */(this.state.item)
        const errorField = /** @type {ErrorSociete} */(this.state.errorField)

        return (<>
            <Columns>
                <Columns.Column className="is-one-quarter">
                    <TextField
                        label="Code"
                        placeholder="Code"
                        value={item.code || ''}
                        errorMessage={errorField.code}
                        readOnly={true}
                        borderless={true}
                        styles={{ field: { cursor: 'default' } }}
                    />
                </Columns.Column>
                <Columns.Column className="is-one-quarter">
                    <TextField
                        label="Nom"
                        placeholder="Nom"
                        value={item.libelle || ''}
                        errorMessage={errorField.libelle}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, libelle: newVal } })}
                        readOnly={isReadOnly}
                        borderless={isReadOnly}
                        required
                    />
                </Columns.Column>
                {/* <Columns.Column className="is-one-quarter">
                    <FilteredVirtualCombobox
                        label="Choix contenu courrier"
                        placeholder="Choix contenu courrier"
                        readOnly={isReadOnly}
                        options={[
                            { key: 1, text: "Choix 1" },
                            { key: 2, text: "Choix 2" }
                        ]}
                        selectedKey={item.courrierContenuChoix}
                        required
                        onChange={(ev, option) => this.setState({ item: { ...item, courrierContenuChoix: option.key } })}
                    />
                </Columns.Column> */}
                <Columns.Column className="is-hidden-touch" />
            </Columns>
            <Columns>
                <Columns.Column className="is-one-quarter">
                    <Toggle
                        label="La société fait partie de l'UES ?"
                        checked={item.estUes}
                        onText="Oui"
                        offText="Non"
                        onChange={(ev, checked) => this.setState({ item: { ...item, estUes: checked } })}
                        disabled={isReadOnly}
                    />
                </Columns.Column>
                <Columns.Column className="is-one-quarter">
                    <Toggle
                        label="A un representant du personnel ?"
                        checked={item.estRepPer}
                        onText="Oui"
                        offText="Non"
                        onChange={(ev, checked) => this.setState({ item: { ...item, estRepPer: checked } })}
                        disabled={isReadOnly}
                    />
                </Columns.Column>
                <Columns.Column className="is-hidden-touch" />
                <Columns.Column className="is-hidden-touch" />
            </Columns>

            <Columns>
                <Columns.Column>
                    <TextField
                        label="Courrier"
                        placeholder="Courrier"
                        value={item.footer || ''}
                        errorMessage={errorField.footer}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, footer: newVal } })}
                        readOnly={isReadOnly}
                        borderless={isReadOnly}
                        multiline
                        required
                        rows={6}
                    />
                </Columns.Column>
                {/* <Columns.Column className="is-half">
                    <TextField
                        label="Signataire"
                        placeholder="Signataire"
                        value={item.signataire || ''}
                        errorMessage={errorField.signataire}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, signataire: newVal } })}
                        readOnly={isReadOnly}
                        borderless={isReadOnly}
                        multiline
                        required
                        rows={6}
                    />
                </Columns.Column> */}
            </Columns>
        </>)
    }

    /**
     * Render field for: Parametre
     */
    _renderParametre() {
        const { isReadOnly } = this.state
        const item = /** @type {Parametre} */(this.state.item)
        const errorField = /** @type {ErrorParametre} */(this.state.errorField)

        return (<>
            <Columns>
                <Columns.Column className="is-two-quarter">
                    <TextField
                        label="Description"
                        placeholder="Description"
                        value={item.description || ''}
                        errorMessage={errorField.code}
                        readOnly={true}
                        borderless={true}
                        styles={{ field: { cursor: 'default' } }}
                    />
                </Columns.Column>
                <Columns.Column className="is-two-quarter">
                    <TextField
                        label="Valeur"
                        placeholder="Valeur"
                        value={item.valeur || ''}
                        errorMessage={errorField.valeur}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, valeur: newVal } })}
                        readOnly={isReadOnly}
                        borderless={isReadOnly}
                        required
                    />
                </Columns.Column>
            </Columns>
        </>)
    }

    /**
     * Render field for: Utilisateur
     */
    _renderUtilisateur() {
        const { isReadOnly } = this.state
        const { param, me } = this.props
        const item = /** @type {Salarie} */(this.state.item)
        const errorField = /** @type {ErrorSalarie} */(this.state.errorField)

        return (<>
            <Columns>
                <Columns.Column className="is-one-quarter">
                    <TextField
                        label="Nom"
                        placeholder="Nom"
                        value={item.nom || ''}
                        errorMessage={errorField.nom}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, nom: newVal } })}
                        readOnly={true}
                        borderless={true}
                    />
                </Columns.Column>
                <Columns.Column className="is-one-quarter">
                    <TextField
                        label="Prénom"
                        placeholder="Prénom"
                        value={item.prenom || ''}
                        errorMessage={errorField.prenom}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, prenom: newVal } })}
                        readOnly={true}
                        borderless={true}
                    />
                </Columns.Column>
                <Columns.Column className="is-one-quarter">
                    <TextField
                        label="Email"
                        placeholder="Email"
                        value={item.login || ''}
                        errorMessage={errorField.login}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, login: newVal } })}
                        readOnly={true}
                        borderless={true}
                        type="email"
                    />
                </Columns.Column>
                <Columns.Column className="is-one-quarter">
                    <FilteredVirtualCombobox
                        label="Département"
                        placeholder="Département"
                        readOnly={isReadOnly}
                        options={param.departement}
                        selectedKey={item.departementId}
                        onChange={(ev, option) => this.setState({ item: { ...item, departementId: option.key } })}
                    />
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column className="is-one-quarter">
                    <FilteredVirtualCombobox
                        label="Rôles"
                        placeholder="Rôles"
                        readOnly={isReadOnly}
                        options={param.restriction?.map(x => ({ ...x, disabled: me.id === item.id && x.key === 3 }))}
                        multiSelect
                        selectedKey={item.restrictionId ?? []}
                        onChange={(ev, option) => {
                            const restrictionId = this._getUpdatedList(item.restrictionId, option)
                            this.setState({
                                item: {
                                    ...item,
                                    restrictionId,
                                    restrictionCentreId: restrictionId?.filter(x => [2, 4, 5].includes(x))?.length === 0 ? [] : item.restrictionCentreId,
                                    restrictionSocieteId: restrictionId?.filter(x => [2, 4, 5].includes(x))?.length === 0 ? [] : item.restrictionSocieteId
                                }
                            })
                        }}
                        required
                        onRenderLabel={_props => {
                            const props = _props?.props ?? _props

                            if (me.id !== item.id)
                                return <Label required={props.required}>{props.label}</Label>

                            return (
                                <Stack horizontal verticalAlign="center">
                                    <Label required={props.required}>{props.label}</Label>
                                    <TooltipHost
                                        tooltipProps={{
                                            onRenderContent: () =>
                                                <p>Vous ne pouvez pas retirer le rôle <b>"Service Juridique"</b> de vous-même.</p>
                                        }}
                                        delay={TooltipDelay.zero}
                                        directionalHint={DirectionalHint.rightCenter}
                                    >
                                        <IconButton
                                            id={"iconButtonId"}
                                            iconProps={{ iconName: 'Info' }}
                                            styles={{ root: { pointerEvents: 'none', marginLeft: '-1em' } }}
                                        />
                                    </TooltipHost>
                                </Stack>
                            )
                        }}
                    />
                </Columns.Column>
                {item.restrictionId?.filter(x => [2, 4, 5].includes(x))?.length ?
                    <>
                        <Columns.Column className="is-one-quarter">
                            <FilteredVirtualCombobox
                                label="Centres"
                                placeholder="Centres"
                                readOnly={isReadOnly}
                                options={param.centre}
                                multiSelect
                                selectedKey={item.restrictionCentreId ?? []}
                                onChange={(ev, option) => this.setState({
                                    item: {
                                        ...item,
                                        restrictionCentreId: this._getUpdatedList(item.restrictionCentreId, option)
                                    }
                                }, () => this.setState({
                                    item: {
                                        ...this.state.item,
                                        restrictionSocieteId: this._getRestrictionSocieteId()
                                    }
                                }))}
                            />
                        </Columns.Column>
                        <Columns.Column className="is-one-quarter">
                            <FilteredVirtualCombobox
                                label="Sociétés"
                                placeholder="Sociétés"
                                readOnly={isReadOnly}
                                options={param.societe}
                                multiSelect
                                selectedKey={item.restrictionSocieteId ?? []}
                                onChange={(ev, option) => this.setState({
                                    item: {
                                        ...item,
                                        restrictionSocieteId: this._getUpdatedList(item.restrictionSocieteId, option)
                                    }
                                }, () => {
                                    const item = /** @type {Salarie} */(this.state.item)
                                    let restrictionCentreId = [...item.restrictionCentreId]

                                    if (option.selected) {
                                        for (const centre of param.centre?.filter(x => option.key === x.socId))
                                            restrictionCentreId.push(/** @type {number} */(centre.key))
                                    } else {
                                        for (const centre of param.centre?.filter(x => option.key === x.socId))
                                            restrictionCentreId = restrictionCentreId.filter(x => x !== /** @type {number} */(centre.key))
                                    }

                                    this.setState({
                                        item: {
                                            ...item,
                                            restrictionCentreId: [...new Set(restrictionCentreId)]
                                        }
                                    })
                                })}
                            />
                        </Columns.Column>
                    </>
                    :
                    <>
                        <Columns.Column className="is-hidden-touch" />
                        <Columns.Column className="is-hidden-touch" />
                    </>
                }
                <Columns.Column className="is-hidden-touch" />
            </Columns>
        </>)
    }
}

OneAdministration.prototype._getUpdatedList = _getUpdatedList