import React, { PureComponent } from 'react'
import { ShimmeredDetailsList, SelectionMode, DetailsListLayoutMode, ConstrainMode, buildColumns, MessageBarType, Text, Label, DefaultButton } from 'office-ui-fabric-react'
import { IColumn, IGroup } from 'office-ui-fabric-react'// eslint-disable-line
import { history } from 'helpers/history'
import { _handleRenderColumn, _onColumnClick } from 'helpers/methods/common'
import { Status } from 'static/status'
import { AppProps } from 'app' // eslint-disable-line
import { Link } from 'react-router-dom'
import { InvalidEntityError } from 'request/errors/invalidEntityError'
import { CancelRequestError } from 'request/errors/cancelRequestError'
import { NotImplementedError } from 'request/errors/notImplementedError'
import { UnauthorizedError } from 'request/errors/unauthorizedError'
import { Card } from 'components/containers/card'
import { administrationsList } from 'static/administration/administrationsList'
import { TypeSanction } from 'request/objects/typeSanction'// eslint-disable-line
import { TypeEnvoi } from 'request/objects/typeEnvoi'// eslint-disable-line
import { PagesType } from 'static/pages'// eslint-disable-line
import { Centre } from 'request/objects/centre'// eslint-disable-line
import { Parametre } from 'request/objects/parametre'// eslint-disable-line
import { Societe } from 'request/objects/societe'// eslint-disable-line
import { Salarie } from 'request/objects/salarie'// eslint-disable-line
// @ts-ignore
import { Columns } from 'react-bulma-components'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'

/**
 * @typedef {object} SearchParamsType
 * @property {string} salarie Salarie name/lastname
 */

/**
 * @extends {PureComponent<AppProps>}}
 */
export default class AllAdministration extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {PagesType} Current administration find by arg in URL */
            administration: administrationsList?.find(x => x.key === this.props?.match?.params?.type) ?? /** @type {PagesType} */({}),
            /** @type {IColumn[]} Columns displayed */
            columns: [],
            /** @type {TypeSanction[] | TypeEnvoi[] | Centre[] | Societe[] | Parametre[] | Salarie[]} Items found in API */
            items: [],
            /** @type {IGroup[]} Groups */
            groups: undefined,
            /** @type {SearchParamsType} Params to search */
            searchParms: {
                salarie: null
            },
        }
        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.props.setMessageBar({ isDisplayed: false })
        this._init()
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.props.manager(this.state.administration.object).cancel()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        if (this.props?.match?.params?.type !== prevProps?.match?.params?.type) { //Handle change in url that didnt unmount component
            this.props.manager(this.state.administration.object).cancel()
            this.setState({
                administration: administrationsList?.find(x => x.key === this.props?.match?.params?.type) ?? {}
            }, async () => {
                this._init()
            })
        }
    }

    /**
     * Setup groups if needed
     * @param {TypeSanction[] | TypeEnvoi[] | Centre[] | Societe[] | Parametre[] | Salarie[]} administrations Items found in API
     */
    _setUpGroups(administrations) {
        /** @type {IGroup[]} */
        const groups = []

        if (this.state.administration.key === 'parametres') {
            const items = /** @type {Parametre[]} */(administrations)
                .sort((a, b) => a.description?.localeCompare(b.description))
                .sort((a, b) => a.groupe?.localeCompare(b.groupe))

            for (let i = 0; i < items.length; i++)
                if (i === 0 || items[i].groupe !== items[i - 1].groupe)
                    groups.push({
                        key: `group-${items[i].groupe}`,
                        name: items[i].groupe,
                        startIndex: i,
                        count: items.filter(x => x.groupe === items[i].groupe)?.length,
                        level: 0
                    })

            this.setState({
                items,
                groups,
                status: Status.RESOLVED
            })
        } else {
            this.setState({
                items: administrations,
                groups: undefined,
                status: Status.RESOLVED
            })
        }
    }

    /**
     * Init Page
     */
    _init() {
        if (Object.keys(this.state.administration).length === 0) return this.props.setMessageBar({
            isDisplayed: true,
            type: MessageBarType.error,
            message: 'Non trouvé'
        }) //Prevent when wrong data in url

        this.props.setBreadcrumb([
            { text: 'Saisie administration', key: 'administration' },
            { text: `Tous les ${this.state.administration.name}`, key: 'all-administration', isCurrentItem: true },
        ])

        this.props.setCommand(
            [
                {
                    key: 'new',
                    text: 'Nouveau',
                    iconProps: { iconName: 'Add' },
                    onClick: () => history.push(`/administration/${this.props?.match?.params?.type}/nouveau`),
                    disabled: !this.state.administration?.access?.isCreate
                },
            ]
        )

        this._search()
    }

    /**
     * Init <DetailList>
     */
    _buildColumns() {
        const cols = this.state?.administration?.columns?.reduce((obj, col) => ({ ...obj, [col.fieldName]: col.name }), {})

        const columns = buildColumns(
            [cols],
            true,
            !['parametres'].includes(this.state.administration.key) ?
                this._onColumnClick.bind(this, { colName: "columns", dataName: ['items'], source: "state" }) :
                null
        )

        for (const column of columns) {
            column.name = cols[column.name]
            column.maxWidth = (() => {
                switch (column.name) {
                    case "Id":
                        return 50
                    case "Description":
                    case "Code":
                    case "Salarié":
                    case "Email":
                        return 200
                    default:
                        return column.name.length * 12
                }
            })()
            column.minWidth = column.name.length * 5

            column.onRender = this.state?.administration?.columns.find(x => x.name === column.name)?.onRender ?? this._handleRenderColumn(this.state?.administration?.columns, column)
        }

        return columns
    }

    /**
     * Search elements
     */
    _search() {
        this.setState({
            status: Status.PENDING,
            columns: this._buildColumns(),
            groups: undefined
        }, async () => {
            try {
                const administrations = await this.props.manager(this.state.administration.object).getAll(this.state.searchParms)
                this._setUpGroups(administrations)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     */
    render() {
        const { columns, status, items, groups, searchParms, administration } = this.state
        const { param } = this.props

        return (
            <main className="app-page-administration-all">
                {
                    administration.key === 'utilisateurs' &&
                    <form onSubmit={ev => { ev.preventDefault(); this._search() }} >
                        <Card className="filters">
                            <Columns>
                                <Columns.Column className="is-one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Salarié"
                                        placeholder="Salarié"
                                        disabled={status === Status.PENDING}
                                        options={param?.salarie}
                                        selectedKey={searchParms.salarie}
                                        onChange={(ev, item) => this.setState({ searchParms: { ...searchParms, salarie: item.key } })}
                                    />
                                </Columns.Column>
                                <Columns.Column className="is-one-quarter is-hidden-mobile" />
                                <Columns.Column className="is-one-quarter is-hidden-mobile" />
                                <Columns.Column className="is-one-quarter">
                                    <Label>&nbsp;</Label>
                                    <DefaultButton
                                        text="Rechercher"
                                        primary={true}
                                        split={true}
                                        disabled={status === Status.PENDING}
                                        onClick={() => this.submitInput.current.click()}
                                        iconProps={{ iconName: 'Search' }}
                                        menuProps={{
                                            items: [{
                                                key: 'Clear',
                                                text: 'Effacer les filtres',
                                                iconProps: { iconName: 'ClearFilter' },
                                                onClick: () => this.setState({
                                                    /** @type {SearchParamsType} */
                                                    searchParms: {
                                                        salarie: null
                                                    }
                                                }, () => this.submitInput.current.click())
                                            }]
                                        }}
                                    />
                                </Columns.Column>
                            </Columns>
                        </Card>
                        <button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={this.submitInput}
                        />
                        <br />
                    </form>
                }
                <Card>
                    <ShimmeredDetailsList
                        items={items}
                        columns={columns}
                        groups={groups}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => true}
                        enableShimmer={status === Status.PENDING}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderRow={(props, defaultRender) => (
                            this.state.administration?.access?.isRead ?
                                <Link
                                    to={{
                                        pathname: `/administration/${this.props?.match?.params?.type}/${props.item?.id}`,
                                        state: { administration: !['utilisateurs'].includes(this.props?.match?.params?.type) ? props.item : undefined }
                                    }}
                                >
                                    {defaultRender(props)}
                                </Link>
                                :
                                defaultRender(props)
                        )}
                    />
                    {!items?.length && [Status.RESOLVED, Status.REJECTED].includes(status) &&
                        <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucun résultat trouvé</Text>
                    }
                </Card>

            </main>
        )
    }
}

AllAdministration.prototype._onColumnClick = _onColumnClick
AllAdministration.prototype._handleRenderColumn = _handleRenderColumn