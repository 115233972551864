/**
 * Historique Object
 */
export class Historique {
    /**
     * @param {object} data
     * @param {number=} data.id
     * @param {string=} data.date
     * @param {string=} data.nomUtilisateur
     * @param {string=} data.champ
     * @param {string=} data.ancienneValeur
     * @param {string=} data.nouvelleValeur
     */
    constructor({
        id = 0,
        date = null,
        nomUtilisateur = null,
        champ = null,
        ancienneValeur = null,
        nouvelleValeur = null
    } = {}) {
        this.id = id
        this.date = date ? new Date(date) : null
        this.nomUtilisateur = nomUtilisateur
        this.champ = champ
        this.ancienneValeur = ancienneValeur
        this.nouvelleValeur = nouvelleValeur
    }
}