import React from 'react'
import ProcedureManager from 'request/managers/procedureManager'
import { Procedure } from 'request/objects/procedure'
import ApiManager from 'request/apiManager'// eslint-disable-line
import ParamManager from 'request/managers/paramManager'
import { Param } from 'request/objects/param'
import CentreManager from 'request/managers/centreManager'
import ParametreManager from 'request/managers/parametreManager'
import SalarieManager from 'request/managers/salarieManager'
import SocieteManager from 'request/managers/societeManager'
import TypeEnvoiManager from 'request/managers/typeEnvoiManager'
import TypeSanctionManager from 'request/managers/typeSanctionManager'
import { Centre } from 'request/objects/centre'
import { Parametre } from 'request/objects/parametre'
import { Salarie } from 'request/objects/salarie'
import { Societe } from 'request/objects/societe'
import { TypeEnvoi } from 'request/objects/typeEnvoi'
import { TypeSanction } from 'request/objects/typeSanction'

/**
 * @typedef {object} ManagersProps
 * @property {function(object):ApiManager<any>} manager Function to get proper manager for a desired object
 * @property {CentreManager} centreManager Centre Manager
 * @property {ParametreManager} parametreManager Parametre Manager
 * @property {ParamManager} paramManager Param Manager
 * @property {ProcedureManager} procedureManager Procedure Manager
 * @property {SalarieManager} salarieManager Salarie Manager
 * @property {SocieteManager} societeManager Societe Manager
 * @property {TypeEnvoiManager} typeEnvoiManager TypeEnvoi Manager
 * @property {TypeSanctionManager} typeSanctionManager TypeSanction Manager
 */

/**
 * With managers hoc
 * @param {Object} WrappedComponent Component to wrapp
 */
export default function withManagers(WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props)

            // Declare all managers
            const centreManager = new CentreManager()
            const parametreManager = new ParametreManager()
            const paramManager = new ParamManager()
            const procedureManager = new ProcedureManager()
            const salarieManager = new SalarieManager()
            const societeManager = new SocieteManager()
            const typeEnvoiManager = new TypeEnvoiManager()
            const typeSanctionManager = new TypeSanctionManager()

            /** @type {object} Store managers in an object */
            this.managers = {
                centreManager,
                parametreManager,
                paramManager,
                procedureManager,
                salarieManager,
                societeManager,
                typeEnvoiManager,
                typeSanctionManager
            }

            /** @type {function(object):ApiManager<any>} Function to get proper manager for a desired object */
            this.manager = obj => {
                switch (obj) {
                    case Centre:
                        return centreManager
                    case Parametre:
                        return parametreManager
                    case Param:
                        return paramManager
                    case Procedure:
                        return procedureManager
                    case Salarie:
                        return salarieManager
                    case Societe:
                        return societeManager
                    case TypeEnvoi:
                        return typeEnvoiManager
                    case TypeSanction:
                        return typeSanctionManager
                    default:
                        return null
                }
            }
        }

        render() {
            return <WrappedComponent manager={this.manager} {...this.managers} {...this.props} />
        }
    }
}