
import React, { PureComponent } from 'react'
import { ShimmeredDetailsList, SelectionMode, DetailsListLayoutMode, ConstrainMode, buildColumns, Label, TextField, DefaultButton, Text } from 'office-ui-fabric-react'
import { IColumn, ICommandBarItemProps } from 'office-ui-fabric-react'// eslint-disable-line
import { history } from 'helpers/history'
import { _handleRenderColumn, _onColumnClick } from 'helpers/methods/common'
import { Status } from 'static/status'
import { AppProps } from 'app' // eslint-disable-line
import { Procedure } from 'request/objects/procedure' // eslint-disable-line
import { Link } from 'react-router-dom'
import { InvalidEntityError } from 'request/errors/invalidEntityError'
import { CancelRequestError } from 'request/errors/cancelRequestError'
import { NotImplementedError } from 'request/errors/notImplementedError'
import { UnauthorizedError } from 'request/errors/unauthorizedError'
import { Card } from 'components/containers/card'
import _flattenObj from 'helpers/methods/flattenObj'
// @ts-ignore
import { Columns } from 'react-bulma-components'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import { HandleBlop } from 'helpers/methods/blob'
import generateCsv from 'helpers/methods/csv'
import { Time } from 'helpers/methods/time'
import { PagesType } from 'static/pages'// eslint-disable-line
import { proceduresList } from 'static/procedure/proceduresList'

/**
 * @typedef {object} SearchParamsType
 * @property {string} salarie Salarie id
 * @property {string} salarieManuel Salarie manuel name
 * @property {number} managerId Manager Id
 * @property {number} directeurId Directeur Id
 * @property {number} etatId Etat Id
 * @property {number} societeId Societe Id
 * @property {number} centreId Centre Id
 * @property {number} departementId Departement Id 
 * @property {number} typeSanReelId TypeSanction Id 
 * @property {string} sanctionFrom Sanction From, ex: "2020-12-31"
 * @property {string} sanctionTo Sanction To, ex: "2020-12-31"
 * @property {string} convocationFrom Convocation From, ex: "2020-12-31"
 * @property {string} convocationTo Convocation To, ex: "2020-12-31"
 * @property {boolean} rhEstVisibleReport Est visible report, for "avec actions rh"
 */

/**
 * @extends {PureComponent<AppProps>}}
 */
export default class AllProcedure extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {IColumn[]} Columns displayed */
            columns: [],
            /** @type {Procedure[]} Items found in API */
            items: [],
            /** @type {SearchParamsType} Params to search */
            searchParms: {
                salarie: null,
                salarieManuel: null,
                managerId: null,
                directeurId: null,
                etatId: null,
                societeId: null,
                centreId: null,
                departementId: null,
                typeSanReelId: null,
                sanctionFrom: null,
                sanctionTo: null,
                convocationFrom: null,
                convocationTo: null,
                rhEstVisibleReport: null,
            },
            /** @type {PagesType} Current procedure find by arg in URL */
            proceduresList: proceduresList?.find(x => x.key === this.props?.match?.path?.replace('/procedure', '')?.replace('/', '')) ?? /** @type {PagesType} */({}),
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.props.setMessageBar({ isDisplayed: false })
        this._init()
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.props.procedureManager.cancel()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.props.procedureManager.cancel()
            this.setState({
                proceduresList: proceduresList?.find(x => x.key === this.props?.match?.path?.replace('/procedure', '')?.replace('/', '')) ?? {},
            }, async () => {
                this._init()
            })
        }

        if (
            JSON.stringify(prevState.searchParms) !== JSON.stringify(this.state.searchParms) &&
            this.state.proceduresList?.key === ''
        ) {
            history.replace({
                search: ''
            })

            const { searchParms } = this.state

            const queryParams = new URLSearchParams(window.location.search)
            for (const key in searchParms) {
                if (searchParms[key] !== null && searchParms[key] !== '')
                    queryParams.set(key, searchParms[key])
            }

            history.replace({
                search: queryParams.toString()
            })
        }
    }

    /**
     * Init Page
     */
    _init() {
        const { proceduresList } = this.state
        const { me } = this.props

        this.props.setBreadcrumb([
            { text: 'Saisie procédure', key: 'procedure' },
            { text: `Toutes les procédures ${proceduresList.key !== '' ? proceduresList.name.charAt(0).toLowerCase() + proceduresList.name.slice(1) : ''}`, key: 'all-procedure', isCurrentItem: true },
        ])

        /** @type {ICommandBarItemProps[]} Commanbar */
        this.commandRead = [
            {
                key: 'new',
                text: 'Nouveau',
                iconProps: { iconName: 'Add' },
                onClick: () => history.push(`/procedure/nouveau`),
                disabled: !me.hasAccess.creerProc
            },
        ]

        if (proceduresList.key === '') {
            this.commandRead.push({
                key: 'export',
                text: 'Exporter',
                iconProps: { iconName: 'DownloadDocument' },
                onClick: () => HandleBlop.download(
                    generateCsv([
                        this.state.proceduresList.columns
                            .map(x => x.name),
                        ...this.state.items
                            .map(x => this._flattenObj(x))
                            .map(x =>
                                this.state.proceduresList.columns
                                    .map(col => col.onRender?.(x) ?? this._handleRenderColumn(this.state.proceduresList.columns, col, true)?.(x) ?? x[col.fieldName])
                            )
                    ]),
                    `Export_${Time(new Date()).getCleanDate({ year: 'numeric', month: '2-digit', day: '2-digit' })}.csv`,
                    true
                ),
                disabled: false
            })
        }

        this.props.setCommand(this.commandRead)

        const queryParams = new URLSearchParams(window.location.search)

        //Reset Filter
        this.setState({
            /** @type {SearchParamsType} */
            searchParms: {
                salarie: parseInt(queryParams.get('salarie')) || null,
                salarieManuel: queryParams.get('salarieManuel') || null,
                managerId: parseInt(queryParams.get('managerId')) || null,
                directeurId: parseInt(queryParams.get('directeurId')) || null,
                etatId: parseInt(queryParams.get('etatId')) || null,
                societeId: parseInt(queryParams.get('societeId')) || null,
                centreId: parseInt(queryParams.get('centreId')) || null,
                departementId: parseInt(queryParams.get('departementId')) || null,
                typeSanReelId: parseInt(queryParams.get('typeSanReelId')) || null,
                sanctionFrom: queryParams.get('sanctionFrom') || null,
                sanctionTo: queryParams.get('sanctionTo') || null,
                convocationFrom: queryParams.get('convocationFrom') || null,
                convocationTo: queryParams.get('convocationTo') || null,
                estCloture: !!queryParams.get('estCloture') ? JSON.parse(queryParams.get('estCloture')) : null,
                rhEstVisibleReport: queryParams.get('rhEstVisibleReport') || null,
                ...proceduresList.filters
            }
        })

        if (proceduresList.key !== '')
            this._search()
        else {
            this.setState({
                status: Status.RESOLVED,
                items: [],
                columns: this._buildColumns(),
            })

            this.props.setBreadcrumb([
                { text: 'Saisie procédure', key: 'procedure' },
                { text: `Toutes les procédures ${proceduresList.key !== '' ? proceduresList.name.charAt(0).toLowerCase() + proceduresList.name.slice(1) : ''} (0)`, key: 'all-procedure', isCurrentItem: true },
            ])
        }
    }

    /**
     * Init <DetailList>
     */
    _buildColumns() {
        const { proceduresList } = this.state
        const cols = proceduresList?.columns
            ?.reduce((obj, col) => ({ ...obj, [col.fieldName]: col.name }), {})

        const columns = buildColumns(
            [cols],
            true,
            this._onColumnClick.bind(this, { colName: "columns", dataName: ['items'], source: "state", isFlatten: true })
        )

        for (const column of columns) {
            column.name = cols[column.name]
            column.maxWidth = (() => {
                switch (column.name) {
                    case "Cloturé":
                    case "CR en attente":
                        return 50
                    case "Date entretien":
                        return 100
                    case "Salarié":
                    case "Manager":
                        return 125
                    default:
                        return column.name.length * 12
                }
            })()
            column.minWidth = column.name.length * 5
            column.isSortedDescending = column.name === "Date entretien"

            column.onRender = proceduresList?.columns.find(x => x.name === column.name)?.onRender ?? this._handleRenderColumn(proceduresList?.columns, column)
        }

        return columns
    }

    /**
     * Search elements
     */
    _search() {
        this.setState({
            status: Status.PENDING,
            columns: this._buildColumns(),
        }, async () => {
            try {
                const { proceduresList } = this.state
                const procedures = await this.props.procedureManager.getAll(this.state.searchParms)
                this.setState({
                    items: procedures,
                    status: Status.RESOLVED,
                })

                this.props.setBreadcrumb([
                    { text: 'Saisie procédure', key: 'procedure' },
                    { text: `Toutes les procédures ${proceduresList.key !== '' ? proceduresList.name.charAt(0).toLowerCase() + proceduresList.name.slice(1) : ''} (${procedures.length})`, key: 'all-procedure', isCurrentItem: true },
                ])
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     */
    render() {
        const { columns, status, items, searchParms, proceduresList } = this.state
        const { param } = this.props

        return (
            <main className="app-page-procedure-all">
                {
                    proceduresList.key === '' &&
                    <form onSubmit={ev => { ev.preventDefault(); this._search() }} >
                        <Card className="filters">
                            <Columns>
                                <Columns.Column className="is-one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Salarié"
                                        placeholder="Salarié"
                                        disabled={status === Status.PENDING}
                                        options={param?.salarie}
                                        selectedKey={searchParms.salarie}
                                        onChange={(ev, item) => this.setState({ searchParms: { ...searchParms, salarie: item.key } })}
                                    />
                                </Columns.Column>
                                <Columns.Column className="is-one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Manager"
                                        placeholder="Manager"
                                        disabled={status === Status.PENDING}
                                        options={param?.manager}
                                        selectedKey={searchParms.managerId}
                                        onChange={(ev, item) => this.setState({ searchParms: { ...searchParms, managerId: item.key } })}
                                    />
                                </Columns.Column>
                                <Columns.Column className="is-one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Directeur"
                                        placeholder="Directeur"
                                        disabled={status === Status.PENDING}
                                        options={param?.directeur}
                                        selectedKey={searchParms.directeurId}
                                        onChange={(ev, item) => this.setState({ searchParms: { ...searchParms, directeurId: item.key } })}
                                    />
                                </Columns.Column>
                                <Columns.Column className="is-one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Etat"
                                        placeholder="Etat"
                                        disabled={status === Status.PENDING}
                                        options={param?.etat}
                                        selectedKey={searchParms.etatId}
                                        onChange={(ev, item) => this.setState({ searchParms: { ...searchParms, etatId: item.key } })}
                                    />
                                </Columns.Column>
                            </Columns>
                            <Columns>
                                <Columns.Column className="is-one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Société"
                                        placeholder="Société"
                                        disabled={status === Status.PENDING}
                                        options={param?.societe}
                                        selectedKey={searchParms.societeId}
                                        onChange={(ev, item) => this.setState({ searchParms: { ...searchParms, societeId: item.key } })}
                                    />
                                </Columns.Column>
                                <Columns.Column className="is-one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Centre"
                                        placeholder="Centre"
                                        disabled={status === Status.PENDING}
                                        options={param?.centre}
                                        selectedKey={searchParms.centreId}
                                        onChange={(ev, item) => this.setState({ searchParms: { ...searchParms, centreId: item.key } })}
                                    />
                                </Columns.Column>
                                <Columns.Column className="is-one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Département"
                                        placeholder="Département"
                                        disabled={status === Status.PENDING}
                                        options={param?.departement}
                                        selectedKey={searchParms.departementId}
                                        onChange={(ev, item) => this.setState({ searchParms: { ...searchParms, departementId: item.key } })}
                                    />
                                </Columns.Column>
                                <Columns.Column className="is-one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Type de sanction"
                                        placeholder="Type de sanction"
                                        disabled={status === Status.PENDING}
                                        options={param?.typeSanction}
                                        selectedKey={searchParms.typeSanReelId}
                                        onChange={(ev, item) => this.setState({ searchParms: { ...searchParms, typeSanReelId: item.key } })}
                                    />
                                </Columns.Column>
                            </Columns>
                            <Columns>
                                <Columns.Column className="is-one-quarter">
                                    <Label>Date de convocation</Label>
                                    <Columns>
                                        <Columns.Column className="is-half">
                                            <TextField
                                                type="date"
                                                placeholder="Du"
                                                disabled={status === Status.PENDING}
                                                value={searchParms.convocationFrom || ''}
                                                onChange={(ev, newVal) => this.setState({ searchParms: { ...searchParms, convocationFrom: newVal || null } })}
                                            />
                                        </Columns.Column>
                                        <Columns.Column className="is-half">
                                            <TextField
                                                type="date"
                                                placeholder="Au"
                                                disabled={status === Status.PENDING}
                                                value={searchParms.convocationTo || ''}
                                                onChange={(ev, newVal) => this.setState({ searchParms: { ...searchParms, convocationTo: newVal || null } })}
                                            />
                                        </Columns.Column>
                                    </Columns>
                                </Columns.Column>
                                <Columns.Column className="is-one-quarter">
                                    <Label>Date de sanction</Label>
                                    <Columns>
                                        <Columns.Column className="is-half">
                                            <TextField
                                                type="date"
                                                placeholder="Du"
                                                disabled={status === Status.PENDING}
                                                value={searchParms.sanctionFrom || ''}
                                                onChange={(ev, newVal) => this.setState({ searchParms: { ...searchParms, sanctionFrom: newVal || null } })}
                                            />
                                        </Columns.Column>
                                        <Columns.Column className="is-half">
                                            <TextField
                                                type="date"
                                                placeholder="Au"
                                                disabled={status === Status.PENDING}
                                                value={searchParms.sanctionTo || ''}
                                                onChange={(ev, newVal) => this.setState({ searchParms: { ...searchParms, sanctionTo: newVal || null } })}
                                            />
                                        </Columns.Column>
                                    </Columns>
                                </Columns.Column>
                                <Columns.Column className="is-one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Salarié saisi manuellement"
                                        placeholder="Salarié saisi manuellement"
                                        disabled={status === Status.PENDING}
                                        options={param?.salarieManuel?.map(x => ({ ...x, key: x.text }))}
                                        selectedKey={searchParms.salarieManuel}
                                        onChange={(ev, item) => {
                                            this.setState({ searchParms: { ...searchParms, salarieManuel: item.key } })
                                        }}
                                    />
                                </Columns.Column>
                                <Columns.Column className="is-one-quarter">
                                    <Label>&nbsp;</Label>
                                    <DefaultButton
                                        text="Rechercher"
                                        primary={true}
                                        split={true}
                                        disabled={status === Status.PENDING}
                                        onClick={() => this.submitInput.current.click()}
                                        iconProps={{ iconName: 'Search' }}
                                        menuProps={{
                                            items: [{
                                                key: 'Clear',
                                                text: 'Effacer les filtres',
                                                iconProps: { iconName: 'ClearFilter' },
                                                onClick: () => this.setState({
                                                    /** @type {SearchParamsType} */
                                                    searchParms: {
                                                        salarie: null,
                                                        salarieManuel: null,
                                                        managerId: null,
                                                        directeurId: null,
                                                        etatId: null,
                                                        societeId: null,
                                                        centreId: null,
                                                        departementId: null,
                                                        typeSanReelId: null,
                                                        sanctionFrom: null,
                                                        sanctionTo: null,
                                                        convocationFrom: null,
                                                        convocationTo: null,
                                                        rhEstVisibleReport: null,
                                                    }
                                                })
                                            }]
                                        }}
                                    />
                                </Columns.Column>
                            </Columns>
                        </Card>
                        <button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={this.submitInput}
                        />
                        <br />
                    </form>
                }
                <Card>
                    <ShimmeredDetailsList
                        items={items.map(x => this._flattenObj(x))}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => true}
                        enableShimmer={status === Status.PENDING}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderRow={(props, defaultRender) => (
                            <Link
                                to={{
                                    pathname: `/procedure/${/** @type {Procedure} */(props.item).id}`,
                                    // state: { procedure: items.find(item => item.id === /** @type {Procedure} */(props.item).id) }
                                }}
                            >
                                {defaultRender({
                                    ...props,
                                    styles: {
                                        root: {
                                            background: /** @type {Procedure} */(props.item)?.['sal.estArchive'] ? '#fafafa' : undefined
                                        }
                                    }
                                })}
                            </Link>
                        )}
                    />
                    {!items?.length && [Status.RESOLVED, Status.REJECTED].includes(status) &&
                        <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucun résultat trouvé</Text>
                    }
                </Card>
            </main>
        )
    }
}

AllProcedure.prototype._onColumnClick = _onColumnClick
AllProcedure.prototype._handleRenderColumn = _handleRenderColumn
AllProcedure.prototype._flattenObj = _flattenObj