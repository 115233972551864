import {
    getDefaultMiddleware,
    createSlice,
} from "@reduxjs/toolkit"
import { Middleware } from "redux" // eslint-disable-line
import { Slice, PayloadAction } from "@reduxjs/toolkit" // eslint-disable-line
import { Param } from 'request/objects/param' // eslint-disable-line
import { Salarie } from "request/objects/salarie"//eslint-disable-line

/**
 * User middlewares, must have "getDefaultMiddleware"
 * @type {Middleware[]}
 */
const userMiddleware = [
    ...getDefaultMiddleware()
]

/**
 * Payload Init
 * @typedef {object} PayloadInit
 * @property {Salarie} me User data 
 * @property {Param} param Param data 
 * 
 * Payload SingIn
 * @typedef {string} PayloadSingIn Token
 * 
 * Payload Param
 * @typedef {object} PayloadParam
 * @property {string=} key Key of params type
 * @property {object} value Object param
 * @property {number} value.key Key or Id used
 * @property {string=} value.text Text to display
 */

/**
 * User State
 * @typedef {object} UserState
 * @property {boolean} isAuthenticated Is user authenticated
 * @property {Salarie} me User informations
 * @property {Param} param Constants    
*/

/**
 * User Slice
 * @type {Slice<UserState>}
 */
const userSlice = createSlice({
    name: "user",
    /** @type {UserState} */
    initialState: {
        isAuthenticated: !!localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_KEY),
        me: new Salarie(),
        param: new Param()
    },
    reducers: {
        /**
         * Sign in
         * @param {PayloadAction<PayloadSingIn>} action
         */
        signIn: (state, action) => {
            localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_KEY, action.payload)
            state.isAuthenticated = true
        },
        /**
         * Sign out
         * @param {PayloadAction} action
         */
        signOut: (state) => {
            localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_KEY)
            state.isAuthenticated = false
        },
        /**
         * Init
         * @param {PayloadAction<PayloadInit>} action
         */
        init: (state, action) => {
            state.me = action.payload?.me
            state.param = action.payload?.param
        },
        /**
         * Add item to constantes
         * @param {PayloadAction<PayloadParam>} action
         */
        addParam: (state, action) => {
            if (action.payload?.key)
                state.param[action.payload?.key] = [
                    ...state.param[action.payload?.key],
                    action.payload?.value
                ]
                    .sort((a, b) => a.text.localeCompare(b.text))
        },
        /**
         * Edit param from constant
         * @param {PayloadAction<PayloadParam>} action
         */
        editParam: (state, action) => {
            if (action.payload?.key)
                state.param[action.payload?.key] = [
                    ...state.param[action.payload?.key]
                ]
                    .map(x => x.key === action.payload?.value?.key ? action.payload?.value : x)
                    .sort((a, b) => a.text.localeCompare(b.text))
        },
        /**
         * Remove param from constant
         * @param {PayloadAction<PayloadParam>} action
         */
        removeParam: (state, action) => {
            if (action.payload?.key)
                state.param[action.payload?.key] = [
                    ...state.param[action.payload?.key]
                ]
                    .filter(x => x.key !== action.payload?.value.key)
        },
    },
})

const { init, signIn, signOut, addParam, editParam, removeParam } = userSlice.actions
const userReducer = userSlice.reducer

export {
    init, signIn, signOut, addParam, editParam, removeParam, //Reducers, used to call actions
    userReducer, //All reducers, used to create store
    userMiddleware //Middleware
}