
import React, { PureComponent } from 'react'
import { getTheme, TextField, VirtualizedComboBox } from 'office-ui-fabric-react'
import { IComboBoxProps, IComboBoxOption, ITextFieldProps } from 'office-ui-fabric-react' // eslint-disable-line
import { ParamElement } from 'request/objects/param' // eslint-disable-line

/**
 * @typedef {object} FilteredVirtualComboboxProps
 * @property {IComboBoxOption[]} options Options to show in combobox
 * @property {any} selectedKey Selected key(s) (Number or Number[])
 * @property {function} onChange Is layout is displayed
 * @property {boolean=} readOnly Is in read only ?
 * 
 * @extends {PureComponent<FilteredVirtualComboboxProps & ITextFieldProps & IComboBoxProps>}}
 */
export default class FilteredVirtualCombobox extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {IComboBoxOption[]} Options really displayed, based on this.props.options */
            optionsFiltered: [...props.options],
            /** @type {IComboBoxOption[]} Init options */
            optionsInit: [...props.options]
        }
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        //Check if options have changed
        if (JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
            this.setState({
                optionsFiltered: [...this.props.options],
                optionsInit: [...this.props.options]
            })
        }
    }

    /**
     * Render component
     */
    render() {
        const { readOnly = false } = this.props
        const { optionsFiltered } = this.state

        if (readOnly) {
            const propsClean = { ...this.props }
            delete propsClean.borderless
            delete propsClean.readOnly

            return (
                <TextField
                    {...propsClean}
                    borderless={true}
                    readOnly={true}
                    defaultValue={propsClean.defaultValue ?? (
                        !Array.isArray(this.props.selectedKey) ?
                            this.props.options?.find(x => x.key === propsClean?.selectedKey)?.text :
                            this.props.options?.filter(x => propsClean?.selectedKey?.includes(/** @type {never} */(x.key))).map(x => x.text).join(', ')
                    )}
                />
            )
        } else {
            const propsClean = { ...this.props }
            delete propsClean.allowFreeform
            delete propsClean.autoComplete
            delete propsClean.options
            delete propsClean.useComboBoxAsMenuWidth
            delete propsClean.onItemClick

            return (
                <VirtualizedComboBox
                    {...propsClean}
                    allowFreeform={true}
                    autoComplete="off"
                    useComboBoxAsMenuWidth={true}
                    options={optionsFiltered}
                    componentRef={input => this.input = input}
                    onKeyUp={ev => {
                        if (![38, 40, 13].includes(ev.keyCode)) this._onFilter() //If not Up, Down, Enter
                        if (propsClean.onKeyUp) propsClean.onKeyUp(ev) //If provided
                    }}
                    onMenuOpen={() => {
                        this._onFilter()
                        if (propsClean.onMenuOpen) propsClean.onMenuOpen() //If provided
                    }}
                    onChange={(event, option, index, value) => {
                        if (
                            propsClean.onChange &&  //If provided
                            event.type !== "blur" &&  //If not bluring input
                            !!option //If option is defined
                        ) propsClean.onChange(event, option, index, value)
                    }}
                    onItemClick={(event, option, index) => {
                        if (
                            propsClean.onChange &&  //If provided
                            !!option //If option is defined
                        ) {
                            const opt = !Array.isArray(propsClean.selectedKey) ? (option.key !== propsClean.selectedKey ? option : { key: null, text: null }) : option
                            propsClean.onChange(event, opt, index)
                        }
                    }}
                    onMenuDismissed={() => {
                        this._onFilter({ isReset: true })
                        if (propsClean.onMenuDismissed) propsClean.onMenuDismissed() //If provided
                    }}
                    onRenderOption={option => (
                        <span
                            style={{
                                color: propsClean.selectedKey === option.key ? getTheme().palette.themeSecondary : null,
                                fontWeight: propsClean.selectedKey === option.key ? "bold" : null,
                                background: /** @type {ParamElement} */ (option)?.estArchive ? '#fafafa' : undefined,
                                padding: /** @type {ParamElement} */ (option)?.estArchive ? '10px 350px 10px 10px' : undefined,
                                margin: /** @type {ParamElement} */ (option)?.estArchive ? '-10px -350px -10px -10px' : undefined,
                            }}
                        >
                            {option.text?.toString()?.trim() || <>&nbsp;</>}
                        </span>
                    )}
                />
            )
        }
    }
    /**
     * Filter element display in list
     * @param {object} data 
     * @param {boolean=} data.isReset Is reseting content
     */
    _onFilter({ isReset = false } = {}) {
        const { options } = this.props

        if (this.worker) this.worker.terminate() //Stop worker if one is already running

        if (isReset) { //Cleanup if reset
            return this.setState({ optionsFiltered: this.state.optionsInit })
        } else {
            // @ts-ignore
            this.input._comboBox.current.focus(true) //Set focus on input
        }

        // @ts-ignore
        const regExp = new RegExp(this.input._comboBox.current.state.currentPendingValue, 'gi') //Regex to find matching options

        this.worker = new Worker(
            this._fn2workerURL(
                `
                    function() {
                        var options = ${JSON.stringify(options)}
                        let optionsFiltered = options.filter(option => option.text?.match(${regExp.toString()}))
                        postMessage(JSON.stringify(optionsFiltered))
                    }
                `
            )
        )

        this.worker.onmessage = ev => {
            this.setState({
                optionsFiltered: []
            }, () => {
                this.setState({
                    optionsFiltered: JSON.parse(ev.data)
                }, () => {
                    this.worker.terminate()
                })
            })
        }
    }

    /**
     * Create a blob object from fonction stringify
     * @param {string} fn 
     */
    _fn2workerURL(fn) {
        const blob = new Blob(['(' + fn + ')()'], { type: 'application/javascript' })
        return URL.createObjectURL(blob)
    }
}