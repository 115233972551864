import { Centre } from "request/objects/centre.js" // eslint-disable-line
import { Salarie } from "request/objects/salarie.js" // eslint-disable-line
import { TypeEnvoi } from "request/objects/typeEnvoi.js" // eslint-disable-line
import { TypeSanction } from "request/objects/typeSanction.js" // eslint-disable-line
import { File } from "./file"
import { Historique } from "./historique"

/**
 * Procedure Object
 */
export class Procedure {
    /**
     * @param {object} data
     * @param {number=} data.id
     * @param {number=} data.salId
     * @param {object=} data.sal
     * @param {number=} data.cenId
     * @param {object=} data.cen
     * @param {number=} data.salManagerId
     * @param {object=} data.salManager
     * @param {object=} data.salDirecteur
     * @param {number=} data.affecteeId
     * @param {object=} data.affectee
     * @param {string=} data.fait
     * @param {boolean=} data.avecMapConserv
     * @param {number=} data.typeSanEnvisageId
     * @param {object=} data.typeSanEnvisage
     * @param {string=} data.numRecConvoc
     * @param {string=} data.dateEnvoiConvoc
     * @param {string=} data.datePresentConvoc
     * @param {boolean=} data.avecLicenciement
     * @param {string=} data.dateEntretien
     * @param {string=} data.crDate
     * @param {string=} data.crLieu
     * @param {string=} data.crFait
     * @param {string=} data.crConsequence
     * @param {boolean=} data.crAReconnu
     * @param {boolean=} data.crEstAssiste
     * @param {number=} data.typeSanCrDecisionId
     * @param {object=} data.typeSanCrDecision
     * @param {number=} data.typeSanReelId
     * @param {object=} data.typeSanReel
     * @param {number=} data.typeEnvId
     * @param {object=} data.typeEnv
     * @param {string=} data.dateEnvoiSanction
     * @param {string=} data.dateReceptSanction
     * @param {number=} data.nbJourMap
     * @param {boolean=} data.estCloture
     * @param {string=} data.dateCreation
     * @param {string=} data.origineCreation
     * @param {string=} data.dateModif
     * @param {string=} data.origineModif
     * @param {number=} data.etape
     * @param {number=} data.crNbJourMap
     * @param {number=} data.typeEnvConvocId
     * @param {object=} data.typeEnvConvoc
     * @param {string=} data.numRecSanction
     * @param {string=} data.crNomAssistant
     * @param {string=} data.crPeriodeMap
     * @param {boolean=} data.estAvecDateEnt
     * @param {string=} data.convocComment
     * @param {string=} data.sancComment
     * @param {string=} data.dateFaits
     * @param {object[]=} data.file
     * 
     * @param {string=} data.creationDate
     * @param {string=} data.modifDate
     * @param {object[]=} data.historique
     * 
     * @param {string=} data.createur
     * 
     * @param {string=} data.salManuel
     * @param {string=} data.cenManuel
     * @param {string=} data.salManagerManuel
     * @param {string=} data.salMailManuel
     * @param {string=} data.salManagerMailManuel
     * @param {boolean=} data.estSaisieManuelle
     * 
     * @param {any=} data.acces
     * 
     * @param {string=} data.dateDistributionSanction
     * @param {string=} data.dateDistributionConvoc
     * 
     * @param {string=} data.rhDateDepart
     * @param {boolean=} data.rhEstVisibleReport
     * @param {string=} data.rhComment
     * 
     * @param {boolean=} data.repPers 
     */
    constructor({
        id = 0,
        salId = null,
        sal = null,
        cenId = null,
        cen = null,
        salManagerId = null,
        salManager = null,
        salDirecteur = null,
        affecteeId = null,
        affectee = null,
        fait = null,
        avecMapConserv = null,
        typeSanEnvisageId = null,
        typeSanEnvisage = null,
        numRecConvoc = null,
        dateEnvoiConvoc = null,
        datePresentConvoc = null,
        avecLicenciement = null,
        dateEntretien = null,
        crDate = null,
        crLieu = null,
        crFait = null,
        crConsequence = null,
        crAReconnu = null,
        crEstAssiste = null,
        typeSanCrDecisionId = null,
        typeSanCrDecision = null,
        typeSanReelId = null,
        typeSanReel = null,
        typeEnvId = null,
        typeEnv = null,
        dateEnvoiSanction = null,
        dateReceptSanction = null,
        nbJourMap = null,
        estCloture = false,
        dateCreation = null,
        origineCreation = null,
        dateModif = null,
        origineModif = null,
        etape = null,
        crNbJourMap = null,
        typeEnvConvocId = null,
        typeEnvConvoc = null,
        numRecSanction = null,
        crNomAssistant = null,
        crPeriodeMap = null,
        estAvecDateEnt = null,
        convocComment = null,
        sancComment = null,
        dateFaits = null,
        file = [],

        creationDate = null,
        modifDate = null,
        createur = null,

        historique = [],

        salManuel = null,
        cenManuel = null,
        salManagerManuel = null,
        salMailManuel = null,
        salManagerMailManuel = null,
        estSaisieManuelle = false,

        acces = [],

        dateDistributionSanction = null,
        dateDistributionConvoc = null,

        rhDateDepart = null,
        rhEstVisibleReport = true,
        rhComment = null,

        repPers = false
    } = {}) {
        this.id = id
        this.salId = salId
        this.sal = sal ? new Salarie(sal) : null
        this.cenId = cenId
        this.cen = cen ? new Centre(cen) : null
        this.salManagerId = salManagerId
        this.salManager = salManager ? new Salarie(salManager) : null
        this.salDirecteur = salDirecteur ? new Salarie(salDirecteur) : null
        this.affecteeId = affecteeId
        this.affectee = affectee ? new Salarie(affectee) : null
        this.fait = fait
        this.avecMapConserv = avecMapConserv
        this.typeSanEnvisageId = typeSanEnvisageId
        this.typeSanEnvisage = typeSanEnvisage ? new TypeSanction(typeSanEnvisage) : null
        this.numRecConvoc = numRecConvoc
        this.dateEnvoiConvoc = dateEnvoiConvoc ? new Date(dateEnvoiConvoc) : null
        this.datePresentConvoc = datePresentConvoc ? new Date(datePresentConvoc) : null
        this.avecLicenciement = avecLicenciement
        this.dateEntretien = dateEntretien ? new Date(dateEntretien) : null
        this.crDate = crDate ? new Date(crDate) : null
        this.crLieu = crLieu
        this.crFait = crFait
        this.crConsequence = crConsequence
        this.crAReconnu = crAReconnu
        this.crEstAssiste = crEstAssiste
        this.typeSanCrDecisionId = typeSanCrDecisionId
        this.crEnAttente = !!typeSanCrDecisionId
        this.typeSanCrDecision = typeSanCrDecision ? new TypeSanction(typeSanCrDecision) : null
        this.typeSanReelId = typeSanReelId
        this.typeSanReel = typeSanReel ? new TypeSanction(typeSanReel) : null
        this.typeEnvId = typeEnvId
        this.typeEnv = typeEnv ? new TypeEnvoi(typeEnv) : null
        this.dateEnvoiSanction = dateEnvoiSanction ? new Date(dateEnvoiSanction) : null
        this.dateReceptSanction = dateReceptSanction ? new Date(dateReceptSanction) : null
        this.nbJourMap = nbJourMap
        this.estCloture = estCloture
        this.dateCreation = dateCreation ? new Date(dateCreation) : null
        this.origineCreation = origineCreation
        this.dateModif = dateModif ? new Date(dateModif) : null
        this.origineModif = origineModif
        this.etape = etape //TODO ?
        this.crNbJourMap = crNbJourMap
        this.typeEnvConvocId = typeEnvConvocId
        this.typeEnvConvoc = typeEnvConvoc ? new TypeEnvoi(typeEnvConvoc) : null
        this.numRecSanction = numRecSanction
        this.crNomAssistant = crNomAssistant
        this.crPeriodeMap = crPeriodeMap
        this.estAvecDateEnt = estAvecDateEnt
        this.convocComment = convocComment
        this.sancComment = sancComment
        this.dateFaits = dateFaits
        this.file = (() => {
            if (Array.isArray(file))
                return file?.map(x => new File(x))
            else if (file && typeof file === 'object')
                return [new File(file)]
            else
                return []
        })()

        this.creationDate = creationDate ? new Date(creationDate) : null
        this.modifDate = modifDate ? new Date(modifDate) : null
        this.createur = createur //TODO ?

        this.historique = (() => {
            if (Array.isArray(historique))
                return historique?.map(x => new Historique(x))
            else if (historique && typeof historique === 'object')
                return [new Historique(historique)]
            else
                return []
        })()

        this.salManuel = salManuel
        this.cenManuel = cenManuel
        this.salManagerManuel = salManagerManuel
        this.salMailManuel = salMailManuel
        this.salManagerMailManuel = salManagerMailManuel
        this.estSaisieManuelle = estSaisieManuelle

        this.acces = acces
        this.hasAccess = {
            readProc: !!acces?.find(x => x.libelle === "READ_PROC")?.aAcces ?? false,
            ecritureGeneralProc: !!acces?.find(x => x.libelle === "ECRITURE_GENERAL_PROC")?.aAcces ?? false,
            ecritureConvocationProc: !!acces?.find(x => x.libelle === "ECRITURE_CONVOCATION_PROC")?.aAcces ?? false,
            ecritureEentretienProc: !!acces?.find(x => x.libelle === "ECRITURE_ENTRETIEN_PROC")?.aAcces ?? false,
            ecritureSanctionProc: !!acces?.find(x => x.libelle === "ECRITURE_SANCTION_PROC")?.aAcces ?? false,
            ecritureJustificatifProc: !!acces?.find(x => x.libelle === "ECRITURE_JUSTIFICATIF_PROC")?.aAcces ?? false,
            ecritureAffecteAProc: !!acces?.find(x => x.libelle === "ECRITURE_AFEFCTE_A_PROC")?.aAcces ?? false,
            suppressionProc: !!acces?.find(x => x.libelle === "SUPPRESSION_PROC")?.aAcces ?? false,
            ecritureManuelleProc: !!acces?.find(x => x.libelle === "ECRITURE_MANUELLE_PROC")?.aAcces ?? false,
            cloturerProc: !!acces?.find(x => x.libelle === "CLOTURER_PROC")?.aAcces ?? false,
            validerProc: !!acces?.find(x => x.libelle === "VALIDER_PROC")?.aAcces ?? false,
            ecritureRhProc: !!acces?.find(x => x.libelle === "ECRITURE_RH_PROC")?.aAcces ?? false,
        }

        this.dateDistributionSanction = dateDistributionSanction ? new Date(dateDistributionSanction) : null
        this.dateDistributionConvoc = dateDistributionConvoc ? new Date(dateDistributionConvoc) : null

        this.rhDateDepart = rhDateDepart ? new Date(rhDateDepart) : null
        this.rhEstVisibleReport = !!rhEstVisibleReport
        this.rhComment = rhComment

        this.repPers = repPers
    }
}

/**
 * Procedure Object used to bind error message
 */
export class ErrorProcedure {
    id
    salId
    sal
    cenId
    cen
    salManagerId
    salManager
    affecteeId
    affectee
    fait
    avecMapConserv
    typeSanEnvisageId
    typeSanEnvisage
    numRecConvoc
    dateEnvoiConvoc
    datePresentConvoc
    avecLicenciement
    dateEntretien
    crDate
    crLieu
    crFait
    crConsequence
    crAReconnu
    crEstAssiste
    typeSanCrDecisionId
    typeSanCrDecision
    typeSanReelId
    typeSanReel
    typeEnvId
    typeEnv
    dateEnvoiSanction
    dateReceptSanction
    nbJourMap
    estCloture
    dateCreation
    origineCreation
    dateModif
    origineModif
    etape
    crNbJourMap
    typeEnvConvocId
    typeEnvConvoc
    numRecSanction
    crNomAssistant
    crPeriodeMap
    estAvecDateEnt
    convocComment
    sancComment
    dateFaits
    creationDate
    modifDate
    createur
    salManuel
    cenManuel
    salManagerManuel
    salMailManuel
    salManagerMailManuel
    estSaisieManuelle

    dateDistributionSanction
    dateDistributionConvoc

    rhDateDepart
    rhEstVisibleReport
    rhComment
}