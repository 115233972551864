import { TypeEnvoi } from "request/objects/typeEnvoi"
import { TypeSanction } from "request/objects/typeSanction"
import { Types } from 'static/types'
// import { Centre } from "request/objects/centre"
import { Parametre } from "request/objects/parametre"
import { Societe } from "request/objects/societe"
import { Salarie } from "request/objects/salarie"
import { PagesType } from 'static/pages'// eslint-disable-line

/**
 * @type {PagesType[]}
 */
export const administrationsList = [
    {
        key: 'sanctions',
        name: 'Type de sanction',
        object: TypeSanction,
        access: {
            isCreate: true,
            isRead: true,
            isUpdate: true,
            isDelete: true
        },
        columns: [
            {
                fieldName: 'libelle',
                name: "Libellé de la sanction",
                type: Types.STRING
            }
        ]
    },
    {
        key: 'envois',
        name: 'Type d\'envoi',
        object: TypeEnvoi,
        access: {
            isCreate: true,
            isRead: true,
            isUpdate: true,
            isDelete: true
        },
        columns: [
            {
                fieldName: 'libelle',
                name: "Libellé de l'envoi",
                type: Types.STRING
            }
        ]
    },
    // {
    //     key: 'centres',
    //     name: 'Centre',
    //     object: Centre,
    //     access: {
    //         isCreate: false,
    //         isRead: true,
    //         isUpdate: true,
    //         isDelete: false
    //     },
    //     columns: [
    //         {
    //             fieldName: 'code',
    //             name: "Code",
    //             type: Types.STRING,
    //         },
    //         {
    //             fieldName: 'nom',
    //             name: "Nom",
    //             type: Types.STRING,
    //         }
    //     ]
    // },
    {
        key: 'courriers',
        name: 'Courriers',
        object: Societe,
        access: {
            isCreate: false,
            isRead: true,
            isUpdate: true,
            isDelete: false
        },
        columns: [
            {
                fieldName: 'code',
                name: "Code",
                type: Types.STRING,
            },
            {
                fieldName: 'libelle',
                name: "Nom",
                type: Types.STRING,
            }
        ]
    },
    {
        key: 'parametres',
        name: 'Paramètres',
        object: Parametre,
        access: {
            isCreate: false,
            isRead: true,
            isUpdate: true,
            isDelete: false
        },
        columns: [
            {
                fieldName: 'description',
                name: "Description",
                type: Types.STRING,
            },
            {
                fieldName: 'valeur',
                name: "Valeur",
                type: Types.STRING,
            }
        ]
    },
    {
        key: 'utilisateurs',
        name: 'Utilisateurs',
        object: Salarie,
        access: {
            isCreate: false,
            isRead: true,
            isUpdate: true,
            isDelete: false
        },
        columns: [
            {
                fieldName: 'nom',
                name: "Salarié",
                type: Types.STRING,
                onRender: (salarie) => `${salarie.nom ?? ''} ${salarie.prenom ?? ''}`
            },
            {
                fieldName: 'login',
                name: "Email",
                type: Types.STRING
            },
            {
                fieldName: 'departementId',
                name: "Département",
                type: Types.STRING,
                onRender: (salarie) => salarie?.departement?.nom
            }
        ]
    }
]