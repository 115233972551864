import { Societe } from 'request/objects/societe' // eslint-disable-line

/**
 * Centre Object
 */
export class Centre {
    /**
     * @param {object} data
     * @param {number=} data.id
     * @param {string=} data.code
     * @param {string=} data.nom
     * @param {string=} data.ville
     * @param {boolean=} data.estArchive
     * @param {string=} data.dateCreation
     * @param {string=} data.origineCreation
     * @param {string=} data.dateModif
     * @param {string=} data.origineModif
     * @param {string=} data.adr1
     * @param {string=} data.adr2
     * @param {string=} data.adr3
     * @param {string=} data.cp
     * @param {number=} data.socId
     * @param {object=} data.soc 
     */
    constructor({
        id = 0,
        code = null,
        nom = null,
        ville = null,
        estArchive = null,
        dateCreation = null,
        origineCreation = null,
        dateModif = null,
        origineModif = null,
        adr1 = null,
        adr2 = null,
        adr3 = null,
        cp = null,
        socId = null,
        soc = null
    } = {}) {
        this.id = id
        this.code = code
        this.nom = nom
        this.ville = ville
        this.estArchive = estArchive
        this.dateCreation = dateCreation ? new Date(dateCreation) : null
        this.origineCreation = origineCreation
        this.dateModif = dateModif ? new Date(dateModif) : null
        this.origineModif = origineModif
        this.adr1 = adr1
        this.adr2 = adr2
        this.adr3 = adr3
        this.cp = cp
        this.socId = socId
        this.soc = soc ? new Societe(soc) : null
    }
}

/**
 * Centre Object used to bind error message
 */
export class ErrorCentre {
    id
    code
    nom
    ville
    estArchive
    dateCreation
    origineCreation
    dateModif
    origineModif
    adr1
    adr2
    adr3
    cp
    socId
    soc
}