import React, { PureComponent } from 'react'
// @ts-ignore
import { Menu as BulmaMenu } from 'react-bulma-components'
import { Icon, Text } from 'office-ui-fabric-react'
import classNames from 'classnames'
import { MenuElement } from 'pages/private/_layout' //eslint-disable-line
import { Link } from 'react-router-dom'

/**
 * @typedef {object} MenuProps
 * @property {MenuElement[]} menu Menu to display
 * @property {string} selectedKeyURL Selected Key URL
 * @property {function} changeStateMenu Change state of is menu open to parent componenet
 * 
 * @extends {PureComponent<MenuProps>}}
 */
export default class Menu extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {boolean} Is Menu Open ? */
            isMenuOpen: true
        }
    }

    render() {
        const { menu, selectedKeyURL, changeStateMenu } = this.props
        const { isMenuOpen } = this.state

        return (
            <BulmaMenu>
                <div
                    className={classNames("navbar-burger", { 'is-active': isMenuOpen })}
                    role="button"
                    tabIndex={0}
                    onClick={() => this.setState({ isMenuOpen: !this.state.isMenuOpen }, () => changeStateMenu(this.state.isMenuOpen))}
                >
                    <span></span><span></span><span></span>
                </div>
                <hr />
                {
                    menu.map((x, i) => {
                        /** @type {string} Variable to match subroutes when needed */
                        const selectedKey = (() => {
                            if (selectedKeyURL !== "/procedure/en-cours" && selectedKeyURL !== "/procedure/actions-rh" && selectedKeyURL.match(/\/procedure\/.*/g)) {
                                return '/procedure'
                            } else if (selectedKeyURL.match(/\/sanctions\/.*/g)) {
                                return '/administration/sanctions'
                            } else if (selectedKeyURL.match(/\/envois\/.*/g)) {
                                return '/administration/envois'
                            } else if (selectedKeyURL.match(/\/centres\/.*/g)) {
                                return '/administration/centres'
                            } else if (selectedKeyURL.match(/\/courriers\/.*/g)) {
                                return '/administration/courriers'
                            } else if (selectedKeyURL.match(/\/parametres\/.*/g)) {
                                return '/administration/parametres'
                            } else if (selectedKeyURL.match(/\/utilisateurs\/.*/g)) {
                                return '/administration/utilisateurs'
                            }
                            return selectedKeyURL
                        })()

                        return (
                            <React.Fragment key={i}>
                                {

                                    <BulmaMenu.List title={x.name ? x.name : ''}>
                                        {
                                            x?.links.map((y, j) => (
                                                <li key={i + "_" + j}>
                                                    <Link //BulmaMenu.List.Item
                                                        to={y.key}
                                                        className={classNames({ 'is-active': y.key === selectedKey })}
                                                    >
                                                        <Text variant="medium" ><Icon iconName={y.icon} /><span> {y.title}</span></Text>
                                                    </Link>
                                                    {y?.links?.length &&
                                                        <BulmaMenu.List>
                                                            {
                                                                y?.links.map((z, k) => (
                                                                    <Link //BulmaMenu.List.Item
                                                                        key={i + "_" + j + "_" + k}
                                                                        to={z.key}
                                                                        className={classNames({ 'is-active': z.key === selectedKey })}
                                                                    >
                                                                        <Text variant="medium"><Icon iconName={z.icon} /><span> {z.title}</span></Text>
                                                                    </Link>
                                                                ))
                                                            }
                                                        </BulmaMenu.List>
                                                    }
                                                </li>
                                            ))
                                        }
                                    </BulmaMenu.List>
                                }
                            </React.Fragment>
                        )
                    })
                }
            </BulmaMenu>
        )
    }
}