import { Parametre, ErrorParametre } from 'request/objects/parametre'
import ApiManager from 'request/apiManager'
import { NotImplementedError } from 'request/errors/notImplementedError'
import { UpsertResult } from 'request/objects/_upsertResult'

/**
 * ParametreManager
 * @extends {ApiManager<Parametre, ErrorParametre, UpsertResult>}
 */
export default class ParametreManager extends ApiManager {
    constructor() {
        super({ type: Parametre, errorType: ErrorParametre, upsertResult: UpsertResult, key: 'parametre' })
    }

    /**
     * @override
     * @returns {any}
     */
    create() {
        throw new NotImplementedError()
    }

    /**
     * @override
     * @returns {any}
     */
    removeById() {
        throw new NotImplementedError()
    }
}