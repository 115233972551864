/**
 * Societe Object
 */
export class Societe {
    /**
     * @param {object} data
     * @param {number=} data.id
     * @param {string=} data.libelle
     * @param {string=} data.signataire
     * @param {number=} data.courrierContenuChoix
     * @param {string=} data.code
     * @param {string=} data.footer
     * @param {boolean=} data.estUes
     * @param {boolean=} data.estRepPer
     * @param {string=} data.dateCreation
     * @param {string=} data.origineCreation
     * @param {string=} data.dateModif
     * @param {string=} data.origineModif
     */
    constructor({
        id = 0,
        libelle = null,
        signataire = null,
        courrierContenuChoix = null,
        code = null,
        footer = null,
        estUes = false,
        estRepPer = false,
        dateCreation = null,
        origineCreation = null,
        dateModif = null,
        origineModif = null
    } = {}) {
        this.id = id
        this.libelle = libelle
        this.signataire = signataire
        this.courrierContenuChoix = courrierContenuChoix
        this.code = code
        this.footer = footer
        this.estUes = estUes
        this.estRepPer = estRepPer
        this.dateCreation = dateCreation ? new Date(dateCreation) : null
        this.origineCreation = origineCreation
        this.dateModif = dateModif ? new Date(dateModif) : null
        this.origineModif = origineModif
    }
}

/**
 * Societe Object used to bind error message
 */
export class ErrorSociete {
    id
    libelle
    signataire
    courrierContenuChoix
    code
    footer
    estUes
    estRepPer
    dateCreation
    origineCreation
    dateModif
    origineModif
}