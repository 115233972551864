
/**
 * Types of data
 * @enum {string}
 * @readonly
 */
export const Types = {
    /** 
     * Type string, display simple input Text 
     * @type {string}
     * @constant
     */
    STRING: "STRING",
    /** 
     * Type string, display Textarea
     * @type {string}
     * @constant
     */
    STRING_LONG: "STRING_LONG",
    /** 
     * Type string, display simple input Text with a button
     * @type {string}
     * @constant
     */
    STRING_WITH_BUTTON: "STRING_WITH_BUTTON",
    /** 
     * Type int, display input integer 
     * @type {string}
     * @constant
     */
    INT: "INT",
    /** 
     * Type number, display input floating number
     * @type {string}
     * @constant
     */
    FLOAT: "FLOAT",
    /** Type date, display datepicker 
     * @type {string}
     * @constant
     */
    DATE: "DATE",
    /** Type date, display datepicker 
     * @type {string}
     * @constant
     */
    DATETIME: "DATETIME",
    /** Type list, display dropdown 
     * @type {string}
     * @constant
     */
    LIST: "LIST",
    /** Type list, display dropdown with multiple choices
     * @type {string} 
     * @constant
     */
    LIST_MULTIPLE: "LIST_MULTIPLE",
    /** Type boolean, display dropdown with Oui/Non
     * @type {string} 
     * @constant
     */
    BOOLEAN: "BOOLEAN",
    /** Type email, display textfield type email
     * @type {string} 
     * @constant
     */
    EMAIL: "EMAIL",
    /** Type month and year, display dropdown 
     * @type {string}
     * @constant
     */
    MONTHYEAR: "MONTHYEAR",
}