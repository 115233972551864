import React, { PureComponent } from 'react'
// @ts-ignore
import { Columns } from 'react-bulma-components'
import { Text, MessageBar } from 'office-ui-fabric-react'
import { AppProps } from 'app' // eslint-disable-line

/**
 * @typedef {object} PublicLayoutProps
 * @property {boolean} isDisplay Is layout is displayed
 * 
 * @extends {PureComponent<AppProps & PublicLayoutProps>}}
 */
export default class PublicLayout extends PureComponent {

    render() {
        if (!this.props.isDisplay) return this.props.children

        return (
            <section id="public-layout" style={{ background: `url(${require('assets/img/bg.png').default}), white` }}>
                <Columns>
                    <Columns.Column className="is-hidden-touch" />

                    <Columns.Column >
                        <div className="flex-col">
                            <div className="app-card has-text-centered">
                                <img src={require('assets/img/logo.png').default} alt={process.env.REACT_APP_SHORT_NAME} />
                                <br />
                                <Text variant="xxLarge" block>
                                    {process.env.REACT_APP_NAME}
                                </Text>
                                <br />

                                {
                                    this.props.messageBar.isDisplayed &&
                                    <>
                                        <MessageBar
                                            messageBarType={this.props.messageBar.type}
                                            isMultiline={false}
                                            truncated={true}
                                            onDismiss={() => this.props.setMessageBar({ isDisplayed: false })}
                                        >
                                            {this.props.messageBar.message}
                                        </MessageBar>
                                        <br />
                                    </>
                                }
                                {this.props.children}
                            </div>
                        </div>
                    </Columns.Column>
                    <Columns.Column className="is-hidden-touch" />
                </Columns>
            </section>
        )
    }
}