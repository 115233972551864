/**
 * @typedef {object} DlFile
 * @property {string} type
 * @property {string} content
 */

/**
 * Generate a CSV from data
 * @param {string[][]} data 
 * @returns {DlFile} CSV to download
 */
export default function generateCsv(data) {
    return {
        type: "data:text/csv;charset=utf-8,%EF%BB%BF",
        content: data.map(x => x.join(";")).join("\n")
    }
}