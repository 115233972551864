/**
 * Parametre Object
 */
export class Parametre {
    /**
     * @param {object} data
     * @param {number=} data.id
     * @param {string=} data.groupe
     * @param {string=} data.code
     * @param {string=} data.valeur
     * @param {string=} data.description
     * @param {boolean=} data.estVerrou
     * @param {string=} data.dateCreation
     * @param {string=} data.origineCreation
     * @param {string=} data.dateModif
     * @param {string=} data.originemodif
     */
    constructor({
        id = 0,
        groupe = null,
        code = null,
        valeur = null,
        description = null,
        estVerrou = null,
        dateCreation = null,
        origineCreation = null,
        dateModif = null,
        originemodif = null
    } = {}) {
        this.id = id
        this.groupe = groupe
        this.code = code
        this.valeur = valeur
        this.description = description
        this.estVerrou = estVerrou
        this.dateCreation = dateCreation ? new Date(dateCreation) : null
        this.origineCreation = origineCreation
        this.dateModif = dateModif ? new Date(dateModif) : null
        this.originemodif = originemodif
    }
}

/**
 * Parametre Object used to bind error message
 */
export class ErrorParametre {
    id
    groupe
    code
    valeur
    description
    estVerrou
    dateCreation
    origineCreation
    dateModif
    originemodif
}