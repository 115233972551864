/**
 * Flat object with nested properties
 * @param {object} obj Object
 * @param {object=} parent Parent object
 * @param {object=} res Final result
 */
export default function _flattenObj(obj, parent, res = {}) {
    for (let key in obj) {
        let propName = parent ? parent + '.' + key : key
        if (typeof obj[key] === 'object' && Object.prototype.toString.call(obj[key]) !== '[object Date]') {
            _flattenObj(obj[key], propName, res)
        } else {
            res[propName] = obj[key]
        }
    }
    return res
}