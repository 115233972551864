import { DlFile } from "helpers/methods/csv" // eslint-disable-line

export const HandleBlop = {
    /**
     * Download a blob
     * @param {Blob | DlFile} file File to download
     * @param {string} fileName Name of your file
     * @param {boolean} fromUri Is from Uri instead of blop
     */
    download(file, fileName, fromUri = false) {
        let a = document.createElement('a')
        a.href = fromUri ?
            `${/** @type {DlFile} */(file).type}${encodeURI(/** @type {DlFile} */(file).content)}` :
            window.URL.createObjectURL(file)
        a.download = fileName
        if (document.querySelector('.ms-Dialog-actionsRight')) { //Workaround to dl from a modal
            document.querySelector('.ms-Dialog-actionsRight').appendChild(a)
        } else {
            document.body.appendChild(a)
        }
        a.click()
        a.remove()
    },
    /**
     * Open a blob
     * @param {Blob} file File to open
     */
    open(file) {
        window.open(window.URL.createObjectURL(file), '_blank')
    },

    /**
     * Encode blob to base64
     * @param {Blob} blob blob to encode
     * @returns {Promise<any>}
     */
    blobToBase64(blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    }
}