import React, { PureComponent } from 'react'
// @ts-ignore
import { Navbar } from 'react-bulma-components'
import { Icon, Panel, Label, TextField } from 'office-ui-fabric-react'
import { history } from 'helpers/history'
import { MenuElement } from 'pages/private/_layout' //eslint-disable-line
import enhanceWithClickOutside from 'react-click-outside'
import { Link } from 'react-router-dom'
import { UserState } from 'redux/slices/user'// eslint-disable-line
import { Time } from 'helpers/methods/time'

const Header = enhanceWithClickOutside(
    /**
    * @typedef {object} HeaderProps
    * @property {MenuElement[]} menu Menu to display
    * @property {UserState["me"]} me User informationsparam
    * @property {UserState["param"]} param Constants
    * @property {function():void} signOut Sign out
    * @property {function():void} refresh Refresh app
    * 
    * @extends {PureComponent<HeaderProps>}}
    */
    class extends PureComponent {
        constructor(props) {
            super(props)
            this.state = {
                /** @type {boolean} Is burger menu open on mobile */
                isActive: false,
                /** @type {boolean} Is Panel user open */
                isOpenUser: false
            }
        }

        /**
         * Handle click outside component
         */
        handleClickOutside() {
            this.setState({ isActive: false })
        }

        render() {
            const { menu, me, signOut, refresh, param } = this.props
            const { isActive } = this.state

            return (
                <>
                    <Navbar
                        active={isActive}
                        transparent={false}
                    >
                        <Navbar.Brand>
                            <Navbar.Item
                                renderAs="a"
                                onClick={() => history.push('/')}
                            >
                                <img src={require('assets/img/logo.png').default} alt={process.env.REACT_APP_SHORT_NAME} />
                                <span> {process.env.REACT_APP_SHORT_NAME}</span>
                            </Navbar.Item>
                            <Navbar.Burger onClick={() => this.setState({ isActive: !this.state.isActive })} />
                        </Navbar.Brand>
                        <Navbar.Menu >
                            <Navbar.Container className="is-hidden-desktop">
                                {
                                    menu.map((x, i) => (
                                        <Navbar.Item dropdown key={i}>
                                            {
                                                x.name &&
                                                <Navbar.Link arrowless={true}>
                                                    {x.name}
                                                </Navbar.Link>
                                            }
                                            <Navbar.Dropdown>
                                                {
                                                    x?.links.map((y, j) => (
                                                        <React.Fragment key={i + "_" + j} >
                                                            <Link
                                                                className="navbar-item"
                                                                to={y.key}
                                                                onClick={() => this.setState({ isActive: false })}
                                                            >
                                                                <Icon iconName={y.icon} /><span> {y.title}</span>
                                                            </Link>
                                                            {
                                                                y?.links?.length &&
                                                                y?.links.map((z, k) => (
                                                                    <Link
                                                                        key={i + "_" + j + "_" + k}
                                                                        className="navbar-item"
                                                                        to={z.key}
                                                                        onClick={() => this.setState({ isActive: false })}
                                                                    >
                                                                        <Icon iconName={z.icon} /><span> {z.title}</span>
                                                                    </Link>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                    )
                                                    )
                                                }
                                            </Navbar.Dropdown>
                                        </Navbar.Item>
                                    ))
                                }
                                <Navbar.Item dropdown>
                                    <Navbar.Link arrowless={true}>
                                        Autre
                                </Navbar.Link>
                                    <Navbar.Dropdown>
                                        <Navbar.Item
                                            onClick={() => this.setState({ isActive: false }, () => refresh())}
                                        >
                                            <Icon iconName='Refresh' /> Rafraichir
                                    </Navbar.Item>
                                        <Navbar.Item
                                            onClick={() => this.setState({ isOpenUser: true })}
                                        >
                                            <Icon iconName='Contact' /> Utilisateur
                                    </Navbar.Item>
                                        <Navbar.Item
                                            onClick={() => signOut()}
                                        >
                                            <Icon iconName='ReleaseGate' /> Se déconnecter
                                    </Navbar.Item>
                                    </Navbar.Dropdown>
                                </Navbar.Item>
                            </Navbar.Container>
                            <Navbar.Container position="end" className="is-hidden-touch">
                                <Navbar.Item
                                    className="is-tab"
                                    onClick={() => refresh()}
                                >
                                    <Icon iconName='Refresh' />
                                </Navbar.Item>
                                <Navbar.Item
                                    className="is-tab"
                                    onClick={() => this.setState({ isOpenUser: true })}
                                >
                                    <Icon iconName='Contact' />
                                </Navbar.Item>
                                <Navbar.Item
                                    className="is-tab"
                                    onClick={() => signOut()}
                                >
                                    <Icon iconName='ReleaseGate' />
                                </Navbar.Item>
                            </Navbar.Container>
                        </Navbar.Menu>
                    </Navbar>
                    <Panel
                        className="panel-user"
                        headerText="Votre compte"
                        isLightDismiss={true}
                        isOpen={this.state.isOpenUser}
                        onDismiss={() => this.setState({ isOpenUser: false })}
                        closeButtonAriaLabel="Close"
                    >
                        <br />
                        <Label htmlFor="username">Nom utilisateur</Label>
                        <TextField
                            id="username"
                            value={`${me?.nom ?? ''} ${me?.prenom ?? ''}`}
                            borderless={true}
                            readOnly={true}
                            inputClassName="is-capitalized"
                        />
                        <br />
                        <Label htmlFor="creation">Créé le</Label>
                        <TextField
                            id="creation"
                            value={me?.creationDate ? Time(me?.creationDate).getCleanDate() : ''}
                            borderless={true}
                            readOnly={true}
                            inputClassName="is-capitalized"
                        />
                        <br />
                        {!!me?.manager && <>
                            <Label htmlFor="manager">Managé par</Label>
                            <TextField
                                id="manager"
                                value={`${me?.manager?.nom ?? ''} ${me?.manager?.prenom ?? ''}`}
                                borderless={true}
                                readOnly={true}
                                inputClassName="is-capitalized"
                            />
                            <br />
                        </>}
                        <Label htmlFor="restrictions">Restrictions</Label>
                        <TextField
                            id="restrictions"
                            value={me?.restrictionId?.map(x => param.restriction?.find(y => y.key === x)?.text).filter(x => x).join(', ')}
                            borderless={true}
                            readOnly={true}
                            inputClassName="is-capitalized"
                        />
                    </Panel>
                </>
            )
        }
    })

export default Header