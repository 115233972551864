import React, { PureComponent } from 'react'
import { Breadcrumb, MessageBar, CommandBar, Icon } from 'office-ui-fabric-react'
import { IIconProps } from 'office-ui-fabric-react' // eslint-disable-line
import Header from 'components/_layout/header';
import { history } from 'helpers/history'
import Menu from 'components/_layout/menu'
import { AppProps } from 'app' // eslint-disable-line
import classNames from 'classnames'
import { Link } from 'react-router-dom';
import { administrationsList } from 'static/administration/administrationsList';
import { proceduresList } from 'static/procedure/proceduresList';
import ScrollToTop from 'components/visuals/scrollToTop';

/**
 * @typedef {object} MenuElement
 * @property {string} name Element name
 * @property {boolean} isDisplay Is element display
 * @property {LinkElement[]} links Refresh app
 * 
 * @typedef {object} LinkElement
 * @property {string} key Element key and URL
 * @property {string} title Element title
 * @property {IIconProps['iconName']} icon Element icon
 * @property {boolean} isDisplay Is element display
 * @property {LinkElement[]=} links Refresh app
 */

/**
 * @typedef {object} LayoutProps
 * @property {boolean} isDisplay Is layout is displayed
 * @property {function} refresh Refresh app
 * 
 * @extends {PureComponent<AppProps & LayoutProps>}}
 */
export default class Layout extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {MenuElement[]} */
            menu: [],
            /** @type {boolean} Is Left Menu Open ? */
            isLeftMenuOpen: true
        }
    }

    /** 
     * @inheritdoc
     */
    componentDidMount() {
        this.props.setUrl(history.location.pathname)
    }

    /** 
     * @inheritdoc
     * @param {object} prevProps 
     */
    componentDidUpdate(prevProps) {
        if (prevProps.isDisplay !== this.props.isDisplay && !this.props.isDisplay) {
            this.setState({ menu: [] })
        }

        //Re render menu if user when info user change 
        if (prevProps.me !== this.props.me) {
            this.setState({
                menu: /** @type {MenuElement[]} */([
                    // {
                    //     name: '',
                    //     isDisplay: true,
                    //     links: [
                    //         {
                    //             key: '/',
                    //             title: 'Accueil',
                    //             icon: 'Home',
                    //             isDisplay: true
                    //         },
                    //     ]
                    // },
                    {
                        name: 'Procédure',
                        isDisplay: true,
                        links: proceduresList.map(x => ({
                            key: x.key !== '' ? `/procedure/${x.key}` : '/procedure',
                            title: x.name,
                            icon: 'PeopleAlert',
                            isDisplay: (() => {
                                switch (x.key) {
                                    case 'actions-rh':
                                        return this.props.me.restrictionId.filter(x => [2, 3, 5].includes(x)).length > 0
                                    case 'en-cours':
                                    case '':
                                    default:
                                        return true
                                }
                            })()
                        }))
                    },
                    {
                        name: 'Administration',
                        isDisplay: this.props.me.restrictionId.includes(3),
                        links: administrationsList.map(x => ({
                            key: `/administration/${x.key}`,
                            title: x.name,
                            icon: 'DataManagementSettings',
                            isDisplay: true
                        }))
                    }
                ]
                )
            })
        }
    }

    /**
     * Filter menu with element that must be displayed
     * @param {MenuElement[]} menu 
     * @returns {MenuElement[]} Filtered menu
     */
    _filterMenu(menu) {
        return menu
            .filter(group => group.isDisplay)
            .map(group => {
                return {
                    ...group,
                    links: group.links.filter(link => link.isDisplay)
                }
            })
    }

    render() {
        const { selectedKeyURL, breadcrumb, command, messageBar, me, param, signOut } = this.props
        const { menu, isLeftMenuOpen } = this.state

        if (!this.props.isDisplay) return this.props.children

        const filteredMenu = this._filterMenu(menu)

        return (
            <section id="private-layout">
                <Header
                    menu={filteredMenu}
                    me={me}
                    param={param}
                    refresh={() => this.setState({ menu: [] }, () => this.props.refresh())}
                    signOut={signOut}
                />
                <div className="content-layout" >
                    <div className={classNames('menu-layout', 'is-hidden-touch', { 'is-close': !isLeftMenuOpen })}>
                        <Menu
                            changeStateMenu={newVal => this.setState({ isLeftMenuOpen: newVal })}
                            menu={filteredMenu}
                            selectedKeyURL={selectedKeyURL}
                        />
                    </div>
                    <div className="main-layout">
                        <CommandBar
                            items={command}
                        />
                        <div className="body-layout">
                            <div className="body-content-layout">
                                <Breadcrumb
                                    items={breadcrumb}
                                    maxDisplayedItems={5}
                                    tooltipHostProps={{ content: undefined }}
                                    onRenderItem={(props, defaultRender) => props.href ?
                                        <Link to={props.href}>
                                            {defaultRender({ ...props, href: undefined, })}
                                        </Link>
                                        :
                                        defaultRender(props)
                                    }
                                />
                                {
                                    messageBar?.isDisplayed &&
                                    <>
                                        <MessageBar
                                            messageBarType={this.props.messageBar.type}
                                            isMultiline={false}
                                            truncated={true}
                                            onDismiss={() => this.props.setMessageBar({ isDisplayed: false })}
                                        >
                                            {messageBar.message}
                                        </MessageBar>
                                        <br />
                                    </>
                                }
                                <ScrollToTop>
                                    <Icon iconName="ChevronUp" />
                                </ScrollToTop>
                                {this.props.children}
                                <br />
                            </div>
                        </div>
                        <CommandBar
                            items={command}
                        />
                    </div>
                </div>
            </section>
        )
    }
}
