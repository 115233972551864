/**
 * File Object
 */
export class File {
    /**
     * @param {object} data
     * @param {number=} data.id
     * @param {string=} data.nom
     * @param {number=} data.procId
     */
    constructor({
        id = 0,
        nom = null,
        procId = 0
    } = {}) {
        this.id = id
        this.nom = nom
        this.procId = procId
    }
}

/**
 * File Object used to bind error message
 */
export class ErrorFile {
    id
    nom
    procId
}