/**
 * All param
 */
export class Param {
    /**
     * @param {object} data 
     */
    constructor(data = []) {
        /** @type {ParamElement[]} Managers */
        this.manager = []
        /** @type {ParamElement[]} Directeurs */
        this.directeur = []
        /** @type {ParamElement[]} Etats */
        this.etat = []
        /** @type {ParamElement[]} Societes */
        this.societe = []
        /** @type {ParamElement[]} Centres */
        this.centre = []
        /** @type {ParamElement[]} Departements */
        this.departement = []
        /** @type {ParamElement[]} Salariés */
        this.salarie = []
        /** @type {ParamElement[]} Salariés manuels */
        this.salarieManuel = []
        /** @type {ParamElement[]} Admin */
        this.admin = []
        /** @type {ParamElement[]} Type envoi */
        this.typeEnvoi = []
        /** @type {ParamElement[]} Type sanction */
        this.typeSanction = []
        /** @type {ParamElement[]} Restrictions */
        this.restriction = []
        /** @type {ParamElement[]} Rh */
        this.rh = []

        for (const param in data)
            if (Array.isArray(data[param]))
                this[param] = data[param].map(x => new ParamElement(x))
            else if (data[param] && typeof data[param] === 'object')
                this[param] = [new ParamElement(data[param])]
    }

}

/**
 * One Param Element
 */
export class ParamElement {
    /**
     * @param {object} data
     * @param {(string | number)=} data.key
     * @param {string=} data.text
     * @param {number=} data.cenId Used only on salarie
     * @param {number=} data.managerId Used only on salarie
     * @param {boolean=} data.estArchive Used only on salarie
     * @param {number=} data.socId Used only on centre
     */
    constructor({
        key = 0,
        text = "-",

        cenId = null,
        managerId = null,
        estArchive = false,

        socId = null,
    } = {}) {
        this.key = key
        this.text = text?.toString()?.trim()

        this.cenId = cenId
        this.managerId = managerId
        this.estArchive = !!estArchive

        this.socId = socId
    }
}