/**
 * TypeSanction Object
 */
export class TypeSanction {
    /**
     * @param {object} data
     * @param {number=} data.id
     * @param {string=} data.code
     * @param {string=} data.libelle
     * @param {string=} data.dateCreation
     * @param {string=} data.origineCreation
     * @param {string=} data.dateModif
     * @param {string=} data.origineModif
     */
    constructor({
        id = 0,
        code = null,
        libelle = null,
        dateCreation = null,
        origineCreation = null,
        dateModif = null,
        origineModif = null
    } = {}) {
        this.id = id
        this.code = code
        this.libelle = libelle
        this.dateCreation = dateCreation ? new Date(dateCreation) : null
        this.origineCreation = origineCreation
        this.dateModif = dateModif ? new Date(dateModif) : null
        this.origineModif = origineModif
    }
}

/**
 * TypeSanction Object used to bind error message
 */
export class ErrorTypeSanction {
    id
    libelle
    code
    dateCreation
    origineCreation
    dateModif
    origineModif
}