import React, { PureComponent } from 'react'
import { buildColumns, ConstrainMode, DefaultButton, DetailsList, DetailsListLayoutMode, MessageBarType, Pivot, PivotItem, SelectionMode, Spinner, SpinnerSize, Text, TextField, Toggle } from 'office-ui-fabric-react'
import { IColumn, ICommandBarItemProps } from 'office-ui-fabric-react'// eslint-disable-line
import { history } from 'helpers/history'
import { AppProps } from 'app' // eslint-disable-line
import { ErrorProcedure, Procedure } from 'request/objects/procedure'
import { Status } from 'static/status'
import { InvalidEntityError } from 'request/errors/invalidEntityError'
import { Card } from 'components/containers/card'
// import { GroupComponent } from 'components/containers/groupComponent'
import { Loader } from 'components/visuals/loader'
import { CancelRequestError } from 'request/errors/cancelRequestError'
import { NotImplementedError } from 'request/errors/notImplementedError'
import { _disableCommandBtn, _disableCommandSubBtn, _handleRenderColumn, _onColumnClick, _isValidDate } from 'helpers/methods/common'
import { UnauthorizedError } from 'request/errors/unauthorizedError'
// @ts-ignore
import { Columns } from 'react-bulma-components'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import { Time } from 'helpers/methods/time'
import { HandleBlop } from 'helpers/methods/blob'
import { File } from 'request/objects/file' // eslint-disable-line
import { Workflow } from 'components/visuals/workflow'
import { columnsHistoric } from 'static/procedure/columnsHistoric'
import { setTitle } from 'helpers/methods/pageTitle'
import classNames from 'classnames'

/**
 * @extends {PureComponent<AppProps>}}
 */
export default class OneProcedure extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!this.props.match?.params?.id,
            /** @type {boolean} Is file dragging in */
            isDragging: false,
            /** @type {Procedure} Element find from API */
            item: new Procedure(),
            /** @type {Procedure} Initial element fond from API. Used to refresh */
            iniItem: new Procedure(),
            /** @type {ErrorProcedure} Errors */
            errorField: new ErrorProcedure(),
            /** @type {IColumn[]} Columns displayed in historique */
            columnsHist: [],
        }

        this.times = ((step = 5, minHour = 0, maxHour = 0) => {
            const dt = new Date(1970, 0, 1)
            const res = []
            while (dt.getDate() === 1) {
                if (dt.getHours() >= minHour && dt.getHours() < maxHour)
                    res.push({
                        key: dt.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }),
                        text: dt.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
                    })
                dt.setMinutes(dt.getMinutes() + step)
            }
            return res
        })(15, 6, 22)

        this.submitInput = React.createRef()
        this.uploadFile = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.props.procedureManager.cancel()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.props?.match?.params?.id !== prevProps?.match?.params?.id) {
            this._init()
        }
        if (this.state.item?.sal?.id !== prevState.item?.sal?.id && !!this.state.item?.sal?.id) {
            setTitle(`Procédure de ${this.state.item?.sal?.nom?.toUpperCase()} ${this.state.item?.sal?.prenom}`)
            this.props.setBreadcrumb([
                { text: 'Saisie procédure', key: 'procedure' },
                { text: 'Toutes les procédures', key: 'all-procedure', href: '/procedure' },
                { text: `Procédure ${this.props?.match?.params?.id ? `de ${this.state.item?.sal?.nom?.toUpperCase()} ${this.state.item?.sal?.prenom}` : 'Nouveau'}`, key: 'one-procedure', isCurrentItem: true }
            ])
        }
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        setTitle(`Procédure`)
        this.props.setMessageBar({ isDisplayed: false })
        this._init()
    }

    /**
     * Init <DetailList>
     */
    _buildColumnsHist() {
        const cols = columnsHistoric?.reduce((obj, col) => ({ ...obj, [col.fieldName]: col.name }), {})

        const columns = buildColumns(
            [cols],
            true,
            this._onColumnClick.bind(this, { colName: "columnsHist", dataName: ['item', 'historique'], source: "state" })
        )

        for (const column of columns) {
            column.name = cols[column.name]
            column.maxWidth = (() => {
                switch (column.name) {
                    case "Date":
                    case "Utilisateur":
                        return 125
                    case "Champ":
                        return 150
                    case "Ancienne val.":
                    case "Nouvelle val.":
                        return 200
                    default:
                        return column.name.length * 12
                }
            })()
            column.minWidth = column.name.length * 5
            column.isSorted = column.name === "Date"
            column.isSortedDescending = column.name === "Date"

            column.onRender = columnsHistoric.find(x => x.name === column.name)?.onRender ?? this._handleRenderColumn(columnsHistoric, column)
        }

        return columns
    }

    /**
     * Set default anchor on loading
     * @param {Procedure} procedure 
     */
    _setDefaultAnchor(procedure) {
        if (!!history.location.hash)
            return

        if (procedure.etape === 0)
            history.replace({ hash: `#general` })
        else if (procedure.crDate !== null && procedure.crFait !== null && procedure.typeSanCrDecisionId !== null)
            history.replace({ hash: `#sanction` })
        else if (procedure.typeEnvConvocId != null || procedure.numRecConvoc != null || procedure.dateEnvoiConvoc != null || procedure.datePresentConvoc != null || procedure.convocComment != null)
            history.replace({ hash: `#entretien` })
        else
            history.replace({ hash: `#general` })
    }

    /**
     * Init Page
     */
    _init() {
        this.props.setBreadcrumb([
            { text: 'Saisie procédure', key: 'procedure' },
            { text: 'Toutes les procédures', key: 'all-procedure', href: '/procedure' },
            { text: `Procédure ${this.props?.match?.params?.id ? `` : 'Nouveau'}`, key: 'one-procedure', isCurrentItem: true }
        ])
        this.props.setCommand([])

        this._setupCommandBar()

        //If there is an id in URL, get element by id
        if (this.props?.match?.params?.id) {
            this.setState({ status: Status.PENDING, columnsHist: this._buildColumnsHist() }, async () => {
                if (this.props.location.state?.procedure) { //If object came from history push with a create
                    this._setDefaultAnchor(this.props.location.state?.procedure)
                    this.setState({
                        item: this.props.location.state?.procedure,
                        iniItem: this.props.location.state?.procedure,
                        status: Status.RESOLVED
                    }, () => {
                        this._setupCommandBar()
                        this.props.setCommand(this.commandRead)
                    })
                } else {
                    try {
                        const procedure = await this.props.procedureManager.getById(this.props?.match?.params?.id)
                        this._setDefaultAnchor(procedure)
                        this.setState({
                            item: procedure,
                            iniItem: procedure,
                            status: Status.RESOLVED
                        }, () => {
                            this._setupCommandBar()
                            this.props.setCommand(this.commandRead)
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                console.error(error)
                                break
                            default:
                                this.props.setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                console.error(error)
                                break
                        }
                    }
                }
            })
        } else {
            const { me: { hasAccess } } = this.props

            this.setState({
                item: {
                    ...new Procedure(),
                    hasAccess
                },
                columnsHist: this._buildColumnsHist()
            })

            this.props.setCommand(this.commandEdit)
        }
    }

    _setupCommandBar() {
        const { item } = this.state
        const { me } = this.props
        /** 
         * @type {ICommandBarItemProps[]} Commanbar items when readonly 
         */
        this.commandRead = [
            {
                key: 'edit',
                text: 'Modifier',
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false }, () => this.props.setCommand(this.commandEdit)),
                disabled: !me.hasAccess.ecritureProc //|| item.estCloture
            },
            {
                key: 'download',
                text: 'Télécharger',
                iconProps: { iconName: 'DownloadDocument' },
                subMenuProps: {
                    items: [
                        {
                            key: 'print-courrier',
                            text: 'Courrier',
                            iconProps: { iconName: 'TextDocumentShared' },
                            /** @type {any} */
                            onClick: async () => {
                                if (!this.state.item.dateEnvoiConvoc || !this.state.item.dateEntretien) {
                                    this.props.setMessageBar({
                                        isDisplayed: true,
                                        type: MessageBarType.warning,
                                        message: "Vous devez compléter les champs \"Date d'envoi\" et \"Date d'entretien\"",
                                    })
                                } else {
                                    try {
                                        const blob = await this.props.procedureManager.getFile(this.props.match?.params?.id, 'courier')
                                        HandleBlop.download(blob, `Courrier_procedure_n${this.props.match?.params?.id}.docx`)
                                    } catch (error) {
                                        switch (error?.constructor) {
                                            case CancelRequestError:
                                            case UnauthorizedError:
                                            case InvalidEntityError: break
                                            case NotImplementedError:
                                                console.error(error)
                                                break
                                            default:
                                                console.error(error)
                                                break
                                        }
                                    }
                                }
                            },
                            disabled: !me.restrictionId.includes(2) && !me.restrictionId.includes(3) && !me.restrictionId.includes(5)
                        },
                        {
                            key: 'print-cr',
                            text: 'Compte Rendu',
                            iconProps: { iconName: 'TextDocumentShared' },
                            /** @type {any} */
                            onClick: async () => {
                                try {
                                    const blob = await this.props.procedureManager.getFile(this.props.match?.params?.id, 'cr')
                                    HandleBlop.download(blob, `Compte_rendu_procedure_n${this.props.match?.params?.id}.docx`)
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            console.error(error)
                                            break
                                        default:
                                            console.error(error)
                                            break
                                    }
                                }
                            },
                            disabled: item.dateEntretien >= new Date()
                        }
                    ],
                },
            },
            {
                key: 'wf',
                text: 'Workflow',
                iconProps: { iconName: 'ProgressLoopOuter' },
                disabled: item?.estCloture,
                subMenuProps: {
                    items: [
                        {
                            key: 'wf-step',
                            text: 'Étape suivante',
                            iconProps: { iconName: 'CheckboxComposite' },
                            onClick: () => this.setState({ status: Status.PENDING }, async () => {
                                this.props.setCommand([])
                                try {
                                    const result = await this.props.procedureManager.validateWf(this.props?.match?.params?.id)
                                    const procedure = await this.props.procedureManager.getById(result.id)

                                    this.setState({
                                        item: procedure,
                                        iniItem: procedure,
                                        status: Status.RESOLVED
                                    }, () => {
                                        this._setupCommandBar()
                                        this.props.setCommand(this.commandRead)
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError: break
                                        case NotImplementedError:
                                            console.error(error)
                                            break
                                        case InvalidEntityError:
                                            const err = /** @type {InvalidEntityError<ErrorProcedure>} */(error)
                                            this.setState({ errorField: err.errorField })
                                            this.props.setCommand(this.commandRead)
                                            break
                                        default:
                                            this.setState({ status: Status.REJECTED })
                                            this.props.setCommand(this.commandRead)
                                            console.error(error)
                                            break
                                    }
                                }
                            }),
                            disabled: item?.etape > 4 || !item?.hasAccess?.validerProc
                        },
                        {
                            key: 'wf-step',
                            text: 'Étape précédente',
                            iconProps: { iconName: 'CheckboxComposite' },
                            onClick: () => this.setState({ status: Status.PENDING }, async () => {
                                this.props.setCommand([])
                                try {
                                    const result = await this.props.procedureManager.validateWfCancel(this.props?.match?.params?.id)
                                    const procedure = await this.props.procedureManager.getById(result.id)

                                    this.setState({
                                        item: procedure,
                                        iniItem: procedure,
                                        status: Status.RESOLVED
                                    }, () => {
                                        this._setupCommandBar()
                                        this.props.setCommand(this.commandRead)
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError: break
                                        case NotImplementedError:
                                            console.error(error)
                                            break
                                        case InvalidEntityError:
                                            const err = /** @type {InvalidEntityError<ErrorProcedure>} */(error)
                                            this.setState({ errorField: err.errorField })
                                            this.props.setCommand(this.commandRead)
                                            break
                                        default:
                                            this.setState({ status: Status.REJECTED })
                                            this.props.setCommand(this.commandRead)
                                            console.error(error)
                                            break
                                    }
                                }
                            }),
                            disabled: item?.etape < 2 || !item?.hasAccess?.validerProc
                        },
                        {
                            key: 'wf-close',
                            text: 'Clôturer',
                            iconProps: { iconName: 'WorkFlow' },
                            onClick: () => this.props.setModal({
                                show: true,
                                title: 'Clôturer la procédure',
                                subTitle: 'Êtes-vous certains de vouloir clôturer la procedure ? Cette action est définitive.',
                                callback: () => {
                                    this.setState({ status: Status.PENDING }, async () => {
                                        this.props.setCommand([])
                                        try {
                                            const result = await this.props.procedureManager.closeWf(this.props?.match?.params?.id)
                                            const procedure = await this.props.procedureManager.getById(result.id)

                                            this.setState({
                                                item: procedure,
                                                iniItem: procedure,
                                                status: Status.RESOLVED
                                            }, () => {
                                                this._setupCommandBar()
                                                this.props.setCommand(this.commandRead)
                                            })
                                        } catch (error) {
                                            switch (error?.constructor) {
                                                case CancelRequestError:
                                                case UnauthorizedError: break
                                                case NotImplementedError:
                                                    console.error(error)
                                                    break
                                                case InvalidEntityError:
                                                    const err = /** @type {InvalidEntityError<ErrorProcedure>} */(error)
                                                    this.setState({ errorField: err.errorField })
                                                    this.props.setCommand(this.commandRead)
                                                    break
                                                default:
                                                    this.setState({ status: Status.REJECTED })
                                                    this.props.setCommand(this.commandRead)
                                                    console.error(error)
                                                    break
                                            }
                                        }
                                    })
                                }
                            }),
                            disabled: !item?.hasAccess?.cloturerProc
                        }
                    ],
                }
            },
        ]

        /** 
         * @type {ICommandBarItemProps[]} Commanbar items when editing 
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: 'Annuler',
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    this.setState({ isReadOnly: true, item: { ...this.state.iniItem }, status: Status.PENDING, errorField: new ErrorProcedure() },
                        () => this.setState({ status: Status.IDLE, }, //Workaround to reset input with "defaultValue"
                            () => this.props.setCommand(this.commandRead)
                        )
                    )
                },
                disabled: !this.props.match?.params?.id
            },
            {
                key: 'save',
                text: 'Enregistrer',
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                }
            },
            {
                key: 'delete',
                text: 'Supprimer',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    this.props.setModal({
                        show: true,
                        title: 'Supprimer la procedure',
                        subTitle: 'Êtes-vous certains de vouloir supprimer la procedure ? Cette action est définitive.',
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                this.props.setCommand([])
                                try {
                                    await this.props.procedureManager.removeById(this.props?.match?.params?.id)
                                    history.push('/procedure')
                                    this.props.setMessageBar({ //We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: "L'élément a bien été supprimée"
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            console.error(error)
                                            break
                                        default:
                                            this.props.setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        }
                    })
                },
                disabled: !this.props.match?.params?.id || !this.state.item?.hasAccess?.suppressionProc || this.state.item.estCloture
            },
            {
                key: 'checkbox',
                onRender: () => (<Toggle
                    offText="Saisie normale"
                    onText="Saisie manuelle"
                    onChange={(ev, checked) => {
                        const { item: { estSaisieManuelle } } = this.state
                        if (!estSaisieManuelle) {
                            this.setState({
                                item: {
                                    ...this.state.item,
                                    salId: null,
                                    sal: {},
                                    cenId: null,
                                    cen: {},
                                    salManagerId: null,
                                    salManager: {},
                                    estSaisieManuelle: !estSaisieManuelle
                                }
                            })
                        } else {
                            this.setState({
                                item: {
                                    ...this.state.item,
                                    salManuel: null,
                                    cenManuel: null,
                                    salManagerManuel: null,
                                    salMailManuel: null,
                                    salManagerMailManuel: null,
                                    estSaisieManuelle: !estSaisieManuelle
                                }
                            })
                        }
                        this.props.setCommand([...this.commandEdit])
                    }}
                    styles={{ root: { marginTop: '12px', marginLeft: '.5em' } }}
                    checked={this.state.item?.estSaisieManuelle}
                    disabled={!this.state.item?.hasAccess?.ecritureManuelleProc || this.state.item.estCloture}
                />)
            },
        ]
    }

    /**
     * Check field requiered are filled
     * @returns {boolean}
     */
    _validateFields() {
        const { item } = this.state

        const mustGeneralBeCompleted = item.salId || item.cenId || item.salManagerId || item.salManuel || item.cenManuel || item.salManagerManuel || item.salMailManuel || item.salManagerMailManuel || item.affecteeId || item.typeSanEnvisageId || item.avecMapConserv || item.estAvecDateEnt || item.dateEntretien || item.fait || item.dateFaits
        const mustEntretienBeCompleted = item.crDate || item.crLieu || item.crFait || item.crConsequence || item.crAReconnu || item.crEstAssiste || item.crNomAssistant || item.typeSanCrDecisionId || item.crNbJourMap || item.crPeriodeMap

        //Check field requiered are filled
        const fields = {
            //General
            salId: !item?.estSaisieManuelle && mustGeneralBeCompleted,
            salManuel: item?.estSaisieManuelle && mustGeneralBeCompleted,
            cenManuel: item?.estSaisieManuelle && mustGeneralBeCompleted,
            salManagerManuel: item?.estSaisieManuelle && mustGeneralBeCompleted,
            // salMailManuel: item?.estSaisieManuelle && mustGeneralBeCompleted,
            salManagerMailManuel: item?.estSaisieManuelle && mustGeneralBeCompleted,
            fait: mustGeneralBeCompleted,
            //Entretien
            crDate: mustEntretienBeCompleted,
            crFait: mustEntretienBeCompleted,
            typeSanCrDecisionId: mustEntretienBeCompleted,
        }

        const fieldsName = {
            //General
            salId: 'Salarié',
            salManuel: 'Salarié',
            cenManuel: 'Centre',
            salManagerManuel: 'Manager',
            // salMailManuel: 'Email du salarié',
            salManagerMailManuel: 'Email du manager',
            fait: 'Faits',
            //Entretien
            crDate: 'Date des faits',
            crFait: 'Faits détaillés',
            typeSanCrDecisionId: 'Décision finale',
        }

        const missingFields = []

        for (const key in fields)
            if (fields[key] && !item[key])
                missingFields.push(fieldsName[key])

        if (missingFields.length > 0) {
            this.props.setMessageBar({
                isDisplayed: true,
                type: MessageBarType.warning,
                message: `Certains champs sont manquants: ${missingFields.join(', ')}`
            })
            return false
        }

        return true
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    _upsert() {
        const { item } = this.state

        if (!this._validateFields())
            return

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            this.props.setCommand([])
            try {
                const result = await this.props.procedureManager.upsert(item, this.props.match?.params?.id)
                const procedure = await this.props.procedureManager.getById(result.id)

                if (!!this.props.match?.params?.id) { //Update has an ID
                    this._setDefaultAnchor(procedure)
                    this.setState({
                        item: procedure,
                        iniItem: procedure,
                        status: Status.RESOLVED
                    }, () => {
                        this._setupCommandBar()
                        this.props.setCommand(this.commandRead)
                    })
                } else { //Else without ID
                    history.push(`/procedure/${procedure.id}`, { procedure })
                }
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            console.error(error)
                            break
                        case InvalidEntityError:
                            const err = /** @type {InvalidEntityError<ErrorProcedure>} */(error)
                            this.setState({ errorField: err.errorField })
                            this.props.setCommand(this.commandEdit)
                            break
                        default:
                            this.props.setCommand(this.commandEdit)
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     */
    render() {
        const { status, item } = this.state

        if (status === Status.PENDING) return <Loader />

        return (
            <main className="app-page-procedure-one">
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        this._upsert()
                    }}
                >
                    {/* <Card>
                        <Workflow
                            steps={[
                                {
                                    label: "Procédure créée",
                                    isActive: item.etape === null,
                                    isCompleted: item.etape > 0
                                },
                                {
                                    label: "Saisie convocation et CR d'entretien",
                                    isActive: item.etape === 1,
                                    isCompleted: item.etape > 1
                                },
                                {
                                    label: "Sanction",
                                    isActive: item.etape === 2,
                                    isCompleted: item.etape > 2
                                }
                            ]}
                        />
                    </Card>
                    <br /> */}
                    <Card>
                        <Workflow
                            steps={[
                                {
                                    label: "Nouvelle procédure",
                                    isActive: item.etape === null,
                                    isCompleted: item.etape > 0
                                },
                                {
                                    label: "Convocation envoyée",
                                    isActive: item.etape === 1,
                                    isCompleted: item.etape > 1
                                },
                                {
                                    label: "Sanction à rédiger",
                                    isActive: item.etape === 2,
                                    isCompleted: item.etape > 2
                                },
                                {
                                    label: "Sanction rédigée",
                                    isActive: item.etape === 3,
                                    isCompleted: item.etape > 3
                                },
                                {
                                    label: "Sanction envoyée",
                                    isActive: item.etape === 4,
                                    isCompleted: item.etape > 4
                                }
                            ]}
                        />
                    </Card>
                    <br />
                    <Card>
                        <Pivot
                            onLinkClick={item => history.replace({ hash: `#${item.props.itemKey}` })}
                            defaultSelectedKey={window.location.hash?.replace('#', '')}
                        >
                            <PivotItem
                                headerText="Général"
                                itemIcon="GlobalNavButton"
                                itemKey="general"
                            >
                                {this._renderPivotGeneral()}
                            </PivotItem>
                            <PivotItem
                                headerText="Convocation"
                                itemIcon="MailAlert"
                                itemKey="convocation"
                            >
                                {this._renderPivotConvocation()}
                            </PivotItem>
                            <PivotItem
                                headerText="CR d'entretien"
                                itemIcon="PeopleAlert"
                                itemKey="entretien"
                            >
                                {this._renderPivotEntretien()}
                            </PivotItem>
                            <PivotItem
                                headerText="Sanction"
                                itemIcon="EditContact"
                                itemKey="sanction"
                            >
                                {this._renderPivotSanction()}
                            </PivotItem>
                            {item.hasAccess?.ecritureRhProc &&
                                <PivotItem
                                    headerText="RH"
                                    itemIcon="AccountManagement"
                                    itemKey="rh"
                                >
                                    {this._renderPivotRh()}
                                </PivotItem>
                            }
                            {this.props.match?.params?.id &&
                                <PivotItem
                                    headerText="Justificatifs"
                                    itemIcon="TextDocumentShared"
                                    itemKey="justificatifs"
                                >
                                    {this._renderPivotJustificatifs()}
                                </PivotItem>
                            }
                            {this.props.match?.params?.id &&
                                <PivotItem
                                    headerText="Modifications"
                                    itemIcon="History"
                                    itemKey="modifications"
                                >
                                    {this._renderPivotModifications()}
                                </PivotItem>
                            }
                        </Pivot>
                    </Card>
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={this.submitInput}
                    />
                </form>
            </main>
        )
    }

    /**
     * Render first pivot: General
     */
    _renderPivotGeneral() {
        const { item, isReadOnly, errorField } = this.state
        const { param } = this.props

        return (<>
            {
                !this.state.item?.estSaisieManuelle ?
                    <>
                        <Columns>
                            <Columns.Column>
                                <FilteredVirtualCombobox
                                    label="Salarié"
                                    placeholder="Salarié"
                                    readOnly={isReadOnly || !item.hasAccess?.ecritureGeneralProc || item.estCloture}
                                    options={param.salarie}
                                    selectedKey={item.salId}
                                    required
                                    onChange={(ev, option) => this.setState({ item: { ...item, salId: option.key, cenId: option.cenId, salManagerId: option.managerId } })}
                                />
                            </Columns.Column>
                            <Columns.Column>
                                <TextField
                                    className="flex-one"
                                    label="Centre"
                                    placeholder="Centre"
                                    value={param.centre?.find(x => x.key === item.cenId)?.text || item.cen?.nom || ''}
                                    errorMessage={errorField.cenId}
                                    readOnly={true}
                                    borderless={isReadOnly || !item.hasAccess?.ecritureGeneralProc || item.estCloture}
                                    required
                                    styles={{ field: { cursor: 'default' } }}
                                />
                            </Columns.Column>
                            <Columns.Column>
                                <TextField
                                    className="flex-one"
                                    label="Manager"
                                    placeholder="Manager"
                                    value={param.manager?.find(x => x.key === item.salManagerId)?.text ||
                                        (item.salManager?.nom && item.salManager?.prenom ? `${item.salManager?.nom} ${item.salManager?.prenom}` : '')}
                                    errorMessage={errorField.salManagerId}
                                    readOnly={true}
                                    borderless={isReadOnly || !item.hasAccess?.ecritureGeneralProc || item.estCloture}
                                    required
                                    styles={{ field: { cursor: 'default' } }}
                                />
                            </Columns.Column>
                        </Columns>
                    </>
                    :
                    <>
                        <Columns>
                            <Columns.Column>
                                <TextField
                                    label="Salarié"
                                    placeholder="Salarié"
                                    value={item.salManuel || ''}
                                    errorMessage={errorField.salManuel}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, salManuel: newVal } })}
                                    readOnly={isReadOnly || !item.hasAccess?.ecritureGeneralProc || !item?.hasAccess?.ecritureManuelleProc || item.estCloture}
                                    borderless={isReadOnly || !item.hasAccess?.ecritureGeneralProc || !item?.hasAccess?.ecritureManuelleProc || item.estCloture}
                                    required
                                />
                            </Columns.Column>
                            <Columns.Column>
                                <TextField
                                    label="Centre"
                                    placeholder="Centre"
                                    value={item.cenManuel || ''}
                                    errorMessage={errorField.cenManuel}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, cenManuel: newVal } })}
                                    readOnly={isReadOnly || !item.hasAccess?.ecritureGeneralProc || !item?.hasAccess?.ecritureManuelleProc || item.estCloture}
                                    borderless={isReadOnly || !item.hasAccess?.ecritureGeneralProc || !item?.hasAccess?.ecritureManuelleProc || item.estCloture}
                                    required
                                />
                            </Columns.Column>
                            <Columns.Column>
                                <TextField
                                    label="Manager"
                                    placeholder="Manager"
                                    value={item.salManagerManuel || ''}
                                    errorMessage={errorField.salManagerManuel}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, salManagerManuel: newVal } })}
                                    readOnly={isReadOnly || !item.hasAccess?.ecritureGeneralProc || !item?.hasAccess?.ecritureManuelleProc || item.estCloture}
                                    borderless={isReadOnly || !item.hasAccess?.ecritureGeneralProc || !item?.hasAccess?.ecritureManuelleProc || item.estCloture}
                                    required
                                />
                            </Columns.Column>
                        </Columns>
                        <Columns>
                            <Columns.Column>
                                <TextField
                                    label="Email du manager"
                                    placeholder="Email du manager"
                                    value={item.salManagerMailManuel || ''}
                                    errorMessage={errorField.salManagerMailManuel}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, salManagerMailManuel: newVal } })}
                                    readOnly={isReadOnly || !item.hasAccess?.ecritureGeneralProc || !item?.hasAccess?.ecritureManuelleProc || item.estCloture}
                                    borderless={isReadOnly || !item.hasAccess?.ecritureGeneralProc || !item?.hasAccess?.ecritureManuelleProc || item.estCloture}
                                    required
                                    type="email"
                                />
                            </Columns.Column>
                            <Columns.Column className="is-hidden-touch" />
                            <Columns.Column className="is-hidden-touch" />
                        </Columns>
                    </>
            }
            <Columns>
                <Columns.Column>
                    <FilteredVirtualCombobox
                        label="Affectée à"
                        placeholder="Affectée à"
                        readOnly={isReadOnly || !item.hasAccess?.ecritureGeneralProc || !item.hasAccess?.ecritureAffecteAProc || item.estCloture}
                        options={param.admin}
                        selectedKey={item.affecteeId}
                        onChange={(ev, option) => this.setState({ item: { ...item, affecteeId: option.key } })}
                    />
                </Columns.Column>
                <Columns.Column>
                    <FilteredVirtualCombobox
                        label="Type de sanction à envisager"
                        placeholder="Type de sanction à envisager"
                        readOnly={isReadOnly || !item.hasAccess?.ecritureGeneralProc || item.estCloture}
                        options={param.typeSanction}
                        selectedKey={item.typeSanEnvisageId}
                        onChange={(ev, option) => this.setState({ item: { ...item, typeSanEnvisageId: option.key } })}
                    />
                </Columns.Column>
                <Columns.Column>
                    <Toggle
                        label="Mise à pied conservatoire ?"
                        checked={item.avecMapConserv}
                        onText="Oui"
                        offText="Non"
                        onChange={(ev, checked) => this.setState({ item: { ...item, avecMapConserv: checked } })}
                        disabled={isReadOnly || !item.hasAccess?.ecritureGeneralProc || item.estCloture}
                    />
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <Toggle
                        label="Date d'entretien ?"
                        checked={item.estAvecDateEnt}
                        onText="Oui"
                        offText="Non"
                        onChange={(ev, checked) => this.setState({
                            item: {
                                ...item,
                                estAvecDateEnt: checked,
                                dateEntretien: checked ? item.dateEntretien : null
                            }
                        })}
                        disabled={isReadOnly || !item.hasAccess?.ecritureGeneralProc || item.estCloture}
                    />
                </Columns.Column>
                {item.estAvecDateEnt ? <>
                    <Columns.Column>
                        <TextField
                            label="Date entretien"
                            placeholder="Date entretien"
                            defaultValue={(item.dateEntretien ? Time(item.dateEntretien).getIsoDateString() : '') || ''}
                            errorMessage={errorField.dateEntretien}
                            onChange={(ev) => {
                                if (/** @type {HTMLInputElement} */ (ev.target).value === '') //Clear callback
                                    return this.setState({ item: { ...item, dateEntretien: null } })
                            }}
                            onBlur={ev => {
                                if (!ev.target.value)
                                    return this.setState({ item: { ...item, dateEntretien: null } })

                                let dt = item.dateEntretien || new Date()
                                dt = Time(dt).set(parseInt(ev.target.value?.split('-')?.[0]), 'year')
                                dt = Time(dt).set(parseInt(ev.target.value?.split('-')?.[1]), 'month')
                                dt = Time(dt).set(parseInt(ev.target.value?.split('-')?.[2]), 'day')
                                /* dt = Time(dt).set(this.state.item.dateEntretien?.getMinutes() ?? 0, 'min')
                                dt = Time(dt).set(this.state.item.dateEntretien?.getHours() ?? 0, 'hour') */
                                dt = Time(dt).set(0, 'sec')
                                this.setState({ item: { ...item, dateEntretien: dt } })
                            }}
                            readOnly={isReadOnly || !item.hasAccess?.ecritureGeneralProc || item.estCloture}
                            borderless={isReadOnly || !item.hasAccess?.ecritureGeneralProc || item.estCloture}
                            type="date"
                        />
                    </Columns.Column>
                    <Columns.Column>
                        <FilteredVirtualCombobox
                            label="Heure entretien"
                            placeholder="Heure entretien"
                            readOnly={isReadOnly || !item.hasAccess?.ecritureGeneralProc || item.estCloture}
                            options={this.times}
                            selectedKey={(item.dateEntretien ? Time(item.dateEntretien).getIsoTimeString() : '') || ''}
                            errorMessage={errorField.dateEntretien}
                            onChange={(ev, option) => {
                                let dt = item.dateEntretien || new Date()
                                dt = Time(dt).set(parseInt(option.key?.split(':')?.[1] ?? 0), 'min')
                                dt = Time(dt).set(parseInt(option.key?.split(':')?.[0] ?? 0), 'hour')
                                dt = Time(dt).set(0, 'sec')
                                this.setState({ item: { ...item, dateEntretien: dt } })
                            }}
                        />
                    </Columns.Column></>
                    :
                    <>
                        <Columns.Column className="is-hidden-touch" />
                        <Columns.Column className="is-hidden-touch" />
                    </>
                }
            </Columns>
            <Columns>
                <Columns.Column>
                    <TextField
                        label="Faits"
                        placeholder="Faits"
                        value={item.fait || ''}
                        errorMessage={errorField.fait}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, fait: newVal } })}
                        readOnly={isReadOnly || !item.hasAccess?.ecritureGeneralProc || item.estCloture}
                        borderless={isReadOnly || !item.hasAccess?.ecritureGeneralProc || item.estCloture}
                        required
                        multiline
                        autoAdjustHeight
                    />
                </Columns.Column>
                <Columns.Column>
                    <TextField
                        label="Date des faits"
                        placeholder="Date des faits"
                        value={item.dateFaits || ''}
                        errorMessage={errorField.dateFaits}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, dateFaits: newVal } })}
                        readOnly={isReadOnly || !item.hasAccess?.ecritureGeneralProc || item.estCloture}
                        borderless={isReadOnly || !item.hasAccess?.ecritureGeneralProc || item.estCloture}
                    />
                </Columns.Column>
                {/* <Columns.Column className="is-hidden-touch" /> */}
                <Columns.Column>
                    <Toggle
                        label="Représentant du personnel ?"
                        checked={item.repPers}
                        onText="Oui"
                        offText="Non"
                        onChange={(ev, checked) => this.setState({ item: { ...item, repPers: checked } })}
                        disabled={isReadOnly || !item.hasAccess?.ecritureGeneralProc || item.estCloture}
                    />
                </Columns.Column>
            </Columns>
        </>)
    }

    /**
     * Render second pivot: Convocation
     */
    _renderPivotConvocation() {
        const { item, isReadOnly, errorField } = this.state
        const { param } = this.props

        return (<>
            <Columns>
                <Columns.Column>
                    <FilteredVirtualCombobox
                        label="Type d'envoi"
                        placeholder="Type d'envoi"
                        readOnly={isReadOnly || !item.hasAccess?.ecritureConvocationProc || item.estCloture || !item.estAvecDateEnt}
                        options={param.typeEnvoi}
                        selectedKey={item.typeEnvConvocId}
                        onChange={(ev, option) => this.setState({ item: { ...item, typeEnvConvocId: option.key } })}
                    />
                </Columns.Column>
                <Columns.Column>
                    <TextField
                        label="N° de recommandé"
                        placeholder="N° de recommandé"
                        maxLength={13}
                        value={item.numRecConvoc || ''}
                        errorMessage={errorField.numRecConvoc}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, numRecConvoc: newVal } })}
                        readOnly={isReadOnly || !item.hasAccess?.ecritureConvocationProc || item.estCloture || !item.estAvecDateEnt}
                        borderless={isReadOnly || !item.hasAccess?.ecritureConvocationProc || item.estCloture || !item.estAvecDateEnt}
                    />
                </Columns.Column>
                <Columns.Column className="is-hidden-touch" />
            </Columns>
            <Columns>
                <Columns.Column>
                    <TextField
                        label="Date d'envoi"
                        placeholder="Date d'envoi"
                        value={(item.dateEnvoiConvoc ? Time(item.dateEnvoiConvoc).getIsoDateString() : '') || ''}
                        errorMessage={errorField.dateEnvoiConvoc}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, dateEnvoiConvoc: this._isValidDate(new Date(newVal)) ? new Date(newVal) : null } })}
                        readOnly={isReadOnly || !item.hasAccess?.ecritureConvocationProc || item.estCloture || !item.estAvecDateEnt}
                        borderless={isReadOnly || !item.hasAccess?.ecritureConvocationProc || item.estCloture || !item.estAvecDateEnt}
                        type="date"
                    />
                </Columns.Column>
                <Columns.Column>
                    <TextField
                        label="Date de présentation"
                        placeholder="Date de présentation"
                        value={(item.datePresentConvoc ? Time(item.datePresentConvoc).getIsoDateString() : '') || ''}
                        errorMessage={errorField.datePresentConvoc}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, datePresentConvoc: this._isValidDate(new Date(newVal)) ? new Date(newVal) : null } })}
                        readOnly={isReadOnly || !item.hasAccess?.ecritureConvocationProc || item.estCloture || !item.estAvecDateEnt}
                        borderless={isReadOnly || !item.hasAccess?.ecritureConvocationProc || item.estCloture || !item.estAvecDateEnt}
                        type="date"
                    />
                </Columns.Column>
                <Columns.Column>
                    <TextField
                        label="Date de distribution"
                        placeholder="Date de distribution"
                        value={(item.dateDistributionConvoc ? Time(item.dateDistributionConvoc).getIsoDateString() : '') || ''}
                        errorMessage={errorField.dateDistributionConvoc}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, dateDistributionConvoc: this._isValidDate(new Date(newVal)) ? new Date(newVal) : null } })}
                        readOnly={isReadOnly || !item.hasAccess?.ecritureConvocationProc || item.estCloture || !item.estAvecDateEnt}
                        borderless={isReadOnly || !item.hasAccess?.ecritureConvocationProc || item.estCloture || !item.estAvecDateEnt}
                        type="date"
                    />
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <TextField
                        label="Commentaires"
                        placeholder="Commentaires"
                        value={item.convocComment || ''}
                        errorMessage={errorField.convocComment}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, convocComment: newVal } })}
                        readOnly={isReadOnly || !item.hasAccess?.ecritureConvocationProc || item.estCloture || !item.estAvecDateEnt}
                        borderless={isReadOnly || !item.hasAccess?.ecritureConvocationProc || item.estCloture || !item.estAvecDateEnt}
                        multiline
                        autoAdjustHeight
                    />
                </Columns.Column>
                <Columns.Column>
                    <Toggle
                        label="Licenciement à envisager ?"
                        checked={item.avecLicenciement}
                        onText="Oui"
                        offText="Non"
                        onChange={(ev, checked) => this.setState({ item: { ...item, avecLicenciement: checked } })}
                        disabled={isReadOnly || !item.hasAccess?.ecritureConvocationProc || item.estCloture || !item.estAvecDateEnt}
                    />
                </Columns.Column>
                <Columns.Column className="is-hidden-touch" />
            </Columns>
        </>)
    }

    /**
     * Render third pivot: Entretien
     */
    _renderPivotEntretien() {
        const { item, isReadOnly, errorField } = this.state
        const { param } = this.props

        const isEntretienReadonly = isReadOnly ||
            !item.hasAccess?.ecritureEentretienProc ||
            item.estCloture ||
            (item.dateEntretien && Time(item?.dateEntretien).getIsoDateString() > Time(new Date()).getIsoDateString()) ||
            (item.dateEntretien && Time(item?.dateEntretien).getIsoDateString() === Time(new Date()).getIsoDateString() && Time(item?.dateEntretien)?.getIsoTimeString() > new Date().toLocaleTimeString(navigator.language, { hour: 'numeric', minute: 'numeric' }))

        //Function to display a message if entretien is not yet done
        /* const checkDateEntretien = () => {
            if (item.dateEntretien < new Date())
            this.props.setMessageBar({
                isDisplayed: true,
                type: MessageBarType.warning,
                message: "Attention le compte rendu n'est pas encore passé"
            })
        } */

        return (<>
            <Columns>
                <Columns.Column>
                    <TextField
                        label="Date des faits"
                        placeholder="Date des faits"
                        value={(item.crDate ? Time(item.crDate).getIsoDateString() : '') || ''}
                        errorMessage={errorField.crDate}
                        onChange={(ev, newVal) =>
                            this.setState({ item: { ...item, crDate: this._isValidDate(new Date(newVal)) ? new Date(newVal) : null } })}
                        readOnly={isEntretienReadonly}
                        borderless={isEntretienReadonly}
                        type="date"
                        required={item?.etape >= 1}
                    />
                </Columns.Column>
                <Columns.Column>
                    <TextField
                        label="Lieu"
                        placeholder="Lieu"
                        value={item.crLieu || ''}
                        errorMessage={errorField.crLieu}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, crLieu: newVal } })}
                        readOnly={isEntretienReadonly}
                        borderless={isEntretienReadonly}
                    />
                </Columns.Column>
                <Columns.Column className="is-hidden-touch" />
            </Columns>
            <Columns>
                <Columns.Column>
                    <TextField
                        label="Faits détaillés"
                        placeholder="Faits détaillés"
                        value={item.crFait || ''}
                        errorMessage={errorField.crFait}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, crFait: newVal } })}
                        readOnly={isEntretienReadonly}
                        borderless={isEntretienReadonly}
                        multiline
                        autoAdjustHeight
                        required={item?.etape >= 1}
                    />
                </Columns.Column>
                <Columns.Column>
                    <TextField
                        label="Conséquences (coût, impact sur le client, ...)"
                        placeholder="Conséquences (coût, impact sur le client, ...)"
                        value={item.crConsequence || ''}
                        errorMessage={errorField.crConsequence}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, crConsequence: newVal } })}
                        readOnly={isEntretienReadonly}
                        borderless={isEntretienReadonly}
                        multiline
                        autoAdjustHeight
                    />
                </Columns.Column>
                <Columns.Column className="is-hidden-touch" />
            </Columns>
            <Columns>
                <Columns.Column>
                    <Toggle
                        label="Reconnaissance des faits par le salarié"
                        checked={item.crAReconnu}
                        onText="Oui"
                        offText="Non"
                        onChange={(ev, checked) => this.setState({ item: { ...item, crAReconnu: checked } })}
                        disabled={isEntretienReadonly}
                    />
                </Columns.Column>
                <Columns.Column>
                    <Toggle
                        label="Assistance du salarié"
                        checked={item.crEstAssiste}
                        onText="Oui"
                        offText="Non"
                        onChange={(ev, checked) => this.setState({
                            item: {
                                ...item,
                                crEstAssiste: checked,
                                crNomAssistant: checked ? item.crNomAssistant : null
                            }
                        })}
                        disabled={isEntretienReadonly}
                    />
                </Columns.Column>
                {item.crEstAssiste &&
                    <Columns.Column>
                        <TextField
                            label="Nom de l'assistant"
                            placeholder="Nom de l'assistant"
                            value={item.crNomAssistant || ''}
                            errorMessage={errorField.crNomAssistant}
                            onChange={(ev, newVal) => this.setState({ item: { ...item, crNomAssistant: newVal } })}
                            readOnly={isReadOnly || !item.hasAccess?.ecritureEentretienProc || item.estCloture || !item.estAvecDateEnt}
                            borderless={isEntretienReadonly}
                        />
                    </Columns.Column>}
                {!item.crEstAssiste && <Columns.Column className="is-hidden-touch" />}
            </Columns>
            <Columns>
                <Columns.Column>
                    <FilteredVirtualCombobox
                        label="Décision finale"
                        placeholder="Décision finale"
                        readOnly={isEntretienReadonly}
                        options={param.typeSanction}
                        selectedKey={item.typeSanCrDecisionId}
                        onChange={(ev, option) => this.setState({ item: { ...item, typeSanCrDecisionId: option.key } })}
                        required={item?.etape >= 1}
                    />
                </Columns.Column>
                <Columns.Column>
                    <FilteredVirtualCombobox
                        label="Mise à pied"
                        placeholder="Mise à pied"
                        readOnly={isEntretienReadonly}
                        options={[
                            { key: 1, text: "1 jour" },
                            { key: 2, text: "2 jours" },
                            { key: 3, text: "3 jours" },
                            { key: 4, text: "4 jours" },
                            { key: 5, text: "5 jours" },
                            { key: 6, text: "6 jours" },
                        ]}
                        selectedKey={item.crNbJourMap}
                        onChange={(ev, option) => this.setState({ item: { ...item, crNbJourMap: option.key } })}
                    />
                </Columns.Column>
                <Columns.Column>
                    <TextField
                        label="Période de la mise à pied"
                        placeholder="Période de la mise à pied"
                        value={item.crPeriodeMap || ''}
                        errorMessage={errorField.crPeriodeMap}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, crPeriodeMap: newVal } })}
                        readOnly={isEntretienReadonly}
                        borderless={isEntretienReadonly}
                    />
                </Columns.Column>
            </Columns>
        </>)
    }

    /**
     * Render fifth pivot: Sanction
     */
    _renderPivotSanction() {
        const { item, isReadOnly, errorField } = this.state
        const { param } = this.props

        return (<>
            <Columns>
                <Columns.Column>
                    <FilteredVirtualCombobox
                        label="Type de sanction"
                        placeholder="Type de sanction"
                        readOnly={isReadOnly || !item.hasAccess?.ecritureSanctionProc || item.estCloture}
                        options={param.typeSanction}
                        selectedKey={item.typeSanReelId}
                        onChange={(ev, option) => this.setState({ item: { ...item, typeSanReelId: option.key } })}
                    />
                </Columns.Column>
                <Columns.Column>
                    <FilteredVirtualCombobox
                        label="Type d'envoi"
                        placeholder="Type d'envoi"
                        readOnly={isReadOnly || !item.hasAccess?.ecritureSanctionProc || item.estCloture}
                        options={param.typeEnvoi}
                        selectedKey={item.typeEnvId}
                        onChange={(ev, option) => this.setState({ item: { ...item, typeEnvId: option.key } })}
                    />
                </Columns.Column>
                <Columns.Column>
                    <TextField
                        label="N° de recommandé"
                        placeholder="N° de recommandé"
                        maxLength={13}
                        value={item.numRecSanction || ''}
                        errorMessage={errorField.numRecSanction}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, numRecSanction: newVal } })}
                        readOnly={isReadOnly || !item.hasAccess?.ecritureSanctionProc || item.estCloture}
                        borderless={isReadOnly || !item.hasAccess?.ecritureSanctionProc || item.estCloture}
                    />
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <TextField
                        label="Date d'envoi"
                        placeholder="Date d'envoi"
                        value={(item.dateEnvoiSanction ? Time(item.dateEnvoiSanction).getIsoDateString() : '') || ''}
                        errorMessage={errorField.dateEnvoiSanction}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, dateEnvoiSanction: this._isValidDate(new Date(newVal)) ? new Date(newVal) : null } })}
                        readOnly={isReadOnly || !item.hasAccess?.ecritureSanctionProc || item.estCloture}
                        borderless={isReadOnly || !item.hasAccess?.ecritureSanctionProc || item.estCloture}
                        type="date"
                    />
                </Columns.Column>
                <Columns.Column>
                    <TextField
                        label="Date de présentation"
                        placeholder="Date de présentation"
                        value={(item.dateReceptSanction ? Time(item.dateReceptSanction).getIsoDateString() : '') || ''}
                        errorMessage={errorField.dateReceptSanction}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, dateReceptSanction: this._isValidDate(new Date(newVal)) ? new Date(newVal) : null } })}
                        readOnly={isReadOnly || !item.hasAccess?.ecritureSanctionProc || item.estCloture}
                        borderless={isReadOnly || !item.hasAccess?.ecritureSanctionProc || item.estCloture}
                        type="date"
                    />
                </Columns.Column>
                <Columns.Column>
                    <TextField
                        label="Date de distribution"
                        placeholder="Date de distribution"
                        value={(item.dateDistributionSanction ? Time(item.dateDistributionSanction).getIsoDateString() : '') || ''}
                        errorMessage={errorField.dateDistributionSanction}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, dateDistributionSanction: this._isValidDate(new Date(newVal)) ? new Date(newVal) : null } })}
                        readOnly={isReadOnly || !item.hasAccess?.ecritureSanctionProc || item.estCloture}
                        borderless={isReadOnly || !item.hasAccess?.ecritureSanctionProc || item.estCloture}
                        type="date"
                    />
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <TextField
                        label="Commentaires"
                        placeholder="Commentaires"
                        value={item.sancComment || ''}
                        errorMessage={errorField.sancComment}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, sancComment: newVal } })}
                        readOnly={isReadOnly || !item.hasAccess?.ecritureSanctionProc || item.estCloture}
                        borderless={isReadOnly || !item.hasAccess?.ecritureSanctionProc || item.estCloture}
                        multiline
                        autoAdjustHeight
                    />
                </Columns.Column>
                <Columns.Column>
                    <FilteredVirtualCombobox
                        label="Mise à pied"
                        placeholder="Mise à pied"
                        readOnly={isReadOnly || !item.hasAccess?.ecritureSanctionProc || item.estCloture}
                        options={[
                            { key: 1, text: "1 jour" },
                            { key: 2, text: "2 jours" },
                            { key: 3, text: "3 jours" },
                            { key: 4, text: "4 jours" },
                            { key: 5, text: "5 jours" },
                            { key: 6, text: "6 jours" },
                        ]}
                        selectedKey={item.nbJourMap}
                        onChange={(ev, option) => this.setState({ item: { ...item, nbJourMap: option.key } })}
                    />
                </Columns.Column>
                <Columns.Column className="is-hidden-touch" />
            </Columns>
        </>)
    }

    /**
     * Render sixth pivot: Rh
     */
    _renderPivotRh() {
        const { item, isReadOnly, errorField } = this.state

        return (<>
            <Columns>
                <Columns.Column>
                    <TextField
                        label="Date estimée de départ"
                        placeholder="Date estimée de départ"
                        value={(item.rhDateDepart ? Time(item.rhDateDepart).getIsoDateString() : '') || ''}
                        errorMessage={errorField.rhDateDepart}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, rhDateDepart: this._isValidDate(new Date(newVal)) ? new Date(newVal) : null } })}
                        readOnly={isReadOnly || !item.hasAccess?.ecritureRhProc}
                        borderless={isReadOnly || !item.hasAccess?.ecritureRhProc}
                        type="date"
                    />
                </Columns.Column>
                <Columns.Column>
                    <Toggle
                        label="Visible dans le reporting ?"
                        checked={item.rhEstVisibleReport}
                        onText="Oui"
                        offText="Non"
                        onChange={(ev, checked) => this.setState({ item: { ...item, rhEstVisibleReport: checked } })}
                        disabled={isReadOnly || !item.hasAccess?.ecritureRhProc}
                    />
                </Columns.Column>
                <Columns.Column>
                    <TextField
                        label="Commentaires"
                        placeholder="Commentaires"
                        value={item.rhComment || ''}
                        errorMessage={errorField.rhComment}
                        onChange={(ev, newVal) => this.setState({ item: { ...item, rhComment: newVal } })}
                        readOnly={isReadOnly || !item.hasAccess?.ecritureRhProc}
                        borderless={isReadOnly || !item.hasAccess?.ecritureRhProc}
                        multiline
                        autoAdjustHeight
                    />
                </Columns.Column>
            </Columns>
        </>)
    }

    /**
     * Render seventh pivot: Justificatifs
     */
    _renderPivotJustificatifs() {
        const { item, isReadOnly, iniItem, status, isDragging } = this.state

        return (<>
            <div className="flex-row flex-start">
                <DefaultButton
                    className={classNames({ 'is-dragging': isDragging })}
                    primary
                    iconProps={{ iconName: 'Upload' }}
                    text="Ajouter"
                    onClick={() => {
                        this.uploadFile.current.value = null
                        this.uploadFile.current.click()
                    }}
                    onDragOver={ev => {
                        ev.preventDefault()
                        this.setState({
                            isDragging: true,
                        })
                    }}
                    onDragLeave={ev => {
                        ev.preventDefault()
                        this.setState({
                            isDragging: false,
                        })
                    }}
                    onDrop={ev => {
                        ev.preventDefault()
                        const fileElement = ev.dataTransfer.items ? ev.dataTransfer.items[0]?.getAsFile() : ev.dataTransfer.files[0]
                        if (fileElement) {
                            this.setState({ status: Status.PENDING_FILE, isDragging: false }, async () => {
                                try {
                                    const file = await this.props.procedureManager.uploadFile(
                                        item.id,
                                        fileElement
                                    )
                                    this.setState({
                                        item: { ...item, file: [...item.file, file] },
                                        iniItem: { ...iniItem, file: [...iniItem.file, file] },
                                        status: Status.RESOLVED
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            console.error(error)
                                            break
                                        default:
                                            this.setState({ status: Status.REJECTED })
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        }
                    }}
                    disabled={status === Status.PENDING_FILE || isReadOnly || !item.hasAccess?.ecritureJustificatifProc}
                />
                {status === Status.PENDING_FILE && <>&nbsp;&nbsp;<Spinner size={SpinnerSize.small} /></>}
                <input
                    type="file"
                    ref={this.uploadFile}
                    onChange={ev => {
                        const fileElement = ev.target?.files?.[0]
                        if (fileElement) {
                            this.setState({ status: Status.PENDING_FILE }, async () => {
                                try {
                                    const file = await this.props.procedureManager.uploadFile(
                                        item.id,
                                        fileElement
                                    )
                                    this.setState({
                                        item: { ...item, file: [...item.file, file] },
                                        iniItem: { ...iniItem, file: [...iniItem.file, file] },
                                        status: Status.RESOLVED
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            console.error(error)
                                            break
                                        default:
                                            this.setState({ status: Status.REJECTED })
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        }
                    }}
                    style={{ display: 'none' }}
                />
            </div>
            <DetailsList
                items={item.file}
                columns={[
                    {
                        key: 'file',
                        name: 'Fichier',
                        fieldName: 'nom',
                        minWidth: 100,
                        onRender: row => <p className="flex-col">{row.nom}</p>
                    },
                    {
                        key: 'delete',
                        name: '',
                        minWidth: 131,
                        maxWidth: 131,
                        onRender: row => <DefaultButton
                            text="Supprimer"
                            iconProps={{ iconName: "trash" }}
                            onClick={() => {
                                const file = /** @type {File} */(row)
                                this.props.setModal({
                                    show: true,
                                    title: 'Supprimer le fichier',
                                    subTitle: `Êtes-vous certains de vouloir supprimer le fichier "${file.nom}" ? Cette action est définitive.`,
                                    callback: () => {
                                        this.setState({ status: Status.PENDING_FILE }, async () => {
                                            this.props.setCommand([])
                                            try {
                                                await this.props.procedureManager.removeFile(this.props.match?.params?.id, file.id)

                                                this.setState({
                                                    item: { ...item, file: [...item.file].filter(x => x.id !== file.id) },
                                                    iniItem: { ...iniItem, file: [...iniItem.file].filter(x => x.id !== file.id) },
                                                    status: Status.RESOLVED
                                                })
                                                this.props.setCommand(this.commandEdit)
                                            } catch (error) {
                                                switch (error?.constructor) {
                                                    case CancelRequestError:
                                                    case UnauthorizedError:
                                                    case InvalidEntityError: break
                                                    case NotImplementedError:
                                                        this.props.setCommand(this.commandEdit)
                                                        console.error(error)
                                                        break
                                                    default:
                                                        this.props.setCommand(this.commandEdit)
                                                        this.setState({ status: Status.REJECTED })
                                                        console.error(error)
                                                        break
                                                }
                                            }
                                        })
                                    }
                                })
                            }}
                            disabled={status === Status.PENDING_FILE || isReadOnly || !item.hasAccess?.ecritureJustificatifProc || item.estCloture}
                        />
                    },
                ]}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => true}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
                onRenderRow={(props, defaultRender) => (
                    <div
                        onClick={async ev => {
                            if (!/** @type {HTMLDivElement} */(ev.target).closest('.ms-Button') && status !== Status.PENDING_FILE) {
                                try {
                                    const file = /** @type {File} */(props.item)
                                    const blob = await this.props.procedureManager.getFile(this.props.match?.params?.id, file.id)
                                    HandleBlop.download(blob, file.nom)
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            console.error(error)
                                            break
                                        default:
                                            console.error(error)
                                            break
                                    }
                                }
                            }
                        }}
                    >
                        {defaultRender({
                            ...props,
                            styles: {
                                cell: {
                                    cursor: status !== Status.PENDING_FILE ? "pointer" : undefined
                                }
                            }
                        })}
                    </div>
                )}
            />
            {!item.file?.length && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucun fichier trouvé</Text>}
        </>)
    }

    /**
     * Render eighth pivot: Modifications
     */
    _renderPivotModifications() {
        const { columnsHist, item: { historique }, item } = this.state

        return (<>
            <Columns>
                <Columns.Column>
                    <TextField
                        label="Date de création"
                        placeholder="Date de création"
                        value={(item.dateCreation ? Time(item.dateCreation).getCleanDate({
                            weekday: "long",
                            year: 'numeric',
                            month: 'long',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                        }) : '') || ''}
                        readOnly={true}
                        borderless={true}
                    />
                </Columns.Column>
                <Columns.Column className="is-hidden-touch" />
                <Columns.Column className="is-hidden-touch" />
            </Columns>
            <DetailsList
                items={historique}
                columns={columnsHist}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => true}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
            />
            {!historique?.length && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucune modification trouvée</Text>}
        </>)
    }
}

OneProcedure.prototype._disableCommandBtn = _disableCommandBtn
OneProcedure.prototype._disableCommandSubBtn = _disableCommandSubBtn
OneProcedure.prototype._onColumnClick = _onColumnClick
OneProcedure.prototype._handleRenderColumn = _handleRenderColumn
OneProcedure.prototype._isValidDate = _isValidDate
