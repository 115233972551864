import { Centre } from "request/objects/centre.js"
import { Civilite } from "./civilite"
import { Departement } from "./departement"
import { Origine } from "./origine"
import { SalarieStatut } from "./salarieStatut"
// import { SeRestriction } from "./seRestriction"

/**
 * Salarie Object
 */
export class Salarie {
    /**
     * @param {object} data
     * @param {number=} data.id
     * @param {string=} data.matricule
     * @param {number=} data.civiliteId
     * @param {object=} data.civilite
     * @param {string=} data.nom
     * @param {string=} data.prenom
     * @param {string=} data.login
     * @param {number=} data.managerId
     * @param {object=} data.manager
     * @param {number=} data.cenId
     * @param {object=} data.cen
     * @param {number=} data.statutId
     * @param {object=} data.statut
     * @param {boolean=} data.estArchive
     * @param {string=} data.creationDate
     * @param {number=} data.creationOrigineId
     * @param {object=} data.creationOrigine
     * @param {string=} data.modifDate
     * @param {number=} data.modifOrigineId
     * @param {object=} data.modifOrigine
     * @param {string=} data.adr1
     * @param {string=} data.adr2
     * @param {string=} data.cp
     * @param {string=} data.ville
     * @param {string=} data.mgrMatricule
     * @param {number=} data.departementId
     * @param {object=} data.departement
     * @param {string=} data.sysperMatricule
     * @param {any=} data.restriction
     * @param {any=} data.acces 
     * @param {string=} data.token
     */
    constructor({
        id = 0,
        matricule = null,
        civiliteId = null,
        civilite = null,
        nom = null,
        prenom = null,
        login = null,
        managerId = null,
        manager = null,
        cenId = null,
        cen = null,
        statutId = null,
        statut = null,
        estArchive = null,
        creationDate = null,
        creationOrigineId = null,
        creationOrigine = null,
        modifDate = null,
        modifOrigineId = null,
        modifOrigine = null,
        adr1 = null,
        adr2 = null,
        cp = null,
        ville = null,
        mgrMatricule = null,
        departementId = null,
        departement = null,
        sysperMatricule = null,
        restriction = [],
        acces = [],
        token = null
    } = {}) {
        this.id = id
        this.matricule = matricule
        this.civiliteId = civiliteId
        this.civilite = civilite ? new Civilite(civilite) : null
        this.nom = nom
        this.prenom = prenom
        this.login = login
        this.managerId = managerId
        this.manager = manager ? new Salarie(manager) : null
        this.cenId = cenId
        this.cen = cen ? new Centre(cen) : null
        this.statutId = statutId
        this.statut = statut ? new SalarieStatut(statut) : null
        this.estArchive = estArchive
        this.creationDate = creationDate ? new Date(creationDate) : null
        this.creationOrigineId = creationOrigineId
        this.creationOrigine = creationOrigine ? new Origine(creationOrigine) : null
        this.modifDate = modifDate ? new Date(modifDate) : null
        this.modifOrigineId = modifOrigineId
        this.modifOrigine = modifOrigine ? new Origine(modifOrigine) : null
        this.adr1 = adr1
        this.adr2 = adr2
        this.cp = cp
        this.ville = ville
        this.mgrMatricule = mgrMatricule
        this.departementId = departementId
        this.departement = departement ? new Departement(departement) : null
        this.sysperMatricule = sysperMatricule
        // this.restriction = restriction?.map(x => new SeRestriction({
        //     salarieId: x.salarieId,
        //     // salarie: x.salarie, Not usefull to send this
        //     profilId: x.profilId,
        //     profil: x.profil,
        //     centreId: x.centreId,
        //     centre: x.centre
        // })) ?? []

        this.restriction = restriction

        /** @type {number[]} */
        this.restrictionId = (() => {
            if (Array.isArray(restriction?.profil))
                return (restriction?.profil?.map(x => x.id) ?? [])
            else if (restriction?.profil && typeof restriction?.profil === 'object')
                return [restriction?.profil?.id]
            else
                return []
        })()

        /** @type {number[]} */
        this.restrictionCentreId = (() => {
            if (Array.isArray(restriction?.profil)) {
                const ids = []
                for (const profil of restriction?.profil) {
                    if (Array.isArray(profil?.centre))
                        profil?.centre?.forEach(x => ids.push(x.id))
                    else if (profil?.centre && typeof profil?.centre === 'object')
                        ids.push(profil?.centre.id)
                }
                return [...new Set(ids)]
            }
            else if (restriction?.profil && typeof restriction?.profil === 'object') {
                if (Array.isArray(restriction?.profil?.centre))
                    return restriction?.profil?.centre?.map(x => x.id)
                else if (restriction?.profil?.centre && typeof restriction?.profil?.centre === 'object')
                    return [restriction?.profil.centre?.id]
                else
                    return []
            }
            else
                return []
        })()

        /** @type {number[]} */
        this.restrictionSocieteId = []

        this.token = token

        this.acces = acces
        //1: Manager
        //2: RH
        //3: Admin
        //4: DIRECTEUR DE REGION 
        //5: Modératur
        this.hasAccess = {
            creerProc: this.restrictionId.filter(el => [3, 1, 5].includes(el)).length > 0,
            ecritureProc: this.restrictionId.filter(el => [3, 2, 1, 5].includes(el)).length > 0,
            readProc: this.restrictionId.filter(el => [4, 3, 2, 1, 5].includes(el)).length > 0,
            ecritureGeneralProc: this.restrictionId.filter(el => [3, 2, 1, 5].includes(el)).length > 0,
            ecritureConvocationProc: this.restrictionId.filter(el => [3, 2, 5].includes(el)).length > 0,
            ecritureEentretienProc: this.restrictionId.filter(el => [3, 2, 1, 5].includes(el)).length > 0,
            ecritureSanctionProc: this.restrictionId.filter(el => [3, 2, 5].includes(el)).length > 0,
            ecritureJustificatifProc: this.restrictionId.filter(el => [3, 2, 1, 5].includes(el)).length > 0,
            ecritureAffecteAProc: this.restrictionId.filter(el => [3, 5].includes(el)).length > 0,
            suppressionProc: this.restrictionId.filter(el => [3, 2, 5].includes(el)).length > 0,
            ecritureManuelleProc: this.restrictionId.filter(el => [3, 2, 5].includes(el)).length > 0,
            cloturerProc: this.restrictionId.filter(el => [3, 5].includes(el)).length > 0,
            validerProc: this.restrictionId.filter(el => [3, 2, 5].includes(el)).length > 0,
            ecritureRhProc: this.restrictionId.filter(el => [3, 2, 5].includes(el)).length > 0,
        }
    }
}

/**
 * Salarie Object used to bind error message
 */
export class ErrorSalarie {
    id
    matricule
    civiliteId
    civilite
    nom
    prenom
    login
    managerId
    manager
    cenId
    cen
    statutId
    statut
    estArchive
    creationDate
    creationOrigineId
    creationOrigine
    modifDate
    modifOrigineId
    modifOrigine
    adr1
    adr2
    cp
    ville
    mgrMatricule
    departementId
    departement
    sysperMatricule
}