import { TypeEnvoi, ErrorTypeEnvoi } from 'request/objects/typeEnvoi'
import ApiManager from 'request/apiManager'
import { UpsertResult } from 'request/objects/_upsertResult'

/**
 * TypeEnvoiManager
 * @extends {ApiManager<TypeEnvoi, ErrorTypeEnvoi, UpsertResult>}
 */
export default class TypeEnvoiManager extends ApiManager {
    constructor() {
        super({ type: TypeEnvoi, errorType: ErrorTypeEnvoi, upsertResult: UpsertResult, key: 'typeEnvoi' })
    }
}