import React, { PureComponent } from 'react'
import { history } from 'helpers/history'
import { InvalidEntityError } from 'request/errors/invalidEntityError'
import { Status } from 'static/status'
import { AppProps } from 'app' // eslint-disable-line
import { CancelRequestError } from 'request/errors/cancelRequestError'
import { NotImplementedError } from 'request/errors/notImplementedError'
import { UnauthorizedError } from 'request/errors/unauthorizedError'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'

/**
 * @extends {React.PureComponent<AppProps>}}
 */
export default class Login extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
        }
    }

    componentDidMount() {
        if (this.props.isAuthenticated) history.push('/')
    }

    componentWillUnmount() {
        this.props.salarieManager.cancel()
    }

    render() {
        return (
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <GoogleLogin
                        onSuccess={response => {
                            this.setState({ status: Status.PENDING }, async () => {
                                try {
                                    const user = await this.props.salarieManager.login({
                                        tokenId: response.credential
                                    })
                                    this.props.signIn(user.token)
                                    history.push('/')
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                        default:
                                            this.setState({ status: Status.REJECTED })
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        }}
                        onError={err => console.log('fail', err)}
                    />
                </div>
            </GoogleOAuthProvider>
        )
    }
}