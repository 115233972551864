import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { commonReducer } from "redux/slices/common"
import { userReducer } from "redux/slices/user"
/**
 * @see https://www.valentinog.com/blog/redux/#modern-redux-with-redux-toolkit
 */
const store = configureStore({
    reducer: {
        user: userReducer,
        common: commonReducer,
    },
    middleware: getDefaultMiddleware({ serializableCheck: false })
    // middleware: {
    //     ...userMiddleware,
    //     ...commonMiddleware
    // },
})
export default store